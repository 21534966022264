import {
  FETCH_ALL_CAMPAIGNS_ADMIN, SET_SCREENS_FOR_MARKUP
} from "./types";
import axios from "axios";
import { fetchDOOHCampaignsForApproval } from "./campaignActions";
import { rootURL } from "../Utils/config"



export const fetchAllCampaignsAdmin = () => async dispatch => {

  try {

    const campaigns = await axios.get(`${rootURL}/api/fetchAllCampaignsAdmin`);

    dispatch({
      type : FETCH_ALL_CAMPAIGNS_ADMIN,
      payload : campaigns.data
    });

  } catch(err){
    console.log(err);
  }
}

export const uploadCampaignInvoice = (data, cb) => async dispatch => {

  try {

    await axios.post(`${rootURL}/api/uploadCampaignInvoiceAdmin`, data, {
      headers : {
        'Content-Type' : 'multipart/form-data'
      }
    });

    // console.log("Response Invoice :", response);

    cb();

  } catch(err){
    console.log(err);
  }
}

// Agency Partner Actions

export const fetchScreensForMarkup = (data) => async dispatch => {

  try {

    const response = await axios.get(`${rootURL}/api/fetchScreensForMarkup`);

    dispatch({
      type : SET_SCREENS_FOR_MARKUP,
      payload : response.data
    });

  } catch(err) {
    console.log(err);
  }

}

export const updateCampaignMarkup = (data, cb) => async dispatch => {

  try{

    const response = await axios.post(`${rootURL}/api/updateCampaignMarkup`, data, {
      headers : {
        'Content-Type' : 'multipart/form-data'
      }
    });

    dispatch(fetchDOOHCampaignsForApproval());

    cb();

  } catch(err) {
    console.log(err);
  }
}