import React from 'react';
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { } from "@material-ui/icons";
import Card from "./Card";

function PublisherCounts(props) {

  const { dashboard } = props;
  const { campaignCount, contentCount, impressionCount, screenCount, totalImpressionCount, pendingApprovalCount } = dashboard;


  const numFormater = (value) => {

    if(!Number.isInteger(value)){
      return value;
    }
   
   
    // if(value > 999 && value < 1000000){

    //   return `${(value/1000).toFixed(2)}K+`;
    // } else if(value > 1000000 && value < 1000000000){

    //   return `${(value/1000000).toFixed(2)}M+`;
    // } else if(value > 1000000000){

    //   return `${(value/1000000000).toFixed(2)}B+`;
    // } else if(value < 1000) {
      
    //   return value;
    // }

    return value;
  }

  return (
    <div>
      <Grid container spacing={2}>
        {/* <Grid item md={3}>
          <Card text="Total Impression delivered" value={numFormater(totalImpressionCount)} item="impression count" />
        </Grid> */}
        <Grid item md={3}>
          <Card text="Impression count" value={numFormater(impressionCount)} item="impression count"/>
        </Grid>
        <Grid item md={3}>
          <Card text="Campaign approval" value={numFormater(pendingApprovalCount)} item="approval pending"/>
        </Grid>
        <Grid item md={3}>
          <Card text="Screen count" value={screenCount} item="screen"/>
        </Grid>
        <Grid item md={3}>
          <Card text="Content uploaded" value={contentCount} item="content"/>
        </Grid>
        <Grid item md={3}>
          <Card text="Campaigns" value={campaignCount} item="campaign"/>
        </Grid>
      </Grid>

    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    dashboard : state.dashboard
  }
}

export default connect(mapStateToProps, {})(PublisherCounts);
