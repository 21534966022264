import {
  FETCH_ALL_CREATIVE_ADMIN, APPROVE_CREATIVE_ADMIN, DISAPPROVE_CREATIVE_ADMIN
} from "./types";

import axios from "axios";
import { rootURL } from "../Utils/config";


export const fetchAllCreativeAdmin = () => async dispatch => {

  try {
    const response = await axios.get(`${rootURL}/api/fetchCreativeForApproval`);

    dispatch({
      type : FETCH_ALL_CREATIVE_ADMIN,
      payload : response.data.result
    });

  } catch(err){
    console.log(err);
  }
}

export const approveCreativeAdmin = (data) => async dispatch => {
  try {

    const response = await axios.post(`${rootURL}/api/approveContent`,{data});
    // console.log(response.data.result);

    dispatch({
      type : APPROVE_CREATIVE_ADMIN,
      payload : response.data.result
    });

  } catch(err){
    console.log(err);
  }
}

export const disapproveCreativeAdmin = (data) => async dispatch =>{
  try {

    const response = await axios.post(`${rootURL}/api/disapproveContent`,{data});
    // console.log(response.data.result);

    dispatch({
      type : DISAPPROVE_CREATIVE_ADMIN,
      payload : response.data.result
    })

  } catch(err){
    console.log(err);
  }
}