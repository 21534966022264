import axios from "axios";
import {rootURL} from "../Utils/config";
import {
    FETCH_INVENTORY_SCREENS,
    ADD_TO_INVENTORY_CART,
    CLEAR_INVENTORY_CART,
    FETCH_INVENTORY_FILTER_OPTIONS,
    INVENTORY_TABLE_LOADING,
    SET_STATE_FILTER_OPTIONS,
    SET_CITY_FILTER_OPTIONS,
    FETCH_FILTER_DATA,
    SET_LOCATION_STRUCTURE_LIST,
    FETCH_LOCATIONS_IN_RADIUS
} from "./types";


export const fetchInventory = () => async dispatch => {

    try {

        dispatch({
            type : INVENTORY_TABLE_LOADING,
            payload : true
        });

        const reponse = await axios.post(`${rootURL}/api/getAllInventoryScreens`);
        // console.log("Innn",reponse.data);


        // --------------------------------------
        dispatch({
            type : FETCH_INVENTORY_SCREENS,
            payload : reponse.data
        });

        dispatch({
            type : SET_LOCATION_STRUCTURE_LIST,
            payload : reponse.data
        });

        dispatch({
            type : INVENTORY_TABLE_LOADING,
            payload : false
        });
        
    } catch (err) {
        console.log(err);
    }

}

export const addToCart = (data) => dispatch => {

    dispatch({
        type : ADD_TO_INVENTORY_CART,
        payload : data
    });
}

export const clearCart = () => dispatch => {

    dispatch({
        type : CLEAR_INVENTORY_CART,
        payload : []
    });
}

export const fetchInventoryFilter = () => async dispatch =>{

    try {
        const response = await axios.get(`${rootURL}/api/fetchInventoryFilterOptions`);
        // console.log(response.data);

        dispatch({
            type: FETCH_INVENTORY_FILTER_OPTIONS,
            payload: response.data
        });
        
    } catch(err){
        console.log(err);
    }
}

export const setCityFilterOptions = (data) => dispatch => {

    dispatch({
        type : SET_CITY_FILTER_OPTIONS,
        payload : data
    });


}

export const setStateFilterOptions = (data) => dispatch => {

    dispatch({
        type : SET_STATE_FILTER_OPTIONS,
        payload : data
    });
}

export const fetchFilterData = (data) => async dispatch => {

    let find = {...data};
    let filter = {};
    
    Object.keys(find).forEach(item => {

        if(find[item].length === 0 || find[item] === ""){
            delete find[item];
        }
    });

    Object.keys(find).forEach(item => {

        if(Array.isArray(find[item])){

            filter[item] = [...find[item].map(i => i)];
        } else {
            filter[item] = find[item];
        }

    })

    // console.log(filter);

    if(Object.keys(find).length === 0) {

        

        try {
            dispatch({
                type : INVENTORY_TABLE_LOADING,
                payload : true
            });

            const reponse = await axios.post(`${rootURL}/api/getAllInventoryScreens`);
            // console.log("In",reponse.data);

            console.log("here");
    
            dispatch({
                type : FETCH_INVENTORY_SCREENS,
                payload : reponse.data
            });
    
            dispatch({
                type : INVENTORY_TABLE_LOADING,
                payload : false
            });
            
        } catch (err) {
            console.log(err);
        }
        

    } else {

        try {

            dispatch({
                type : INVENTORY_TABLE_LOADING,
                payload : true
            });

            const response = await axios.post(`${rootURL}/api/fetchIventoryFilteredData`, {filter});

            dispatch({
                type : FETCH_FILTER_DATA,
                payload : response.data.result
            });

            dispatch({
                type : INVENTORY_TABLE_LOADING,
                payload : false
            });
        
            // console.log("F:",response.data);

        } catch(err) {

        }

    }

}

export const saveLocationList = (list) => async dispatch => {

    try {
        const result = await axios.post(`${rootURL}`, {list});
    } catch(err) {
        console.log(err);
    }
}

export const getLocationsWithinRadius = (data) => async dispatch => {

    try {
        dispatch({
            type : INVENTORY_TABLE_LOADING,
            payload : true
        });

        const locations = await axios.post(`${rootURL}/api/getLocationsWithinRadius`,{...data});

        
        dispatch({
            type : FETCH_LOCATIONS_IN_RADIUS,
            payload : locations.data.locations
        });

        dispatch({
            type : INVENTORY_TABLE_LOADING,
            payload : false
        });

    } catch (err) {
        console.log(err);
    }
}