import React from 'react';
import {
  Grid, List ,ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, FormControl, Button, FormHelperText
} from "@material-ui/core";
import { Delete, Add } from "@material-ui/icons";
import { FixedSizeList } from "react-window";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },

  mapList : {

    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 500,

  },

  margin : {
    margin: theme.spacing(1)
  },
  buttonMargin : {
    marginRight: theme.spacing(1)
  },

  formContent : {
    margin : theme.spacing(4),
    padding : theme.spacing(4)
  },

  formControlWidth : {
    width : "100%"
  }

  
}));

function SelectedSitesComponent(props) {

  const { values, errors, setFieldValue, touched,  removeFromCart, sites } = props;
  const { screens_from_map  } = values;

  const classes = useStyles();

  const rowRender = (props) => {

    const { index, style } = props;

    return (
      <div key={index} style={style}>
        <ListItem>
          <ListItemText primary={screens_from_map[index].screen_name} />
          <ListItemSecondaryAction>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => removeFromCart(screens_from_map[index], values.screens_from_map, setFieldValue) }
            >
              <Delete />
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    )

  }

  const returnHelperTextScreensFromMap = (errors, touched) => {
    let isError = errors.screens_from_map && touched.screens_from_map;

    // console.log("MyError is :", errors);

    if(isError){
      return <FormHelperText>{errors.screens_from_map}</FormHelperText>
    }
  }

  const addAllSites = () => {
    setFieldValue("screens_from_map", sites);
  }

  const removeAllSites = () => {
    setFieldValue("screens_from_map", []);
  }

  return (
    <div>

      <Button
        color="primary"
        variant="contained"
        className={classes.buttonMargin}
        onClick={addAllSites}
        startIcon={<Add />}
      >
        Add All
      </Button>

      <Button
        color="secondary"
        variant="contained"
        onClick={removeAllSites}
        startIcon={<removeAllSites/>}
      >
        Remove All
      </Button>

      <FormControl component="fieldset" error={!!errors.screens_from_map && touched.screens_from_map} className={classes.formControlWidth}>
        <List dense={true}>
          { returnHelperTextScreensFromMap(errors, touched) }
          <FixedSizeList height={500} width={350} itemSize={46} itemCount={values.screens_from_map.length}>
            {rowRender}
          </FixedSizeList>
        </List>
        {/* { returnHelperTextScreensFromMap(errors, touched) } */}
      </FormControl>
    </div>
  )
}

export default SelectedSitesComponent;
