import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AppBar, Tabs, Tab, Typography, Box
} from "@material-ui/core";
import DOOHCampaign from "./DOOHCampaignTable";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}));

function Campaigns(props) {

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // getDOOHCampaigns();
  }, [])


  return (
    <div className={classes.root}>
    {/* <div className={classes.root}> */}
      {/* <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="DOOH" {...a11yProps(0)} />
          <Tab label="Mobile Apps" {...a11yProps(1)} />
          <Tab label="Web" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0} dir={theme.direction}>
        
      </TabPanel>

      <TabPanel value={value} index={1} dir={theme.direction}>
        No Application data
      </TabPanel>
      
      <TabPanel value={value} index={2} dir={theme.direction}>
        No Web data
      </TabPanel> */}

      <DOOHCampaign />
    </div>
  )
}

export default Campaigns;
