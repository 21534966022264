import React, { useState } from 'react';
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { Collections } from "@material-ui/icons";
import Lightbox from "react-image-lightbox";
import { getAllScreenshots } from "../../../Actions/screensActions";
import 'react-image-lightbox/style.css';


function ScreenshotGallery(props) {
  const { getAllScreenshots, data, screenshots} = props;

  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);

  const handleGalleryOpen = () => {
    const {screen_name, user_id } = data;
    getAllScreenshots(screen_name, user_id, ()=>{
      setShow(true);
    });
    // setShow(true);
  }

  const handleGalleryClose = () => {
    setShow(false);
  }

  const moveNext = () => {
    let position =  (index + 1) % screenshots.length;
    setIndex(position);
  }

  const movePrev = () => {
    let position =  (index + screenshots.length - 1) % screenshots.length;
    setIndex(position);
  }


  return (
    <div>
      <Button
        onClick={handleGalleryOpen}
        variant="contained"
        color="primary"
      >
        <Collections />
      </Button>
      
      <div>
        { show  ?
          
          <Lightbox
            // mainSrc={screenshots.length > 0 ? screenshots[index] : null}
            mainSrc={screenshots.length > 0 ? screenshots[index] : ""}
            nextSrc={screenshots.length > 0 ? screenshots[(index + 1) % screenshots.length] : ""}
            prevSrc={screenshots.length > 0 ? screenshots[(index + screenshots.length - 1) % screenshots.length] : ""}
            onCloseRequest={handleGalleryClose}
            onMovePrevRequest={movePrev}
            onMoveNextRequest={moveNext}
            reactModalStyle={{
              overlay : {
                zIndex : 10000
              }
            }}
          />

            : null
        }

      </div>
    </div>
  )
}

const mapStateToProps = (state) => {

  return {
    screenshots : [...state.screenshots.map(s => s.screenshot_url)]
  }
}

export default connect(mapStateToProps, { getAllScreenshots })(ScreenshotGallery);
