import React, { useState, useEffect } from 'react';
import {Button, Modal, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThermometerHalf } from '@fortawesome/free-solid-svg-icons'
import { Cloud } from "@material-ui/icons"
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';
import { rootURL } from "../../Utils/config";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none"
  },
  margin: {
    margin: theme.spacing(1),
  },
}));


function WeatherData({cities}) {

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [ weather, setWeather ] = useState(null);

  const handleOpen = () => {
    setOpen(true);

  }

  const handleClose = () => {
    setOpen(false);

  };


  useEffect(() => {
    // console.log("Cities", rootURL);
    const fetchWeather = async (cities) => {

      try {

        const response = await axios.post(`${rootURL}/api/getWeatherForCities`, {cities});

        // console.log("Response :", response.data)

        setWeather(response.data);

      } catch(e){
        console.log(e);
      }

    }

    if(open){
      fetchWeather(cities);
    }
    
  }, [open]);


  const renderList = () => {

    if(weather){

      const list = weather.map((item, index) => {

        return (
          <ListItem key={index}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faThermometerHalf}/>
            </ListItemIcon>
            <ListItemText primary={`${item.city} (${item.temperature} \u00B0C)`}/>
          </ListItem>
        )
      });

      return list;

    } else {
      return <div>Loading...</div>
    }
  }


  // if(cities.length > 1){
  if(true){

    return (
      <div>
          <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          // startIcon={<Share />}
          className={classes.margin}
          >
            <Cloud />
            
          </Button>
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >

        <Fade in={open}>

          <Paper style={{width: "30vw", padding: "1rem" }}>

            { renderList() }
          </Paper>
        </Fade>
      </Modal>
      </div>
    )
  } else {
    return "City";
  }

}

export default WeatherData;
