import React from 'react';
import clsx from 'clsx';
import {
    AppBar, Toolbar, Button, IconButton, Drawer, Divider, List, ListItemIcon, ListItem, ListItemText, Typography, Tooltip
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CssBaseline from '@material-ui/core/CssBaseline';
import DashboardIcon from '@material-ui/icons/Dashboard';
import {
    PersonalVideo, Description, LibraryBooks, AssignmentTurnedIn, Explore, Tune, AccountCircle, Assignment,
    ScreenShare, Group
} from "@material-ui/icons";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DevicesIcon from '@material-ui/icons/Devices';
import { Link as LinkRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../Actions/authActions";
import Can from "../../Config/Can";
const drawerWidth = 240;


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,

    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        flexGrow: 1,
    },
}))

const Navbar = ({ auth, children, pathnames, logoutUser }) => {
    // const dispatch = useDispatch()
    const classes = useStyles()
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, { [classes.hide]: open, })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>Screeno OOH & pDOOH</Typography>
                    {auth.isAuthenticated && <Button color="inherit" onClick={() => { logoutUser() }} endIcon={<ExitToAppIcon />}>Logout</Button>}
                </Toolbar>
            </AppBar>
            
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    
                    <ListItem button component={LinkRouter} to="/dashboard">
                        <Tooltip title="Dashboard">
                            <ListItemIcon><DashboardIcon /></ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={"Dashboard"} />
                    </ListItem>
                    
                    
                    <Can I="view" a="Screens">
                        <ListItem button component={LinkRouter} to="/media">
                            <Tooltip title="Media(Screens)">
                                <ListItemIcon><PersonalVideo /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={"Media"} />
                        </ListItem>
                    </Can>
                    
                    
                   

                    <Can I="view" a="Creatives">
                        <ListItem button component={LinkRouter} to="/creatives">
                            <Tooltip title="Creatives">
                                <ListItemIcon><Description /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={"Creatives"} />
                        </ListItem>
                    </Can>
                    
                    {/* <Can I="view" a="Creatives">
                        <ListItem button component={LinkRouter} to="/approve">
                            <Tooltip title="Creative Approval">
                                <ListItemIcon><AssignmentTurnedIn /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={"Approval"} />
                        </ListItem>
                    </Can> */}

                    
                    <Can I="view" a="Inventory">

                        <ListItem button component={LinkRouter} to="/inventory">
                            <Tooltip title="Inventory data">
                                <ListItemIcon><Explore /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={"Explore"} />
                        </ListItem>
                    </Can>
                    

                    <Can I="view" a="Campaigns">
                        <ListItem button component={LinkRouter} to="/campaign">
                            <Tooltip title="Campaigns">
                                <ListItemIcon><DevicesIcon /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={"Campaign"} />
                        </ListItem>
                    </Can>
                    

                    
                    <Can I="view" a="SavedLocations">
                        <ListItem button component={LinkRouter} to="/savedlist">
                            <Tooltip title="Saved locations/Screens from inventory">
                                <ListItemIcon><LibraryBooks /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={"Saved List"} />
                        </ListItem>
                    </Can>

                    
                    <Can I="update" a="Screens">
                        <ListItem button component={LinkRouter} to="/screenController">
                            <Tooltip title="Screen controller">
                                <ListItemIcon><Tune /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={"Screen Controller"} />
                        </ListItem>
                    </Can>

                    <Can I="update" a="Screens">
                        <ListItem button component={LinkRouter} to="/campaignApproval">
                            <Tooltip title="Campaign approval">
                                <ListItemIcon><AssignmentTurnedIn /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={"Campaign Approval"} />
                        </ListItem>
                    </Can>

                    <Can I="update" a="AgencyApproval">
                        <ListItem button component={LinkRouter} to="/campaignApprovalAgency">
                            <Tooltip title="Campaign approval">
                                <ListItemIcon><AssignmentTurnedIn /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={"Campaign Approval"} />
                        </ListItem>
                    </Can>

                    
                    <Can I="view" a="SharedCampaigns">
                        <ListItem button component={LinkRouter} to="/sharedCampaigns">
                            <Tooltip title="Shared campaigns">
                                <ListItemIcon><ScreenShare /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={"Shared Campaigns"} />
                        </ListItem>
                    </Can>

                    <Can I="view" a="SharedReports">
                        <ListItem button component={LinkRouter} to="/sharedReports">
                            <Tooltip title="Shared reports">
                                <ListItemIcon><Assignment /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={"Shared Reports"} />
                        </ListItem>
                    </Can>

                    <Can I="view" a="Clients">
                        <ListItem button component={LinkRouter} to="/clients">
                            <Tooltip title="Users">
                                <ListItemIcon><Group /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={"Users"} />
                        </ListItem>

                    </Can>

                    <Can I="view" a="Reports">
                        <ListItem button component={LinkRouter} to="/reports">
                            <Tooltip title="Reports">
                                <ListItemIcon><Assignment /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={"Reports"} />
                        </ListItem>

                    </Can>

                    <ListItem button component={LinkRouter} to="/user">
                        <Tooltip title="Profile">
                            <ListItemIcon><AccountCircle /></ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={"Profile"} />
                    </ListItem>

                </List>
                <Divider />
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {children}
            </main>
        </div>
    )
}

const mapStateToprops = (state) => {

    return {
        auth: state.auth
    }
}

export default connect(mapStateToprops, { logoutUser })(Navbar);