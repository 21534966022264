import { Ability, AbilityBuilder } from "@casl/ability";
import store from "../store";

function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }
  return item.__type;
}

const ability = new Ability([], { subjectName });
// const ability = new Ability([
//   {
//     action : 'view',
//     subject : 'Campaigns'
//   },
//   {
//     action : 'view',
//     subject : "Screens",
//     inverted : true,
//     reason : "Only For Publisher"
//   }
// ]);

// console.log("Ability Main :", store);


let currentUser;
store.subscribe(() => {
  const prevUser = currentUser;

  currentUser = store.getState().auth.user;
  // console.log("Store :", currentUser);

  if(prevUser !== currentUser){

    ability.update(defineAbilityForUser(currentUser));
  }

  // console.log("define :", defineAbilityForUser(currentUser));
  console.log("Can ?", ability.can("view", 'Screens'));
})

function defineAbilityForUser(user){

  const { can, rules, cannot } = new AbilityBuilder();

  // console.log("User :", user.role);

  if(user.role === "Media Owner"){

    can("view", "Media");

    can('view', 'Screens');
    can('add', 'Screens');
    can('remove', 'Screens');
    can('update', 'Screens');

    can('view', 'Creatives');
    can('add', 'Creatives');
    can('update', 'Creatives');
    can('remove', 'Creatives');

    can('view', 'Campaigns');
    can('add', 'Campaigns');
    can('remove', 'Campaigns');
    can('update', 'Campaigns');

    can('view', 'Inventory');
    can('add', 'Inventory');
    can('remove', 'Inventory');
    can('update', 'Inventory');


    can('view', 'SavedLocations');
    can('add', 'SavedLocations');
    can('remove', 'SavedLocations');
    can('update', 'SavedLocations');

    can('view', 'SubUsers');
    can('add', 'Subusers');
    can('remove', 'Subusers');
    can('update', 'Subusers');

    can('view', 'Reports');

  } else if(user.role === "Advertiser"){

    can('view', 'Creatives');
    can('add', 'Creatives');
    can('update', 'Creatives');
    can('remove', 'Creatives');

    can('view', 'Campaigns');
    can('add', 'Campaigns');
    can('remove', 'Campaigns');
    can('update', 'Campaigns');

    can('view', 'Inventory');
    can('add', 'Inventory');
    can('remove', 'Inventory');
    can('update', 'Inventory');


    can('view', 'SavedLocations');
    can('add', 'SavedLocations');
    can('remove', 'SavedLocations');
    can('update', 'SavedLocations');

    can('view', 'SubUsers');
    can('add', 'Subusers');
    can('remove', 'Subusers');
    can('update', 'Subusers');

    can('view', 'Reports');

    cannot('view', 'Screens').because("Only for Publisher");
    
  } else if (user.role === "AgencyPartnerAdmin"){

    can('view', 'Clients');
    can('add', 'Clients');
    can('remove', 'Clients');
    can('update', 'Clients');

    can('view', 'AgencyApproval');
    can('add', 'AgencyApproval');
    can('remove', 'AgencyApproval');
    can('update', 'AgencyApproval');

    can('view', 'CreativeApproval')

    // can('view', '');
    // can('add', '');
    // can('remove', '');
    // can('update', '');

    can('view', 'Reports');

  } else if( user.role === "Client"){

    can('view', 'SharedCampaigns');
    can('view', 'SharedReports');
    can('view', 'SharedCreatives');

    can('view', 'Reports');

  } else {

    can('view', 'SharedCampaigns');
    can('view', 'SharedReports');
    can('view', 'SharedCreatives');

    can('view', 'Reports');

  }

  // console.log("Can ?", ability.can("view", 'Screens'));

  return rules;

}



export default ability;