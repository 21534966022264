import React from 'react';
import {Container} from "@material-ui/core";
import {Route, Switch} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Register from "../Auth/Register";
import Login from "../Auth/Login";
import Dashboard from "../Dashboard/Dashboard";
import Screens from '../Media/Media';
import ScreenEdit from "../Media/Screens/ScreenEdit";
import AddScreen from "../Media/Screens/AddScreen";
import Creatives from "../Creatives/Creatives";
import NotFound from '../Layout/NotFound';
import Inventory from "../Inventory/Inventory";
import Campaign from "../Campaign/Campaign";
import PerCampaignStat from "../Campaign/PerCampiagnStats";
import CreateCampaign from "../Campaign/CampaignNew/CreateCampaign";
import Approve from "../Admin/Approve/Approve";
import ShowSavedList from "../Advertiser/ShowSavedList";
import CampaignForm from "../Campaign/CampaignForm";
import ShowCampaignScreens from "../Campaign/ShowCampaignScreens";
import ShowCampaignCreatives from "../Campaign/ShowCampaignCreative";
import ScreenController from "../Media/ScreenController/ScreenController";
import Profile from "../User/Profile";
import SharedCampaigns from "../SubUserData/SharedCampains";
import SharedReports from "../SubUserData/SharedReports";
import Clients from "../AgencyPartner/Clients/ClientTable";
import AddAgencyClients from "../AgencyPartner/Clients/AddClientsPage";
import UpdateClientMarkup from "../AgencyPartner/Clients/UpdateClientMarkup";
import CampaignApproval from "../Publishers/CampaignApproval";
import ScreensForApproval from "../Publishers/ScreensForApproval";
import InvoiceUpload from "../Admin/InvoiceUpload";
import CampaignApprovalAgency from "../AgencyPartner/CampaignApproval/CampaignApprovalByAgency"; // Table
import ScreenApprovalByAgency from "../AgencyPartner/CampaignApproval/ScreenApprovalByAgency"; // Approval Table
import Reports from "../Reports/Reports";
import Can from "../../Config/Can";

const Routes = () => {
    return (
        <Container maxWidth={false} disableGutters={true}>
        
        <Switch>

          <Route path="/register" component={Register}/>
          <Route path="/login" component={Login}/>
          <PrivateRoute path="/"  component={Dashboard} exact/>
          <PrivateRoute path="/dashboard" component={Dashboard}/>
          <PrivateRoute path="/media" component={(props) => (
            <Can I="view" a="Screens">
              <Screens {...props} />
            </Can>
          ) } exact/>
          {/* <PrivateRoute path="/media" component={Screens} exact/> */}
          <PrivateRoute path="/addscreen" component={(props) => (
            <Can I="add" a="Screens">
              <AddScreen {...props} />
            </Can>
          )} exact/>

          <PrivateRoute path="/screens/edit/:id" component={(props) => (
            <Can I="update" a="Screens">
              <ScreenEdit {...props} />
            </Can>
          )} exact />


          <PrivateRoute path="/screenController" component={(props) => (
            <Can I="update" a="Screens">
              <ScreenController {...props}/>
            </Can>
          )} exact />

          <PrivateRoute path="/creatives" component={(props) => (
            <Can I="view" a="Creatives">
              <Creatives {...props} />
            </Can>  
          )} exact />

          <PrivateRoute path="/inventory" component={(props) => (
            <Can I="view" a="Inventory">
              <Inventory {...props} />
            </Can>   
          )} exact />

          <PrivateRoute path="/campaign" component={(props) => (
            <Can I="view" a="Campaigns">
              <Campaign {...props} />
            </Can> 
          )} exact />

          <PrivateRoute path="/campaign/:id/campaignCounts" component={(props) => (
            <Can I="view" a="Campaigns">
              <PerCampaignStat {...props} />
            </Can> 
          )} exact />

          <PrivateRoute path="/campaign/:id/creatives" component={(props) => (
            <Can I="view" a="Campaigns">
              <ShowCampaignCreatives {...props} />
            </Can> 
          )} exact />

          <PrivateRoute path="/campaignApprovalAgency/:id/creatives" component={(props) => (
            <Can I="view" a="CreativeApproval">
              <ShowCampaignCreatives {...props} />
            </Can> 
          )} exact />

          <PrivateRoute path="/campaignApproval/:id/creatives" component={(props) => (
            <Can I="view" a="Campaigns">
              <ShowCampaignCreatives {...props} />
            </Can> 
          )} exact />

          {/* <PrivateRoute path="/campaign" component={Campaign} exact /> */}
          <PrivateRoute path="/campaign/create/dooh" component={(props) => (
            <Can I="add" a="Campaigns">
              <CampaignForm {...props} />
            </Can>
          )} exact />

          <PrivateRoute path="/campaign/createCampaign" component={(props) => (
            <Can I="add" a="Campaigns">
              <CreateCampaign {...props} />
            </Can>
          )} exact />

          <PrivateRoute path="/campaign/:id/screens" component={(props) => (
            <Can I="view" a="Campaigns">
              <ShowCampaignScreens {...props} />
            </Can>
          )} exact />

          <PrivateRoute path="/campaignApproval" component={(props) => (
            <Can I="update" a="Screens">
              <CampaignApproval {...props} />
            </Can>
          )} exact />

          <PrivateRoute path="/campaignApprovalAgency" component={(props) => (
            <Can I="update" a="AgencyApproval">
              <CampaignApprovalAgency {...props} />
            </Can>
          )} exact />

          <PrivateRoute path="/campaignApproval/:id/screens" component={(props) => (
            <Can I="update" a="Screens">
              <ScreensForApproval {...props} />
            </Can>
          )} exact />

          <PrivateRoute path="/campaignApprovalAgency/:id/screens" component={(props) => (
            <Can I="update" a="AgencyApproval">
              <ScreenApprovalByAgency {...props} />
            </Can>
          )} exact />

          <PrivateRoute path="/approve" component={(props) => (
            <Can I="update" a="Creatives">
              <Approve {...props}/>
            </Can>
          )} exact />

          <PrivateRoute path="/savedlist" component={(props) => (
            <Can I="view" a="SavedLocations">
              <ShowSavedList {...props} />
            </Can>
          )} exact />

          <PrivateRoute path="/clients" component={(props) => (
            <Can I="view" a="Clients">
              <Clients {...props} />
            </Can>
            
          )} exact />

          <PrivateRoute path="/clients/addAgencyClients" component={(props) => (
            <Can I="view" a="Clients">
              <AddAgencyClients {...props} />
            </Can>
            
          )} exact />
          
          <PrivateRoute path="/clients/updateAgencyMarkup" component={(props) => (
            <Can I="update" a="Clients">
              <UpdateClientMarkup {...props} />
            </Can>
            
          )} exact />

          <PrivateRoute path="/user" component={Profile} exact />

          <PrivateRoute path="/sharedCampaigns" component={(props) => (
            <Can I="view" a="SharedCampaigns">
              <SharedCampaigns {...props}/>
            </Can>
          )} exact />

          <PrivateRoute path="/sharedReports" component={(props) => (
            <Can I="view" a="SharedReports">
              <SharedReports {...props} />
            </Can>
          )} exact />

          <PrivateRoute path="/invoiceUpload" component={(props) => (
            <Can I="view" a="Campaigns">
              <InvoiceUpload {...props} />
            </Can>
          )} exact />

          <PrivateRoute path="/reports" component={(props) => (
            <Can I="view" a="Reports">
              <Reports {...props} />
            </Can>
          )} exact />


          <PrivateRoute component={NotFound} exact />
          <Route component={NotFound} />

        </Switch>

      </Container>
    )
}

export default Routes;