import React, { useState, useEffect } from 'react';
import { Button } from "@material-ui/core";
import { Wifi } from "@material-ui/icons";
import { green, purple, grey } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles"
import axios from 'axios';
import mqttClient from "../../../Utils/mqtt_config";

function ScreenStatus({data}) {

  const [ lastLog, setLastLog ] = useState("");
  const [ status, setStatus ] = useState("");

  const CustonButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(purple[500]),
      backgroundColor: status === "Online" ? green[500] : grey[500],
      '&:hover': {
        backgroundColor: status === "Online" ? green[700] : grey[600],
      },
    },
  }))(Button);

  // console.log("data :", data);

  const autoCheckStatusMQTT = () => {
    const { screen_name, user_id } = data;

    setStatus("check screen");
    mqttClient.subscribe(`cmd_check_status_${user_id}_${screen_name}`);

    mqttClient.on("message", (topic, iotData) => {

			if (topic.includes(screen_name)) {
				const iotDataJSON = JSON.parse(iotData.toString());
				if (iotDataJSON.status === "Online") {
					// if (this._isMounted) {
					// 	setStatus("Online");
          // }
          setStatus("Online");
				}
			}
    });
    
    mqttClient.publish(
			`cmd_${screen_name}_${user_id}`,
			JSON.stringify({ message: { cmd: "check_status" } })
		);
  }


  const handleCheckStatus = async () => {
    // console.log("Clicked");
    const { screen_name } = data;
    autoCheckStatusMQTT();

    try {

      let report_url = "https://screenoapplogs.locad.net";

      const result = await axios.post(`${report_url}/screeno_api/lastLog`, {screen_name});

      let { lastLog } = result.data;
      lastLog = lastLog === "" ? "" : new Date(lastLog).toLocaleString();
      setLastLog(lastLog);

    } catch(err){
      console.log("Error :", err);
    }
  }

  useEffect(() => {
    autoCheckStatusMQTT();
  },[]);

  return (
    <div>
      <CustonButton
        variant="contained"
        color="default"
        onClick={handleCheckStatus}
        fullWidth
      >
        <Wifi /> {lastLog === "" ? status : lastLog}
      </CustonButton>
    </div>
  )
}

export default ScreenStatus;
