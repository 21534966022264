// import React, { useState } from 'react';
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Modal, Button, Typography, Tabs, Tab, Box, AppBar
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { Backup } from "@material-ui/icons";
// import { createMuiTheme, withStyles, ThemeProvider } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import { connect } from "react-redux";
import ImageUpload from "./Upload/ImageUpload";
import VideoUpload from "./Upload/VideoUpload";
import WebUpload from "./Upload/WebUpload";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none"
  },
  margin: {
    margin: theme.spacing(1),
  },

  anchor: {
    textDecoration: "none"
  },

  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  }

}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function CreativeUploadModal(props) {

  const { clearCreativeError } = props;
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);


  const handleChange = (event, newValue) => {
    
    setValue(newValue);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    
  };


  return (
    <React.Fragment>
      <Button type="button" onClick={handleOpen} variant="contained" color="primary" className={props.class} startIcon={<Backup />}>
        Upload Creative
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.root}>

              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="Images" {...a11yProps(0)} />
                  <Tab label="Videos" {...a11yProps(1)} />
                  <Tab label="Web" {...a11yProps(2)} />
                </Tabs>
              </AppBar>

              <TabPanel value={value} index={0} dir={theme.direction}>
                <ImageUpload close={handleClose}/>
              </TabPanel>

              <TabPanel value={value} index={1} dir={theme.direction}>
                <VideoUpload close={handleClose}/>
              </TabPanel>

              <TabPanel value={value} index={2} dir={theme.direction}>
                <WebUpload close={handleClose}/>
              </TabPanel>

            </div>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}

export default connect(null, {})(CreativeUploadModal);