import React, { useState } from 'react';
import { Link as LinkRouter } from "react-router-dom";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {
    TextField, Button, Grid, Container, FormControl, InputLabel, FormHelperText, Select, MenuItem
} from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import MapModal from "./MapModal";
import { connect } from "react-redux";
import { addScreen } from "../../../Actions/screensActions";

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width : "100%"
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));

const AddScreen = (props) => {

    // const {state} = props.location;
    // console.log(state);
    const classes = useStyles();
    const [cords , setCords ] = useState({
        screen_name : "",
        location : "",
        lat : "",
        lng: "",
        screen_count : 1,
        network: "",
        network_name : "",
        cpm: "",
        purchase_type : "",
        currency: "",
        specification: "",
        availability: "",
        final_score : "",
        category: "",
        state :"",
        city: "",
        country: "",
        footfall:"",
        impressions: "",
        loop_time: "",
        monthly_cost: "",
        ad_slot : "",
        operating_hours: "",
        screen_resolution: ""
    });


    return (
        <div>
            <Container>

            <Button
                variant="contained"
                color="primary"
                component={LinkRouter}
                to={`/media`}
            >
                < ArrowBackIos />Back
            </Button>

            <Formik
                enableReinitialize={true}
                initialValues={cords}
                onSubmit={async (data, {setSubmitting, resetForm }) => {
                    const formData = {...data};

                    formData.latlng = JSON.stringify({lat : data.lat, lng: data.lng});

                    delete formData.lat;
                    delete formData.lng;

                    // console.log(formData);

                    props.addScreen(formData, ()=>{
                        props.history.push("/media");
                    });

                }}
                validationSchema={Yup.object().shape({
                    screen_name : Yup.string().required("Screen Name is required"),
                    location : Yup.string().required("Address is required"),
                    lat : Yup.number().required("Latitude is required"),
                    lng : Yup.number().required("Longitude is required"),
                    screen_count : Yup.number().required("Screen count is required"),
                    network : Yup.string().required("Network name is required"),
                    network_name : Yup.string().required("Publisher network name is required"),
                    category : Yup.string().required("Category is required"),
                    state : Yup.string().required("State is required"),
                    country : Yup.string().required("Country is required"),
                    city : Yup.string().required("City is required"),
                    impressions : Yup.number().required("Impression count is required"),
                    footfall : Yup.number().required("Footfall count is required"),
                    loop_time : Yup.number().required("Loop time is required"),
                    ad_slot : Yup.number().required("Ad slot is required"),
                    monthly_cost : Yup.number().required("Monthly Cost is required"),
                    screen_resolution : Yup.string().required("Screen resolution is required"),
                    operating_hours : Yup.number().required("Operating Hours info required")
                })}

            >
                {({values, handleChange, handleSubmit, isSubmitting, errors, touched}) => (
                    <Form onSubmit={handleSubmit}>
                        <Grid container spacing={4}>
                            <Grid item md={6}>
                                <Field
                                    name="screen_name"
                                    as={TextField}
                                    // placeholder="Screen Name"
                                    fullWidth
                                    label="Screen Name"
                                    error={touched.screen_name && !!errors.screen_name}
                                    helperText={touched.screen_name && errors.screen_name}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Field
                                    name="location"
                                    as={TextField}
                                    // placeholder="Address"
                                    label="Address"
                                    fullWidth
                                    error={touched.location && !!errors.location}
                                    helperText={touched.location && errors.location}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Field
                                    name="lat"
                                    as={TextField}
                                    // placeholder="Address"
                                    label="Latitude"
                                    fullWidth
                                    error={touched.lat && !!errors.lat}
                                    helperText={touched.lat && errors.lat}
                                    type="number"
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Grid container>
                                    <Grid item md={8}>
                                        <Field
                                            name="lng"
                                            as={TextField}
                                            // placeholder="Address"
                                            label="Longitude"
                                            fullWidth
                                            error={touched.lng && !!errors.lng}
                                            helperText={touched.lng && errors.lng}
                                            type="number"
                                        />
                                        
                                    </Grid>
                                    <Grid item md={4}>
                                        {/* <Button variant="contained">Locate on Map</Button>  */}
                                        <MapModal cords={values}  setCords={setCords} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                            <Grid item md={6}>
                                <Field
                                    name="screen_count"
                                    as={TextField}
                                    // placeholder="Address"
                                    label="Screen Count"
                                    fullWidth
                                    error={touched.screen_count && !!errors.screen_count}
                                    helperText={touched.screen_count && errors.screen_count}
                                    type="number"
                                />
                            </Grid>
                            <Grid item md={6}>
                                
                                <FormControl error={errors.network && touched.network} fullWidth>
                                    <InputLabel id="network">Network</InputLabel>
                                    <Field
                                        as={Select}
                                        labelId="network"
                                        id="network"
                                        name="network"
                                        value={values.network}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="Business">Business</MenuItem>
                                        <MenuItem value="In-Store">In-Store</MenuItem>
                                        <MenuItem value="Leisure">Leisure</MenuItem>
                                        <MenuItem value="Outdoor">Outdoor</MenuItem>
                                        <MenuItem value="Residential">Residential</MenuItem>
                                        <MenuItem value="Transit">Transit</MenuItem>
                                    </Field>
                                    <FormHelperText>{touched.network && errors.network}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item md={6}>
                                
                                <FormControl error={errors.category && touched.category} fullWidth>
                                    <InputLabel id="category">Category</InputLabel>
                                    <Field
                                        as={Select}
                                        labelId="category"
                                        id="category"
                                        name="category"
                                        value={values.category}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="Airport">Airport</MenuItem>
                                        <MenuItem value="Automotive Retail">Automotive Retail</MenuItem>
                                        <MenuItem value="Cafe Network">Cafe Network</MenuItem>
                                        <MenuItem value="Corporate">Corporate</MenuItem>
                                        <MenuItem value="Electronic Retail">Electronic Retail</MenuItem>
                                        <MenuItem value="Metro Station">Metro Station</MenuItem>
                                        <MenuItem value="Outdoor Digital">Outdoor Digital</MenuItem>
                                        <MenuItem value="Premium">Premium</MenuItem>
                                        <MenuItem value="Pub Network">Pub Network</MenuItem>
                                        <MenuItem value="Railway Station">Railway Station</MenuItem>
                                        <MenuItem value="Residential">Residential</MenuItem>
                                        <MenuItem value="Restaurant">Restaurant</MenuItem>
                                        <MenuItem value="Store">Store</MenuItem>
                                    </Field>
                                    <FormHelperText>{touched.category && errors.category}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item md={6}>
                                <Field
                                    name="network_name"
                                    as={TextField}
                                    // placeholder="Address"
                                    label="Publisher Network"
                                    fullWidth
                                    error={touched.network_name && !!errors.network_name}
                                    helperText={touched.network_name && errors.network_name}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Field
                                    name="cpm"
                                    as={TextField}
                                    type="number"
                                    label="CPM"
                                    fullWidth
                                    error={touched.cpm && !!errors.cpm}
                                    helperText={touched.cpm && errors.cpm}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Field
                                    name="currency"
                                    as={TextField}
                                    label="Currency (INR,USD etc.)"
                                    fullWidth
                                    error={touched.currency && !!errors.currency}
                                    helperText={touched.currency && errors.currency}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Field
                                    name="state"
                                    as={TextField}
                                    // placeholder="Address"
                                    label="State"
                                    fullWidth
                                    error={touched.state && !!errors.state}
                                    helperText={touched.state && errors.state}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Field
                                    name="city"
                                    as={TextField}
                                    // placeholder="Address"
                                    label="City"
                                    fullWidth
                                    error={touched.city && !!errors.city}
                                    helperText={touched.city && errors.city}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Field
                                    name="country"
                                    as={TextField}
                                    // placeholder="Address"
                                    label="Country"
                                    fullWidth
                                    error={touched.country && !!errors.country}
                                    helperText={touched.country && errors.country}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Field
                                    name="impressions"
                                    as={TextField}
                                    // placeholder="Address"
                                    label="Monthly Impressions"
                                    fullWidth
                                    error={touched.impressions && !!errors.impressions}
                                    helperText={touched.impressions && errors.impressions}
                                    type="number"
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Field
                                    name="footfall"
                                    as={TextField}
                                    // placeholder="Address"
                                    label="Monthly Footfalls"
                                    fullWidth
                                    error={touched.footfall && !!errors.footfall}
                                    helperText={touched.footfall && errors.footfall}
                                    type="number"
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Field
                                    name="monthly_cost"
                                    as={TextField}
                                    // placeholder="Address"
                                    label="Monthly Cost"
                                    fullWidth
                                    error={touched.monthly_cost && !!errors.monthly_cost}
                                    helperText={touched.monthly_cost && errors.monthly_cost}
                                    type="number"
                                />
                            </Grid>
                            <Grid item md={6}>

                                <FormControl error={errors.purchase_type && touched.purchase_type} fullWidth>
                                    <InputLabel id="purchase_type">Purchase Type</InputLabel>
                                    <Field
                                        as={Select}
                                        labelId="Purchase type"
                                        id="purchase_type"
                                        name="purchase_type"
                                        value={values.purchase_type}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="Individual">Individual</MenuItem>
                                        <MenuItem value="Network">Network</MenuItem>
                                      
                                    </Field>
                                    <FormHelperText>{touched.purchase_type && errors.purchase_type}</FormHelperText>
                                </FormControl>

                            </Grid>

                            <Grid item md={6}>

                                <FormControl error={errors.availability && touched.availability} fullWidth>
                                    <InputLabel id="availability">Availability</InputLabel>
                                    <Field
                                        as={Select}
                                        labelId="availability"
                                        id="availability"
                                        name="availability"
                                        value={values.availability}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="Available">Available</MenuItem>
                                        <MenuItem value="Unavailable">Unavailable</MenuItem>
                                    </Field>
                                    <FormHelperText>{touched.availability && errors.availability}</FormHelperText>
                                </FormControl>

                            </Grid>
                            <Grid item md={6}>
                                <Field
                                    name="operating_hours"
                                    as={TextField}
                                    // placeholder="Address"
                                    label="Operating Hours"
                                    fullWidth
                                    error={touched.operating_hours && !!errors.operating_hours}
                                    helperText={touched.operating_hours && errors.operating_hours}
                                    type="number"
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Field
                                    name="ad_slot"
                                    as={TextField}
                                    // placeholder="Address"
                                    label="Ad Slot(sec)"
                                    fullWidth
                                    error={touched.ad_slot && !!errors.ad_slot}
                                    helperText={touched.ad_slot && errors.ad_slot}
                                    type="number"
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Field
                                    name="specification"
                                    as={TextField}
                                    // placeholder="Address"
                                    label="Specification"
                                    fullWidth
                                    error={touched.specification && !!errors.specification}
                                    helperText={touched.specification && errors.specification}
                                    
                                />
                            </Grid>
                            {/* <Grid item md={6}>
                                <Field
                                    name="final_score"
                                    as={TextField}
                                    // placeholder="Address"
                                    label="Final Score"
                                    fullWidth
                                    error={touched.final_score && !!errors.final_score}
                                    helperText={touched.final_score && errors.final_score}
                                    type="number"
                                />
                            </Grid> */}
                            <Grid item md={6}>
                                <Field
                                    name="loop_time"
                                    as={TextField}
                                    // placeholder="Address"
                                    label="Loop Time(Min)"
                                    fullWidth
                                    error={touched.loop_time && !!errors.loop_time}
                                    helperText={touched.loop_time && errors.loop_time}
                                    type="number"
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Field
                                    name="screen_resolution" 
                                    as={TextField}
                                    // placeholder="Address"
                                    label="Screen Resolution"
                                    fullWidth
                                    error={touched.screen_resolution && !!errors.screen_resolution}
                                    helperText={touched.screen_resolution && errors.screen_resolution}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <Button type="submit" variant="contained" color="primary"> Submit </Button>
                            </Grid>

                            {/* <Grid item md={6}>
                                <Button type="submit" variant="contained" color="primary"> Submit </Button>
                            </Grid> */}
                        </Grid>
                        
                    </Form>
                )}
            </Formik>
            </Container>
        </div>
    )
}

export default connect(null, {addScreen})(AddScreen);