import {
  ADD_SUB_USER, GET_PROFILE, GET_SUB_USERS, UPDATE_PROFILE, REMOVE_CLIENT
} from "../Actions/types";
import _ from "lodash";


const initState = {
  profileSet : false, 
  user :  {},
  subUsers : []
};


export default (state=initState, action) => {
  const { type, payload } = action;

  switch(type){
    case GET_PROFILE:
      return {
        ...state,
        user : payload,
        profileSet : !_.isEmpty(payload)
      }

    case UPDATE_PROFILE:
      return {
        ...state,
        user : {
          ...state.user,
          ...payload
        }
      }

    case GET_SUB_USERS:
      return {
        ...state,
        subUsers : payload
      }

    case ADD_SUB_USER :
      return {
        ...state,
        subUsers : [...state.subUsers, payload]
      }
    
      case REMOVE_CLIENT : 
      return {
        ...state,
        subUsers : state.subUsers.filter(s => s._id !== payload)
      }

    default:
      return state
  }
}