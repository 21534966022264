import React, { useState, useEffect } from 'react';
import { Button, Modal } from "@material-ui/core";
import { Schedule } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';
import moment from "moment";


import WeekCalander from "react-week-calendar";
import "react-week-calendar/dist/style.css";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY : "scroll",
    // height : '80vh'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none"
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const ShowCampaignSchedule = ({data}) => {

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  // const { schedule } = data;
  const [ schedule, setSchedule ] = useState([
    ...data.map( d => {
                
      return {
        start : moment(new Date(new Date(d.start).toISOString())),
        end : moment(new Date(new Date(d.end).toISOString())),
        value : ""
      }
    })
  ]);
  // console.log("Data :", data);

  const handleOpen = () => {
    setOpen(true);

  }

  const handleClose = () => {
    setOpen(false);

  };


  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        startIcon={<Schedule />}
        className={classes.margin}
      >
        View
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Campaigns Schedule</h2>
            <Paper style={{width: "60vw", padding: "1rem" }}>
              
              {/* <div>Test</div> */}
              {
                data.length > 0 ? (
                   <WeekCalander  dayFormat='dddd'scaleUnit={120} selectedIntervals={data} useModal={false} />
                ) : <h2>Run all the time</h2>
              }

            </Paper>
          </div>
        </Fade>
      </Modal>

    </div>
  )
}

export default ShowCampaignSchedule;
