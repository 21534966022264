import React, { useEffect } from 'react';
import { Grid, Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import MaterialTable, { MTableToolbar } from 'material-table';
import { makeStyles} from '@material-ui/core/styles';
import CreativePreview from "../Creatives/CreativePreview"

const ShowCampaignCreatives = (props) => {

  const useStyles = makeStyles((theme) => ({
      margin: {
        margin: theme.spacing(1),
      },
  }));

  const classes = useStyles();
  // console.log("cretiv", history);
  let history = useHistory();
  const goToPreviousPath = () => {
    history.goBack()
  }

  const { state : { creatives } } = props.location;

  return (
    <div style={{maxWidth : "100%"}}>

      <Button
        color="primary"
        variant="contained"
        onClick={goToPreviousPath}
        className={classes.margin}
        startIcon={<ArrowBack/>}
      >
        Back
      </Button>

      <MaterialTable 
          title="Creatives"

          columns={[
              {title :"Preview",  headerStyle : { fontWeight : "bold" }, render : rowData => {
                  return <CreativePreview data={rowData} />
              }},
              {title :"File Name", field : "original_name",  headerStyle : { fontWeight : "bold" }},
              {title :"Type", field : "file_type",  headerStyle : { fontWeight : "bold" }},
              {title :"Duration(sec)", field : "duration",  headerStyle : { fontWeight : "bold" }},
              {title :"File Size",  headerStyle : { fontWeight : "bold" }, render : rowData => {
                  if(rowData.file_type.includes("html")){
                      return "-"
                  } else {

                      return `${parseInt(rowData.file_size/1024)} KB`;
                  }
              }},
              {title :"Uploaded on", field : "created_at", headerStyle : { fontWeight : "bold" }, type : "datetime"}

          ]}

          data={[...creatives]}
          
      />
        
    </div>
  )
}



export default ShowCampaignCreatives;
