import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useField } from "formik";

const demoImage = "https://screeno.locad.net/img/no-image.jpg";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();

  const {marker, setFieldValue, values} = props;

  // console.log("setFieldValue", setFieldValue);
  // console.log("values", values);

  const handleRemoveFromCart = (marker) => {

    const { screens_from_map } = values;

    const result = screens_from_map.filter(screen => screen.screen_name !== marker.screen_name);

    setFieldValue("screens_from_map", result);

  }

  const handleAddToCart = () => {

    const { screens_from_map } = values;

    const result = [...screens_from_map, marker];

    setFieldValue("screens_from_map", result);

    
  }
  
  const showButtons = (marker) => {
    if(values.screens_from_map.find(s => s.screen_name === marker.screen_name)){

      return <Button size="small" color="secondary" variant="contained" onClick={() => handleRemoveFromCart(marker)}>
        remove
      </Button>

    } else {
      
      return <Button size="small" color="primary" variant="contained" onClick={() => handleAddToCart(marker)}>
        Add
      </Button>
      
    }
  }

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={marker.file_url === "" ? demoImage : marker.file_url }
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {marker.screen_name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <div><strong>Address :</strong> {marker.location}</div>
            <div><strong>Network :</strong> {marker.network}</div>
            <div><strong>Category :</strong> {marker.category}</div>
            <div><strong>CPM :</strong> {marker.cpm}</div>
            <div><strong>GRP :</strong> {parseFloat(marker.impressions/marker.footfall).toFixed(2)}</div>
            
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {showButtons(marker)}
      </CardActions>
    </Card>
  );
}