import React, { useEffect, useState } from 'react';
import { Button, Grid } from "@material-ui/core";
import { Add, Sync } from "@material-ui/icons";
import { connect } from "react-redux";
import MaterialTable, { MTableToolbar } from 'material-table';
import { makeStyles} from '@material-ui/core/styles';
import { fetchScreens } from "../../../Actions/screensActions";
import UnpairScreen from "./UnpairScreenModal";
import ScreenShotModal from "./ScreenshotModal";
import ScreenStatus from "./ScreenStatus";
import ScreenshotGallery from "./ScreenshotGallery";
import ScreenLiveLogs from "./ScreenLiveLogs";
import mqttClient from "../../../Utils/mqtt_config";

function ScreenController(props) {

  const { screens, fetchScreens } = props;

  const [counter, setCounter] = useState(0);

  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  useEffect(() =>{
    fetchScreens();
  },[]);

  const renderData = (rowData) => {
    return;
  }

  const renderUnpairScreen = (rowData) => {
    return <UnpairScreen data={rowData} />
  }

  const renderScreenshotScreen = (rowData) => {
    return <ScreenShotModal data={rowData}/>
  }

  const screenStatusRender = (rowData) => {
    return <ScreenStatus data={rowData} />
  }

  const screenshotGalleryRender = (rowData) => {
    return <ScreenshotGallery data={rowData}/>
    // return <p>hi</p>
  }

  const screenLiveLogsRender = (rowData) => {
    return <ScreenLiveLogs data={rowData}/>
    // return <p>hi</p>
  }

  const handleScreenRefresh = (rowData) => {

    const { user_id, screen_name } = rowData;
    mqttClient.publish(
      `cmd_${screen_name}_${user_id}`,
      JSON.stringify({ message: { cmd: "refresh" } })
    );
  }
  
  // useEffect(() => {
  //   if(screens.length > 0){
      
  //     screens.forEach(s => {
  //       console.log("I ran ", s.screen_name);
  //       mqttClient.publish(
  //         `cmd_${s.screen_name}_${s.user_id}`,
  //         JSON.stringify({ message: { cmd: "refresh" } })
  //       );

  //     });
  //   }
  // }, [screens]);

  const massRefresh = () => {
    screens.forEach(s => {
      console.log("I ran ", s.screen_name);
      mqttClient.publish(
        `cmd_${s.screen_name}_${s.user_id}`,
        JSON.stringify({ message: { cmd: "refresh" } })
      );

    });
  }
  

  return (
    <div>
      {
        counter > 3 ? (<>
          <Button
            startIcon={<Sync />}
            color="primary"
            variant="contained"
            className={classes.margin}
            onClick={() => massRefresh()}
          >
            Refresh All
          </Button>

          <Button
            color="secondary"
            variant="contained"
            className={classes.margin}
            onClick={() => setCounter(0)}
          >
            {`Hide`}
          </Button>
        </>) : null 
      }
      <div onClick={() => setCounter(counter + 1)}>
      <MaterialTable
        title="Paired Screens"
        columns={[
          { title: 'Screen Name', field: 'screen_name', headerStyle : { fontWeight : "bold" } },
          { title: 'Location', field: 'location', headerStyle : { fontWeight : "bold" } },
          { title: 'Paired', field: 'paried', render : rowData => rowData.paired.toString(), headerStyle : { fontWeight : "bold" } },
          { title: 'Refresh', render : (rowrData) => {
            return <Button
              onClick={() => handleScreenRefresh(rowrData)}
              variant="contained"
              color="primary"
            >
              <Sync/>
            </Button>
          }, headerStyle : { fontWeight : "bold" } },
          { title: 'Unpair', render : renderUnpairScreen, headerStyle : { fontWeight : "bold" } },
          { title: 'Logs', render : screenLiveLogsRender, headerStyle : { fontWeight : "bold" } },
          { title: 'Screenshot', render : renderScreenshotScreen, headerStyle : { fontWeight : "bold" } },
          { title: 'Screen Status', render : screenStatusRender, headerStyle : { fontWeight : "bold" } },
          { title: 'Screenshot Gallery', render : screenshotGalleryRender, headerStyle : { fontWeight : "bold" } },
        ]}
        data={[...screens.filter(s => s.paired === true)]}
        options={{
          pageSize : 100,
          pageSizeOptions : [30, 50, 100]
        }}
      />
      </div>
      
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    screens : state.screens
  }
}
export default connect(mapStateToProps, { fetchScreens })(ScreenController);