import React, { useEffect} from 'react';
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Card from "./Card";
import { combineCampaignStats } from "../../../Actions/campaignActions";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1, 0, 1, 0),
  },
}));

function CampaignReportingCounts(props) {

  const { stats,  combineCampaignStats } = props;
  const { total_budget, total_budget_spend, total_impressions_delivered, ots } = stats;

  const classes = useStyles();

  const numFormater = (value) => {

    if(!Number.isInteger(value)){
      return value;
    }
   
   
    // if(value > 999 && value < 1000000){

    //   return `${(value/1000).toFixed(2)}K+`;
    // } else if(value > 1000000 && value < 1000000000){

    //   return `${(value/1000000).toFixed(2)}M+`;
    // } else if(value > 1000000000){

    //   return `${(value/1000000000).toFixed(2)}B+`;
    // } else if(value < 1000) {
      
    //   return value;
    // }

    return value;
  }

  useEffect(() => {
    combineCampaignStats()
  }, []);

  return (
    <div className={classes.margin}>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <Card text="Impressions delivered" value={numFormater(total_impressions_delivered)} item="impression count" />
        </Grid>
        <Grid item md={3}>
          <Card text="Budget" value={numFormater(total_budget)} item="impression count"/>
        </Grid>
        <Grid item md={3}>
          <Card text="Total budget spend" value={numFormater(total_budget_spend)} item="budget spend"/>
        </Grid>
        <Grid item md={3}>
          <Card text="General OTS" value={ots} item="screen"/>
        </Grid>
      </Grid>
      
    </div>
  )
}

const mapStateToProps = (state) => {

  return {
    stats : state.campaign.combineCampaignStats
  }
}

export default connect(mapStateToProps, {combineCampaignStats})(CampaignReportingCounts);
