import React from 'react'

function SharedReports() {
  return (
    <div>

      <h2> Under active development</h2>
      
    </div>
  )
}

export default SharedReports;
