import {
    FETCH_ALL_CREATIVES,
    REMOVE_CREATIVE,
    CREATIVE_UPLOAD_SUCCESS,
    APPROVE_CREATIVE_ADMIN,
    DISAPPROVE_CREATIVE_ADMIN,
    UPDATE_DURATION
} from "../Actions/types";

const initialState = [];

export default (state=initialState, action) => {
    const {type, payload} = action;

    switch(type){
        case FETCH_ALL_CREATIVES:
            return payload;
        case REMOVE_CREATIVE:
            return [
                ...state.filter(c => c.original_name !== payload)
            ]
        case CREATIVE_UPLOAD_SUCCESS:
            return [
                payload, ...state 
            ]
        case APPROVE_CREATIVE_ADMIN:
            return [
                ...state.map(s => payload._id === s._id ? payload : s)
            ]
        case DISAPPROVE_CREATIVE_ADMIN : 
            return [
                ...state.map(s => payload._id === s._id ? payload : s)
            ]
        case UPDATE_DURATION : {
            return [
                ...state.map( s => payload._id === s._id ? payload : s)
            ]
        }
        default:
            return state;

    }
}