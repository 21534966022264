import React, { useEffect } from 'react';
import { connect } from "react-redux";
import {
  TableContainer, Table, TableBody, TableCell, TableRow
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { getUserProfile, updateProfileData } from "../../Actions/profileActions";
import ProfileUpdateModal from "./ProfileUpdateModal";
import SubUserTable from "./SubUserTable";
import Can from "../../Config/Can";



const useStyles = makeStyles({
  table: {
    minWidth: 400,
    maxWidth: '50vw'
  },
});

function Profile(props) {

  const classes = useStyles();
  const { getUserProfile, profile, updateProfileData } = props;
  const { name, company_name, role, email, defaultCreative, timezone } = profile.user;

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div>
      <div>
        <h2>User details</h2>
        <ProfileUpdateModal user={props.profile.user} updateProfileData={updateProfileData}/>
        <TableContainer >
          <Table className={classes.table}>
            <TableBody>

              <TableRow>
                <TableCell><strong>Name</strong></TableCell>
                <TableCell>{name}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell><strong>Company Name</strong></TableCell>
                <TableCell>{company_name}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell><strong>E-mail</strong></TableCell>
                <TableCell>{email}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell><strong>Role</strong></TableCell>
                <TableCell>{role}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell><strong>Default Creative</strong></TableCell>
                <TableCell>{defaultCreative ? defaultCreative.original_name : "No set"}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell><strong>Timezone</strong></TableCell>
                <TableCell>{timezone ? timezone : "No set"}</TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </TableContainer>
        <div>
          <Can I="view" a="SubUsers">
            <SubUserTable />
          </Can>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {

  return {
    profile : state.profile
  }

}

export default connect( mapStateToProps,{ getUserProfile, updateProfileData })(Profile);
