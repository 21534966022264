import React from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
// import { Link as LinkRouter } from "react-router-dom";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));


// function ShowCampaignScreens(props) {
const ShowCampaignScreens = (props) => {

  const classes = useStyles();
  const { screens, campaign_name } = props.location.state;
  // const { screens, campaign_name } = props.location.state;

  let history = useHistory();
  const goToPreviousPath = () => {
      history.goBack()
  }

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={goToPreviousPath}
        className={classes.margin}
        startIcon={<ArrowBack/>}
      >
        Back
      </Button>
      <MaterialTable
        title={`${campaign_name} Campaign's Screens`}
        columns={[
          { title: "Screen Name", headerStyle: { fontWeight: "bold" }, field: 'screen.screen_name' },
          { title: "Location", headerStyle: { fontWeight: "bold" }, field: 'screen.location' },
          { title : "Publisher", headerStyle: { fontWeight: "bold" }, field : 'screen.network_name' },
          { title : "Network", headerStyle: { fontWeight: "bold" }, field : "screen.network"},
          { title: "Campaign CPM", headerStyle: { fontWeight: "bold" }, field : "advertiserCPM"},
          // { title: "Operating Hours", headerStyle: { fontWeight: "bold" }, field : "screen.operating_hours"},
         
        ]}

        data={screens}

        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar  {...props} />

              <div>
                <Grid container className={classes.margin}>
                  <Grid item>
                    {/* <Button
                      variant="contained"
                      color="primary"
                      component={LinkRouter}
                      to={`/campaign/create/dooh`}
                      className={classes.margin}
                      startIcon={<Add />}
                    >
                      Add
                    </Button> */}
                  </Grid>
                </Grid>
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}

// const mapStateToProps = () => {

// }

export default connect(null, {})(ShowCampaignScreens);
// export default ShowCampaignScreens;