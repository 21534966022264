import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Button } from "@material-ui/core";
import PropTypes from 'prop-types';
import ReportTable from "./ReportsTable";
import NewReport from "./NewReports";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <Typography
          component="div"
          role="tabpanel"
          hidden={value !== index}
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
          {...other}
      >
          {value === index && <Box p={3}>{children}</Box>}
      </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
  },
}));


function Reports() {

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  const onClickHandler = () => {
    setValue(0);
  }
  

  return (
    <div className={classes.root}>
      <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="Reports" {...a11yProps(0)} />
                    {/* <Tab label="New Report" {...a11yProps(1)} /> */}
                    {/* <Tab label="Shared Reports" {...a11yProps(1)} /> */}
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} dir={theme.direction}>
                <ReportTable />
            </TabPanel>
            {/* <TabPanel value={value} index={1} dir={theme.direction}>
                <NewReport />
            </TabPanel> */}
            {/* <TabPanel value={value} index={1} dir={theme.direction}>
                Shared Reports
            </TabPanel> */}
        </div>
    </div>
  )
}

export default Reports;
