import React, { useState, useEffect } from 'react';
import { Form, Formik, Field  } from "formik";
import { Grid, Button, TextField, FormControl, FormHelperText } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { connect } from "react-redux";
import * as Yup from "yup";
import { fetchAllCampaignsAdmin, uploadCampaignInvoice } from "../../Actions/adminActions";

function InvoiceUpload(props) {

  const { fetchAllCampaignsAdmin, campaigns, uploadCampaignInvoice } = props;

  const [ invoiceData, setInvoiceData] = useState({
    campaign : null,
    file : null,
    filename: ""
  });

  useEffect(() => {
    fetchAllCampaignsAdmin();
  }, []);

  const onSubmit = (data, { resetForm }) => {
    // console.log("Data", data);

    const formData = new FormData();

    formData.append('invoice', data.file);
    formData.append('invoice_name', data.filename);
    formData.append('campaign', data.campaign.campaign_name);
    formData.append('startDate', data.campaign.start_date);
    formData.append('endDate', data.campaign.end_date);
    formData.append('id', data.campaign._id);

    uploadCampaignInvoice(formData, () => {
      resetForm();
      setInvoiceData({
        campaign : null,
        file : null,
        filename: ""
      })
    });
    
  }


  return (
    <div>
      <h2>Invoice Upload</h2>
      <Formik
        initialValues={invoiceData}
        enableReinitialize={true}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          campaign : Yup.object().required("Please select campaign").nullable(),
          filename : Yup.string().required("Please select invoice pdf")
        })}
      >
        {
          ({ values, errors, touched, setFieldValue}) => (
            <Form>
              <Grid container spacing={2}>

                <Grid item md={6} >

                  <Autocomplete
                    value={values.campaign} 
                    id="campaign"
                    name="campaign"
                    options={campaigns}
                    size="small"
                    onChange={(e, value, reason) => {
                      // console.log("Value", value);
                      setFieldValue("campaign", value);
                    }}
                    fullWidth
                    getOptionLabel={ option => option.campaign_name }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="campaign"
                        label="Select Campaign for Invoice" 
                        error={errors.campaign && touched.campaign }
                        helperText={ touched.campaign && errors.campaign }
                      />
                    )}
                  />

                </Grid>
                <Grid item md={6}>

                  <FormControl component="fieldset" error={errors.filename && touched.filename}>

                    <input
                      name="file"
                      // value={invoiceData.filename}
                      type="file"
                      
                      onChange={(e, value) => {
                        // console.log("Value", e.currentTarget.files[0]);
                        // setInvoiceData({
                        //   ...invoiceData,
                        //   filename : e.currentTarget.files[0]
                        // });
                        setFieldValue("file", e.currentTarget.files[0]);
                        setFieldValue("filename", e.currentTarget.files[0].name);
                      }}
                    />
                    { errors.filename && touched.filename ? <FormHelperText>{touched.filename && errors.filename}</FormHelperText> : null }
                  </FormControl>


                </Grid>
                <Grid item md={6}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUpload />}
                  >
                    Submit
                  </Button>
                </Grid>

                
              </Grid>
            </Form>
          )
        }
      </Formik>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    campaigns : state.admin.campaigns
  }
}

export default connect(mapStateToProps, {
  fetchAllCampaignsAdmin, uploadCampaignInvoice
})(InvoiceUpload);
