import { createStore, applyMiddleware, compose} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import RootReducer from "./Reducers";

const initialState = {};

const middlewares = [thunk];


// const store = createStore(RootReducer, initialState, compose(
//     applyMiddleware(...middlewares),
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// ));

const store = createStore(
    RootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;