import { FETCH_ADVERTISER_DASHBOARD, FETCH_PUBLISHER_DASHBOARD, FETCH_DASHBOARD_STATS } from "./types";
import axios from "axios";
import { rootURL } from "../Utils/config";

let elasticScreenoURL;

if(process.env.NODE_ENV === "production"){

	elasticScreenoURL = "https://screenoapplogs.locad.net";
	
} else if(process.env.NODE_ENV === "development"){

	elasticScreenoURL = "http://localhost:5000";
}



export const fetchDashboardDetailsPubisher = () => async dispatch => {

  try {

    const count = await axios.get(`${elasticScreenoURL}/screeno_api/getTotalImpressionCount`);

    const impressionCount = await axios.post(`${elasticScreenoURL}/screeno_api/getImpressionCountForUser`);

    // console.log("Count :", count.data);

    const publisher = await axios.get(`${rootURL}/api/dashboardStatsPublisher`);

    // console.log("Publisher :", publisher.data);

    const { data } = publisher;

    const payload = {
      totalImpressions : count.data.data.count,
      totalScreenCount: 0,
      totalLocationCount: 0,
      screenCount: data.screenCount,
      contentCount: data.contentCount,
      campaignCount: data.campaignCount,
      locationCount : data.locationCount,
      impressionCount : impressionCount.data.count.count > 0 ? impressionCount.data.count.count : "No Data"
    }

    dispatch({
      type : FETCH_PUBLISHER_DASHBOARD,
      payload
    })


  } catch (err) {
    console.log(err);
  }

}

export const fetchDashboardDetailsAdvertiser = () => async dispatch => {

  try {

    const count = await axios.get(`${elasticScreenoURL}/screeno_api/getTotalImpressionCount`);

    const impressionCount = await axios.post(`${elasticScreenoURL}/screeno_api/getImpressionCountForUser`);

    // console.log("Count adv :", impressionCount);

    const advertiser = await axios.get(`${rootURL}/api/dashboardStatsAdvertiser`);

    // console.log("Advertiser :", advertiser.data);
    const { data } = advertiser;

    const payload = {
      totalImpressionCount : count.data.data.count,
      totalScreenCount: data.totalScreens,
      totalLocationCount: data.totalLocations,
      screenCount: 0,
      contentCount: data.contentCount,
      campaignCount: data.campaignCount,
      locationCount : 0,
      impressionCount : impressionCount.data.count.count > 0 ? impressionCount.data.count.count : "No Data"
    }

    // console.log("Paylaod : ", payload)

    dispatch({
      type : FETCH_ADVERTISER_DASHBOARD,
      payload
    });


  } catch (err) {
    console.log(err);
  }

}

export const fetchDashboardStats = () => async dispatch => {

  try {

    const count = await axios.get(`${elasticScreenoURL}/screeno_api/getTotalImpressionCount`);

    const impressionCount = await axios.post(`${elasticScreenoURL}/screeno_api/getImpressionCountForUser`);

    const response = await axios(`${rootURL}/api/fetchDashboardStats`);
    // console.log(response.data);

    dispatch({
      type : FETCH_DASHBOARD_STATS,
      payload : {
        totalImpressionCount : count.data.data.count,
        impressionCount : impressionCount.data.count.count,
        ...response.data
      }
    });

  } catch(err){
    console.log(err.response.data);
  }

}