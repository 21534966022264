import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Grid, TextField, Button } from "@material-ui/core";
import { fetchALLCreatives, removeCreative, updateDuration } from "../../Actions/creativeActions";
import MaterialTable, { MTableToolbar } from 'material-table';
import { makeStyles} from '@material-ui/core/styles';
import RemoveCreative from "./RemoveCreativeConfirm";
import CreativePreview from "./CreativePreview";
import CreativeUpload from "./CreativeUploadModal";
import ShareCreativeModal from "./ShareCreativeModal";
import StatusChip from "../Common/StatusChip";
import CreativeApproval from './CreativeApproval';
import UpdateDuration from './UpdateDuration';

const CreativeTable = ({fetchALLCreatives, creatives, updateDuration}) => {

    const useStyles = makeStyles((theme) => ({
        margin: {
          margin: theme.spacing(1),
        },
    }));

    const classes = useStyles();

    const ApprovalStatus = (rowData) => {

        let { approval_status } = rowData;

        if(approval_status === "Pending"){
            return <StatusChip bColor="yellow" label={approval_status} />

        } else if(approval_status === "Approved"){
            return <StatusChip bColor="green" label={approval_status} />

        } else if(approval_status === "Disapproved") {
            return <StatusChip bColor="red" label={approval_status} />
        }

    }


    useEffect(() => {
        fetchALLCreatives();
    }, []);

    // console.log("cretiv", history);

    return (
        <div style={{maxWidth : "100%"}}>

            <MaterialTable 
                title="Creatives"

                columns={[
                    {title :"Preview",  headerStyle : { fontWeight : "bold" }, render : rowData => {
                        return <CreativePreview data={rowData} />
                    }},
                    {title :"File Name", field : "original_name",  headerStyle : { fontWeight : "bold" }},
                    {title :"Type", field : "file_type",  headerStyle : { fontWeight : "bold" }},
                    {title :"Duration (sec)", field : "duration",  headerStyle : { fontWeight : "bold" }},
                    {title :"File Size",  headerStyle : { fontWeight : "bold" }, render : rowData => {
                        if(rowData.file_type.includes("html")){
                            return "-"
                        } else {

                            return `${parseInt(rowData.file_size/1024)} KB`;
                        }
                    }},
                    {title :"Uploaded on", field : "created_at", headerStyle : { fontWeight : "bold" }, type : "datetime"},
                    {title :"Approval",  headerStyle : { fontWeight : "bold" }, render : ApprovalStatus},
                    {title : "Edit Status", headerStyle :{ fontWeight : "bold" }, render : (rowData) => {
                        return <CreativeApproval data={rowData}/>
                    }},
                    {title : "Update Duration", headerStyle :{ fontWeight : "bold" }, render : (rowData) => {
                        
                        return <UpdateDuration data={rowData} updateDuration={updateDuration} />
                    }},
                    {title :"Remove", field : "",  headerStyle : { fontWeight : "bold" }, render: rowData => {
                        return <RemoveCreative data={rowData}/>
                    }},

                ]}

                data={[...creatives]}
                options={{
                    pageSize : 10,
                    pageSizeOptions : [10, 30, 50, 100]
                  }}
                components={{
                    Toolbar : props => (
                        <div>
                            <MTableToolbar {...props} />
                            <Grid container spacing={2}>
                                {/* <Grid container>
                                    <Grid item >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component={LinkRouter}
                                        to={`/addscreen`}
                                        className={classes.margin}
                                        startIcon={<Add />}
                                    >
                                        Add
                                    </Button>
                                    </Grid>
    
                                    <Grid item >
                                        <ScreenExcelUpload class={classes.margin}/>
                                    </Grid>
                                </Grid> */}

                                <Grid item md={2} sm={12}>    
                                    <CreativeUpload class={classes.margin}/>
                                    
                                </Grid>
                                <Grid item md={2} sm={12}>    
                                    <ShareCreativeModal />
                                </Grid>
                                

    
                            </Grid>
                        </div>
                    )
                }} 
            />
            
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        creatives : state.creatives
    }
}

export default connect(mapStateToProps, { fetchALLCreatives, removeCreative, updateDuration })(CreativeTable);
