import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Search, Public } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {
    fetchInventoryFilter, fetchFilterData, setStateFilterOptions, setCityFilterOptions, fetchInventory, getLocationsWithinRadius
} from "../../Actions/inventoryActions";
import MapSearch from "./SearchPointOnMapModal";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    margin: {
        margin: theme.spacing(1),
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


function InventoryFilter(props) {

    const classes = useStyles();
    const { fetchInventoryFilter , setStateFilterOptions, setCityFilterOptions, fetchFilterData, fetchInventory,
        getLocationsWithinRadius
    } = props;
    const {
        country, states, cities, network, category, defaultStates, defaultCities, locationStruct, network_name
    } = props.inventory;

    const [cords, setCords] = useState({
        lat: '',
        lng: '',
        distance: 0
    });

    const [open, setOpen] = React.useState(false);

    const [filter, setFilter] = useState({
        country: [],
        state: [],
        city: [],
        category: [],
        network: [],
        network_name: [],
        footfall: "",
        impressions: ""
    });

    // console.log("Category :", category);

    // For SnackBar
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    // For SnackBar
    const handleClickSnackBar = () => {
        setOpen(true);
    };

    useEffect(() => {
        fetchInventoryFilter();
    }, []);

    const onCountryChange = (e, value, reason) => {

        console.log("Country :", value);

        if (value.length === 0) {
            setFilter({
                ...filter,
                country: [...value],
                city: [],
                state: []
            });

            setStateFilterOptions(defaultStates);
            setCityFilterOptions(defaultCities);
        } else {

            // console.log("Struct :", locationStruct);

            let state = [];
            let city = [];

            setFilter({
                ...filter,
                country: [...value],
                city: [],
                state: []
            });

            value.forEach(country => {

                let states = [...Object.keys(locationStruct[country])];
                let cities = [];

                states.forEach(s => {
                    cities = [...locationStruct[country][s]];


                });

                state = [...state, ...states];
                city = [...city, ...cities]

            });

            setCityFilterOptions(city);
            setStateFilterOptions(state);

        }

    }


    const onStateChange = (e, value, reason) => {
        // console.log("event:", e);
        // console.log("value:", value);
        // console.log("reason:", reason);

        if (value.length === 0) {

            setFilter({
                ...filter,
                state: [...value],
                city: []
            });

            let state = [];
            let city = [];

            filter.country.forEach(country => {

                state = [...state, ...Object.keys(locationStruct[country])];

                state.forEach(s => {
                    city = [...city, ...locationStruct[country][s]];
                });

            });

            setCityFilterOptions(city);

        } else {

            setFilter({
                ...filter,
                state: [...value],
                city: []
            });

            let city = [];

            value.forEach(state => {

                Object.keys(locationStruct).forEach(country => {

                    if (Object.keys(locationStruct[country]).includes(state)) {
                        city = [...city, ...locationStruct[country][state]];
                    }
                });
            });

            setCityFilterOptions(city);
        }


    }

    const onCityChange = (e, value, reason) => {

        setFilter({
            ...filter,
            city: [...value]
        });

    }

    const onCategoryChange = (e, value, reason) => {

        setFilter({
            ...filter,
            category: [...value]
        });

    }

    const onNetworkChange = (e, value, reason) => {

        setFilter({
            ...filter,
            network: [...value]
        });

    }

    const onNetworkNameChange = (e, value, reason) => {

        setFilter({
            ...filter,
            network_name: [...value]
        });

    }

    const onFilterReset = () => {

        setFilter({
            country: [],
            state: [],
            city: [],
            category: [],
            network: [],
            network_name: [],
            footfall: "",
            impressions : ""
        });
        fetchInventory();
        setCityFilterOptions(defaultCities);
        setStateFilterOptions(defaultStates);
    }

    const onSumbit = (e) => {
        e.preventDefault();

        fetchFilterData(filter);
        // console.log("Submitted", stateCityList);

    }

    const onChangeGeo = (e) => {

        setCords({
            ...cords,
            [e.target.name]: e.target.value
        });
    }

    const clearGeoFilter = () => {
        setCords({
            lat: '',
            lng: '',
            distance: 0
        })
    }

    const onChangeGeneric = (e) => {
        // console.log(e.target.name ,e.target.value);
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });

    }

    const onSubmitGeo = (e) => {
        e.preventDefault();

        if (cords.lat === '' || cords.lng === '') {
            handleClickSnackBar();
        } else {

            // console.log(cords);
            getLocationsWithinRadius(cords);
        }
    }

    return (
        <div className={classes.root}>
            <Snackbar open={open} autoHideDuration={3500} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    Please Check the Value of Latitude and Longitude
                </Alert>
            </Snackbar>
            {/* <Grid container spacing={2}> */}
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Show filters</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {/* <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.
                        </Typography> */}
                    <form className={classes.root} onSubmit={onSumbit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    id="country"
                                    value={filter.country}
                                    options={country}
                                    multiple
                                    size="small"
                                    onChange={onCountryChange}
                                    getOptionLabel={(option) => option}
                                    // getOptionLabel={(option) => option.title}
                                    // style={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Country" variant="outlined" />}
                                    loading={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    id="state"
                                    value={filter.state}
                                    options={states}
                                    multiple
                                    size="small"
                                    onChange={onStateChange}
                                    getOptionLabel={(option) => option}
                                    // getOptionLabel={(option) => option.title}
                                    // style={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="State" variant="outlined" />}
                                    loading={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    id="city"
                                    value={filter.city}
                                    options={cities}
                                    multiple
                                    size="small"
                                    onChange={onCityChange}
                                    getOptionLabel={(option) => option}
                                    // getOptionLabel={(option) => option.title}
                                    // style={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="City" variant="outlined" />}
                                />
                            </Grid>
                            
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    id="network"
                                    value={filter.network}
                                    options={network}
                                    multiple
                                    size="small"
                                    onChange={onNetworkChange}
                                    getOptionLabel={(option) => option}
                                    // getOptionLabel={(option) => option.title}
                                    // style={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Network" variant="outlined" />}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    id="category"
                                    value={filter.category}
                                    options={category}
                                    multiple
                                    size="small"
                                    onChange={onCategoryChange}
                                    getOptionLabel={(option) => option}
                                    // getOptionLabel={(option) => option.title}
                                    // style={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Category" variant="outlined" />}
                                />
                            </Grid>
                            
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    id="network_name"
                                    value={filter.network_name}
                                    options={network_name}
                                    multiple
                                    size="small"
                                    onChange={onNetworkNameChange}
                                    getOptionLabel={(option) => option}
                                    // getOptionLabel={(option) => option.title}
                                    // style={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Publisher network" variant="outlined" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    value={filter.impressions}
                                    id="impressions"
                                    type="number"
                                    name="impressions"
                                    variant="outlined"
                                    label="Min Impressions"
                                    size="small"
                                    onChange={onChangeGeneric}
                                    fullWidth
                                />

                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    value={filter.footfall}
                                    id="footfall"
                                    type="number"
                                    name="footfall"
                                    variant="outlined"
                                    label="Min Footfall"
                                    size="small"
                                    onChange={onChangeGeneric}
                                    fullWidth
                                />

                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    className={classes.margin}
                                    startIcon={<Search />}
                                >
                                    Search
                                    </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={onFilterReset}
                                    className={classes.margin}

                                >
                                    Clear
                                    </Button>
                            </Grid>
                        </Grid>
                    </form>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            {/* Filter by Radius */}

            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Geo Search with in Radius</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>


                    <Grid container spacing={2}>

                        <Grid item md={2} sm={12}>
                            <MapSearch cords={cords} setCords={setCords} />
                        </Grid>

                        <Grid item md={10} sm={12}>
                            <form onSubmit={onSubmitGeo}>
                                <Grid container spacing={2}>
                                    <Grid item md={3} sm={6}>
                                        <TextField
                                            type="number"
                                            value={cords.lat}
                                            name="lat"
                                            label="Latitude"
                                            onChange={onChangeGeo}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={6}>
                                        <TextField
                                            type="number"
                                            value={cords.lng}
                                            name="lng"
                                            label="Longitude"
                                            onChange={onChangeGeo}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={6}>
                                        <TextField
                                            type="number"
                                            value={cords.distance}
                                            name="distance"
                                            label="Radius in KMs"
                                            onChange={onChangeGeo}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            startIcon={<Public />}
                                            className={classes.margin}
                                        >
                                            find
                                        </Button>

                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={clearGeoFilter}
                                            className={classes.margin}
                                        >
                                            clear
                                        </Button>

                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>

                </ExpansionPanelDetails>
            </ExpansionPanel>
            {/* </Grid> */}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        inventory: state.inventory
    }
}

export default connect(mapStateToProps, {
    fetchInventoryFilter, setStateFilterOptions, setCityFilterOptions, fetchFilterData, fetchInventory, getLocationsWithinRadius
})(InventoryFilter);


