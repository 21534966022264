import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import {  ThumbDown, ThumbUp, PlayCircleFilled } from "@material-ui/icons";
import { withStyles } from '@material-ui/core/styles';
import { green, purple } from "@material-ui/core/colors";
import Fade from '@material-ui/core/Fade';
// import { approveCreativeAdmin, disapproveCreativeAdmin } from "../../../Actions/AdminCreativeActions";
import { 
  approveScreenForCampaign, disapproveScreenForCampaign 
} from "../../../Actions/campaignActions";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));


const CustonButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

function EditScreenAprovalForCampaign(props) {

  // console.log("props :", props);
  const {data, approveScreenForCampaign, disapproveScreenForCampaign, } = props;

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Approve = (data) => {
    approveScreenForCampaign(data);
    // console.log("Confirm Clicked!", data);
    handleClose();
  }

  const Disapprove = (data) => {
    
    disapproveScreenForCampaign(data);
    // console.log("Confirm Clicked!",data);
    handleClose();
  }

  const showButtons = (data) => {

    if(data.isApproved === "Approved"){

      return <div>
        <Button
              variant="contained"
              className={classes.margin}
              color="secondary"
              onClick={() => Disapprove(data)}
              startIcon={<ThumbDown />}
        >
          Disapprove
        </Button>
      </div>

    } else if(data.isApproved === "Disapproved"){

      return <div>
        <Button
              variant="contained"
              color="primary"
              className={classes.margin}
              onClick={() => Approve(data)}
              startIcon={<ThumbUp />}
        >
          Approve
        </Button>
      </div>

    } else {
      return (
        <div>
          <Button
              variant="contained"
              color="primary"
              className={classes.margin}
              onClick={() => Approve(data)}
              startIcon={<ThumbUp />}
          >
              Approve
          </Button>

          <Button
              variant="contained"
              className={classes.margin}
              color="secondary"
              onClick={() => Disapprove(data)}
              startIcon={<ThumbDown />}
          >
              Disapprove
          </Button>
        </div>
      )
    }

  }

  const modalButton = () => {
    if(data.isApproved === "Approved"){
      return (
        <CustonButton 
          type="button" 
          onClick={handleOpen} 
          variant="contained" 
          color="primary" 
          disabled={data.isCpmApprovedByAgency && data.isCpmApprovedByPublisher ? false : true}
        >
          <PlayCircleFilled/>
        </CustonButton>
      )
    } else if(data.isApproved === "Pending") {
      return(
        <Button

        type="button" 
        onClick={handleOpen} 
        variant="contained" 
        color="primary" 
        disabled={data.isCpmApprovedByAgency && data.isCpmApprovedByPublisher ? false : true}
      
        >
          <PlayCircleFilled/>
        </Button>
      )
    } else if(data.isApproved === "Disapproved"){
      return(
        <Button

        type="button" 
        onClick={handleOpen} 
        variant="contained" 
        color="secondary" 
        disabled={data.isCpmApprovedByAgency && data.isCpmApprovedByPublisher ? false : true}
      
        >
          <PlayCircleFilled/>
        </Button>
      )

    }
  }

  return (
    <div>
      {modalButton()}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Update approval status</h2>
            {showButtons(data)}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default connect(null,{approveScreenForCampaign, disapproveScreenForCampaign})(EditScreenAprovalForCampaign);