import React from 'react';
import {
    Grid
} from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// import DateFnsUtils from '@date-io/date-fns';
// import { isAfter, isBefore } from "date-fns";
// import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import InputField from "./FormFields/InputField";
import SelectField from "./FormFields/SelectField";
import DateField from "./FormFields/DateField";

function CampainDetails(props) {

    return (
        <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
                
                <InputField 
                    type="text"
                    name="campaign_name"
                    label="Campaign Name"
                    fullWidth
                />
            </Grid>
            <Grid item sm={12} md={6}>
                
                <SelectField 
                    name="campaign_type"
                    label="Campaign type"
                    data={[
                        { value : "DOOH", title : "DOOH" },
                        { value : "MOBILE", title : "MOBILE" },
                        { value : "WEB", title : "WEB" },
                    ]}
                    // data={["DOOH", "MOBILE", "WEB"]}
                    title="title"
                    // valueField="value"
                    // multiple
                    fullWidth
                />
            </Grid>
            <Grid item sm={12} md={6}>
                

                <DateField 
                    name="start_date"
                    fullWidth
                    label="Start Date"
                />

            </Grid>
            <Grid item sm={12} md={6}>
               
                <DateField 
                    name="end_date"
                    fullWidth
                    label="End Date"
                />
            </Grid>
            
            <Grid item sm={12} md={6}>
              
                <InputField 
                    type="number"
                    name="campaign_budget"
                    label="Campaign Budget"
                    fullWidth
                />
            </Grid>
            <Grid item sm={12} md={6}> </Grid>
        </Grid>
    )
}

export default CampainDetails;
