import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { Delete } from "@material-ui/icons";
// import { createMuiTheme, withStyles, ThemeProvider } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import { removeDOOHCampaign } from "../../Actions/campaignActions";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

function DOOHCampaignRemoveModal({data, removeDOOHCampaign}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmAction = () => {
    // removeCreative(data.original_name);
    // console.log("Confirm Clicked!");
    removeDOOHCampaign(data._id);
    handleClose();
  }

  return (
    <div>
      <Button type="button" onClick={handleOpen} variant="contained" color="primary">
        <Delete/>
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Are you sure you want to remove this ?</h2>
            <div>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.margin}
                    onClick={confirmAction}
                >
                    Confirm
                </Button>

                <Button
                    variant="contained"
                    className={classes.margin}
                    onClick={handleClose}
                >
                    Cancel
                </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default connect(null,{removeDOOHCampaign})(DOOHCampaignRemoveModal);