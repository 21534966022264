import React from 'react';
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import Card from "./Card";

function ClientCounts({dashboard}) {

  const { totalImpressionCount, sharedReportCount, sharedCampaignCount, sharedCreativeCount } = dashboard;

  const numFormater = (value) => {

    if(!Number.isInteger(value)){
      return value;
    }
   
   
    // if(value > 999 && value < 1000000){

    //   return `${(value/1000).toFixed(2)}K+`;
    // } else if(value > 1000000 && value < 1000000000){

    //   return `${(value/1000000).toFixed(2)}M+`;
    // } else if(value > 1000000000){

    //   return `${(value/1000000000).toFixed(2)}B+`;
    // } else if(value < 1000) {
      
    //   return value;
    // }

    return value;
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <Card text="Total Impression delivered by Platform" value={numFormater(totalImpressionCount)} item="impression count" />
        </Grid>
        {/* <Grid item md={3}>
          <Card text="Shared campaigns" value={sharedCampaignCount} item="campaign"/>
        </Grid> */}
        <Grid item md={3}>
          <Card text="Shared content" value={sharedCreativeCount} item="content"/>
        </Grid>        
        <Grid item md={3}>
          <Card text="Shared reports" value={sharedReportCount} item="report"/>
        </Grid>
        <Grid item md={3}>
          <Card text="Shared Campaigns" value={sharedCampaignCount} item="campaign"/>
        </Grid>
      </Grid>     
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    dashboard : state.dashboard
  }
}
export default connect(mapStateToProps, {})(ClientCounts);
