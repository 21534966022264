import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Grid, Select } from "@material-ui/core";
import { fetchApprovedCreatives } from "../../../Actions/campaignActions";
import SelectField from "./FormFields/SelectField";

function CompaignCreatives(props) {
  const { fetchApprovedCreatives, approvedCreatives } = props;

  useEffect(() => {
    fetchApprovedCreatives();
  }, []);


  return (
    <Grid container spacing={2}>
      <Grid item md={12} sm={12}>
        {/* <Autocomplete
          id="ApprovedCreatives"
          value={creatives}
          options={approvedCreatives}
          multiple
          // size="small"
          onChange={onCreativeChange}
          getOptionLabel={(option) => option.title}
          // style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Select Approved Creatives" variant="outlined" />}
          loading={true}
        /> */}

        <SelectField 
          name="creatives"
          data={approvedCreatives}
          label="Select Approved creatives"
          title="original_name"
          multiple
          fullWidth          
        />

      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    approvedCreatives: state.campaign.approvedCreatives
  }
}

export default connect(mapStateToProps, { fetchApprovedCreatives })(CompaignCreatives);