import React from 'react';
import {
 List ,ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, FormControl, Button, FormHelperText
} from "@material-ui/core";
import { Delete, PlayCircleFilledOutlined, Link, Image } from "@material-ui/icons";
import { FixedSizeList } from "react-window";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },

  mapList : {

    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 500,

  },

  margin : {
    margin: theme.spacing(1)
  },

  formContent : {
    margin : theme.spacing(4),
    padding : theme.spacing(4)
  },

  formControlWidth : {
    width : "100%"
  }

  
}));

function SelectedCreatives(props) {

  const { values, errors, touched,  setFieldValue, removeCreative } = props;
  const { creatives } = values;

  const classes = useStyles();

  const getListIcon = (creative) => {

    if(creative.file_type.includes("image")){

      return <Image />;

    } else if(creative.file_type.includes("video")){

      return <PlayCircleFilledOutlined />;

    } else if(creative.file_type.includes("html")){

      return <Link/>

    }
  }

  // console.log("Errors:", errors);
  // console.log("touched:", touched);
  // console.log("values:", values.creatives);
  
  const rowRender = (props) => {

    const { index, style } = props;

    return (
      <div style={style} key={index}>
        <ListItem>
          <ListItemIcon>
            {getListIcon(creatives[index])}
          </ListItemIcon>
          <ListItemText primary={creatives[index].original_name}/>
          <ListItemSecondaryAction>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => removeCreative(creatives[index], index, values, setFieldValue)}
            >
              <Delete />
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    )

  }

  const returnHelperTextCreatives = (errors, touched) => {
    let isError = errors.creatives && touched.creatives;

    // console.log("MyError is :", errors);

    if(isError){
      return <FormHelperText>{errors.creatives}</FormHelperText>
    }
  }

  return (
    <div>
      <FormControl component="fieldset" error={!!errors.creatives && touched.creatives} className={classes.formControlWidth}>
        <List dense={true}>
          {/* {selectedCreatives(values, setFieldValue)} */}
          { returnHelperTextCreatives(errors, touched) }
          <FixedSizeList height={350} width={400} itemSize={50} itemCount={values.creatives.length}>
            {rowRender}
          </FixedSizeList>
        </List>
        {/* { returnHelperTextCreatives(errors, touched) } */}
      </FormControl>
    </div>
  )
}

export default SelectedCreatives;
