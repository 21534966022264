import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom';
import { connect } from "react-redux";
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { MenuItem, InputLabel, FormControl, Select } from "@material-ui/core";
import { register } from "../../Actions/authActions";
import Alert from "../Common/Alert";


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://locad.net/">
                Screeno
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}))

const SignUp = ({ history, register }) => {
    
    const classes = useStyles()
    // Form Data 

    const [formData, setFormData] = useState({
        name: "",
        company: "",
        email: "",
        password: "",
        password2: "",
        role: ""
    });


    const { name, company, email, password, password2, role } = formData;

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // console.log(e.target.name);
    }


    const onSubmit = (e) => {
        e.preventDefault();
        if (password === password2) {
            console.log("Password doesn't match");

            register(formData, () => {
                setFormData({
                    name: "",
                    company: "",
                    email: "",
                    password: "",
                    password2: "",
                    role: ""
                });

                history.push("/login");
            })

        } else {
            console.log(formData);
        }
    }


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
        </Typography>
                <form className={classes.form} noValidate onSubmit={onSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="fname"
                                name="name"
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                autoFocus
                                onChange={onChange}
                                value={name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="company_name"
                                label="Company Name"
                                name="company"
                                autoComplete="CompanyName"
                                onChange={onChange}
                                value={company}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                // error={isSignupError}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                // helperText={signupError}
                                // onChange={(e) => handleOnChange(e.target.value, e.target.name)}
                                onChange={onChange}
                                value={email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Enter Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={onChange}
                                value={password}
                            // onChange={(e) => handleOnChange(e.target.value, e.target.name)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password2"
                                label="Confirm Password"
                                type="password"
                                id="password2"
                                autoComplete="confirm-password"
                                onChange={onChange}
                                value={password2}
                            // onChange={(e) => handleOnChange(e.target.value, e.target.name)}
                            />
                        </Grid>
                        <FormControl variant="outlined" fullWidth className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="Role"
                                name="role"
                                value={role}
                                onChange={onChange}
                                fullWidth
                                placeholder="role"
                                variant="outlined"
                            >
                                <MenuItem value=""><em>None</em></MenuItem>
                                <MenuItem value="Admin">Admin</MenuItem>
                                <MenuItem value="Media Owner">Media Owner</MenuItem>
                                <MenuItem value="Advertiser">Advertiser</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        type="submit"
                    // onClick={() => dispatch(actions.signUp(values))}
                    >
                        Register
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link component={RouterLink} to="/login" variant="body2" href="#" className={classes.link}>
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form>
                <Alert />
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    )
}

export default connect(null, { register })(SignUp);