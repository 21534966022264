import {
  CREATIVE_IMAGE_ERROR, CREATIVE_VIDEO_ERROR, CREATIVE_WEB_ERROR, CLEAR_CREATIVE_IMAGE_ERROR, CLEAR_CREATIVE_VIDEO_ERROR,
  CLEAR_CREATIVE_WEB_ERROR
} from "../Actions/types";

const initState = {
  imageError : "",
  videoError : "",
  webError : ""
};

export default (state=initState, action) => {

  const { type, payload } = action;

  switch(type){
    
    case CREATIVE_IMAGE_ERROR:
      return {
        ...state, imageError : payload
      }

    case CREATIVE_VIDEO_ERROR:
      return {
        ...state, videoError : payload
      }
    
    case CREATIVE_WEB_ERROR:
      return {
        ...state, webError : payload
      }
    
    case CLEAR_CREATIVE_IMAGE_ERROR:
      return {
        ...state, imageError : ""
      }

    case CLEAR_CREATIVE_VIDEO_ERROR:
      return {
        ...state, videoError : ""
      }

    case CLEAR_CREATIVE_WEB_ERROR:
      return {
        ...state, webError : ""
      }
    
    default:
      return state;
  }
}