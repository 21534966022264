import React, { Component } from 'react';
import _ from "lodash";
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import SearchBox from "react-google-maps/lib/components/places/SearchBox"


const refs = {};
const google = window.google;

const MapComponent = withGoogleMap((props) =>
    <GoogleMap
        ref={props.onMapMounted}
        defaultZoom={12 }
        // defaultCenter={{ lat: -34.397, lng: 150.644 }}
        center={props.center}
        onBoundsChanged={props.onBoundsChanged}
    >
        <SearchBox
            ref={props.onSearchBoxMounted}
            bounds={props.bounds}
            controlPosition={window.google.maps.ControlPosition.TOP_RIGHT}
            onPlacesChanged={props.onPlacesChanged}
        >
            <input
                type="text"
                placeholder="Search Location"
                style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `240px`,
                    height: `32px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                }}
            />
        </SearchBox>
        <Marker position={props.center}/>

    </GoogleMap>
)

class TestMap extends Component {

    state = {
        bounds: null,
        center: this.props.center ||  {lat: 28.5433158, lng: 77.2127729 },
        markers: []
    }

    onMapMounted = ref => {
        refs.map = ref;
    }

    onBoundsChanged = () => {
        this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
        })
    }

    onSearchBoxMounted = ref => {
        refs.searchBox = ref;
    }

    onPlacesChanged = () => {
        const places = refs.searchBox.getPlaces();
        const bounds = new window.google.maps.LatLngBounds();

        places.forEach(place => {
            if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport)
            } else {
                bounds.extend(place.geometry.location)
            }

            
            const point = {
                location : place.formatted_address,
                lat : place.geometry.location.lat(),
                lng : place.geometry.location.lng()
            }

            // console.log("Point :", point);

            this.props.setPoint(point);



        });
        const nextMarkers = places.map(place => ({
            position: place.geometry.location,
        }));

        const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

        this.setState({
            center: nextCenter,
            markers: nextMarkers,
        });

        // refs.map.fitBounds(bounds);
    }


    componentDidMount(){
        const { lat, lng } = this.props.point;
        if(lat !== '' && lng !== ''){
            this.setState({
                ...this.state,
                center : {
                    lat,
                    lng
                }
            })
        }
        
    }

    render() {
        return (
            <div>
                <MapComponent
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    onSearchBoxMounted={this.onSearchBoxMounted}
                    onPlacesChanged={this.onPlacesChanged}
                    onMapMounted={this.onMapMounted}
                    onBoundsChanged={this.onBoundsChanged}
                    bounds={this.state.bounds}
                    markers={this.state.markers}
                    center={this.state.center}

                />
            </div>
        )
    }
}

export default TestMap;

