import axios from "axios";
import {
    FETCH_ALL_CREATIVES, REMOVE_CREATIVE, UPLOAD_PROGRESS_STATUS, CREATIVE_UPLOAD_SUCCESS, APPROVE_CREATIVE_ADMIN,
    DISAPPROVE_CREATIVE_ADMIN, UPDATE_DURATION
} from "./types";
import { setAlert } from "./alertActions";
import { rootURL } from "../Utils/config";


export const fetchALLCreatives = () => async dispatch => {
    try {

        const response = await axios.get(`${rootURL}/api/getAllFiles`);
        // console.log(response.data);

        dispatch({
            type: FETCH_ALL_CREATIVES,
            payload: response.data
        });

    } catch (err) {
        console.log(err);
    }
}


export const removeCreative = (name) => async dispatch => {

    try {
        const result = await axios.post(`${rootURL}/api/removeFile`, { name });

        dispatch({
            type: REMOVE_CREATIVE,
            payload: name
        });

    } catch (err) {
        console.log(err);
    }

}

export const updateDuration = (data, cb) => async dispatch => {

    try{

        const response = await axios.post(`${rootURL}/api/updateDuration`, {data});

        dispatch({
            type : UPDATE_DURATION,
            payload : response.data
        });

        cb();

    } catch(err) {
        console.log(err);
    }

}

export const creativeUpload = (fileData) => async dispatch => {

    const { fileName, type, duration, size, file } = fileData;


    if (type !== "html") {

        try {

            const response = await axios.post(`${rootURL}/api/generateSignedUrlForUpload`, {
                file_name: fileName,
                file_type: type
            });

            const xhr = new XMLHttpRequest();

            xhr.open('PUT', response.data.data);

            xhr.upload.onprogress = e => {
                let d = Math.round((e.loaded * 100) / e.total);
                dispatch({
                    type: UPLOAD_PROGRESS_STATUS,
                    payload: d
                })
            }

            xhr.onreadystatechange = async () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        console.log("file upload done");
                        dispatch({
                            type: UPLOAD_PROGRESS_STATUS,
                            payload: 0
                        });

                        try {

                            const res = await axios.post(`${rootURL}/api/saveFile`, {
                                url: response.data.url,
                                file_name: fileName,
                                duration,
                                file_size: size,
                                file_type: type
                            });

                            // console.log("REs", res.data);
                            // cb();

                            dispatch({
                                type: CREATIVE_UPLOAD_SUCCESS,
                                payload: res.data.data
                            })

                        } catch (err) {
                            // console.log(Object.keys(err));
                            // console.log("myerror", err.response);
                            // console.log("Type :", type);

                            dispatch(setAlert(err.response.data.error, "error"));

                        }


                    }
                }
            }

            xhr.send(file);

        } catch (err) {
            // console.log(err.response.data);

            dispatch(setAlert(err.response.data.error, "error"));

        }
    } else {

        try {
            const res = await axios.post(`${rootURL}/api/saveFile`, {
                url: fileData.url,
                file_name: fileName,
                duration,
                file_size: size,
                file_type: type
            });

            dispatch({
                type: CREATIVE_UPLOAD_SUCCESS,
                payload: res.data.data
            })

            // cb(); 
        } catch (err) {
            // console.log(err.response.data.error);

            dispatch(setAlert(err.response.data.error, "error"));
        }

    }


}


export const uploadProgress = (status) => dispatch => {

    dispatch({
        type: UPLOAD_PROGRESS_STATUS,
        payload: status
    })
}

export const addSharedCreatives = (data, cb) => async dispatch => {

    console.log(data);

    try {

        const response = await axios.post(`${rootURL}/api/addSharedCreatives`, { ...data });
        console.log("response :", response.data);

        cb();

    } catch(err){
        console.log("Error:", err);
    }
}

export const approveCreativeAdmin = (data) => async dispatch => {
    try {
  
      const response = await axios.post(`${rootURL}/api/approveContent`,{data});
      // console.log(response.data.result);
  
      dispatch({
        type : APPROVE_CREATIVE_ADMIN,
        payload : response.data.result
      });
  
    } catch(err){
      console.log(err);
    }
  }
  
  export const disapproveCreativeAdmin = (data) => async dispatch =>{
    try {
  
      const response = await axios.post(`${rootURL}/api/disapproveContent`,{data});
      // console.log(response.data.result);
  
      dispatch({
        type : DISAPPROVE_CREATIVE_ADMIN,
        payload : response.data.result
      })
  
    } catch(err){
      console.log(err);
    }
  }