import {
  FETCH_ALL_LOCATION_LIST, ADD_LOCATION_LIST, REMOVE_LOCATION_LIST, FETCH_APPROVED_CREATIVES, FETCH_DOOH_CAMPAIGNS,
  REMOVE_DOOH_CAMPAIGN, UPDATE_DOOH_CAMPAIGN, FETCH_SCREENS_TO_EDIT_CAMPAIGN_SCREENS, FETCH_SHARED_CAMPAIGNS,
  FETCH_CAMPAIGN_FOR_APPROVAL, CHANGE_CAMPAIGN_APPROVAL, SET_COMBINE_CAMPAIGN_STATS, SET_PER_CAMPAIGN_STATS
} from "../Actions/types";

const initialState = {
  locationList : [],
  approvedCreatives :[],
  doohCampaigns : [],
  screensForDOOHScreenEdit: [],
  sharedCampaigns: [],
  approvalCampaigns : [],
  combineCampaignStats : {
    total_impressions_delivered : null,
    total_budget : null,
    total_budget_spend: null,
    total_ots : null
  },

  perCampaignStats : {
    impressions_delivered : null,
    budget : null,
    budget_spend: null,
    ots : null
  }
}


export default (state=initialState, action) => {

  const { type, payload } = action;

  switch(type){

    case FETCH_ALL_LOCATION_LIST:
      return {
        ...state,
        locationList : [...payload]
      }

    case ADD_LOCATION_LIST:
      return {
        ...state,
        locationList : [...state.locationList, payload]
      }

    case REMOVE_LOCATION_LIST:
      return {
        ...state,
        locationList : [...state.locationList.filter(s => s._id !== payload)]
      }
    
    case FETCH_APPROVED_CREATIVES:
      return {
        ...state,
        approvedCreatives : [...payload]
      }

    case FETCH_DOOH_CAMPAIGNS:
      return {
        ...state,
        doohCampaigns : [...payload]
      }

    case REMOVE_DOOH_CAMPAIGN:
      return {
        ...state,
        doohCampaigns : [...state.doohCampaigns.filter(d => d._id !== payload)]
      }

    case UPDATE_DOOH_CAMPAIGN:
      return {
        ...state,
        doohCampaigns : [...state.doohCampaigns.map(d => d._id === payload._id ? payload : d)]
      }

    case FETCH_SCREENS_TO_EDIT_CAMPAIGN_SCREENS:
      return {
        ...state,
        screensForDOOHScreenEdit: [...payload]
      }
    
    case FETCH_SHARED_CAMPAIGNS : 
      return {
        ...state,
        sharedCampaigns : [...payload]
      }

    case FETCH_CAMPAIGN_FOR_APPROVAL: 
      return {
        ...state,
        approvalCampaigns : [...payload]
      }

    case CHANGE_CAMPAIGN_APPROVAL : 
      return {
        ...state,
        approvalCampaigns : [...state.approvalCampaigns.map(a => a._id === payload._id ? payload : a)]
      }
    
    case SET_COMBINE_CAMPAIGN_STATS:
      return {
        ...state,
        combineCampaignStats : action.payload
      }

    case SET_PER_CAMPAIGN_STATS :
      return {
        ...state,
        perCampaignStats : action.payload
      }
    default:
      return state;
  }


}