import React from 'react';
import {
  Grid
} from "@material-ui/core";
import "react-week-calendar/dist/style.css";
import ScheduleField from "./FormFields/ScheduleField";



function CampaignSchedule(props) {

  return (
    <div>
      <Grid container spacing={2}>

       
        <ScheduleField name="schedule_type" label="Schedule" scheduleFieldName="schedule"/>
      </Grid>

    </div>
  )
}

export default CampaignSchedule;
