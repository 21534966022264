import React from 'react';
import { Chip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles"

function StatusChip(props) {

  const StyledChip = withStyles({
    root: {
      backgroundColor : props.bColor,
      fontWeight : "bold"
    }
  })(Chip);

  return (
    <StyledChip color="primary" label={props.label} />
  )
}

export default StatusChip;
