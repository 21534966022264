import {
    FETCH_ALL_SCREENS,
    ADD_SCREEN_TO_INVENTORY,
    REMOVE_SCREEN_FROM_INVENTORY,
    ADD_SCREEN,
    UPDATE_SCREEN,
    REMOVE_SCREEN,
    UNPAIR_SCREEN
} from "../Actions/types";

const initialState = [];

export default (state=initialState, action) => {

    const { type, payload } = action;

    // if(type === FETCH_ALL_SCREENS){
    //     console.log(FETCH_ALL_SCREENS)
    // }

    switch(type){

        case FETCH_ALL_SCREENS:
            return payload;
        case ADD_SCREEN:
            return [
                ...state, payload
            ]
        case UPDATE_SCREEN:
            return [
                ...state.map(s => s.screen_name === payload.screen_name ? payload : s)
            ]
        case REMOVE_SCREEN:
            return [
                ...state.filter(s => s.screen_name !== payload)
            ]
        case ADD_SCREEN_TO_INVENTORY:
            return [
                ...state.map(s => payload === s.screen_name ? {...s, inventory : true} : s)
            ]
        case REMOVE_SCREEN_FROM_INVENTORY:
            return [
                ...state.map(s => payload === s.screen_name ? {...s, inventory : false} : s)
            ]
        case UNPAIR_SCREEN :
            return [
                ...state.map(s => s._id === payload._id ? payload : s)
            ]
        default:
            return state;
    }
}