import React, { useEffect } from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from "@material-ui/core";
import { PersonAdd, Edit } from "@material-ui/icons";
import { Link as LinkRouter } from "react-router-dom";
import { getSubUsers } from "../../../Actions/profileActions";
import RemoveClient from "../../User/RemoveClientModal";
import { roleFormatter } from "../../../Utils/config";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(2),
  },
}));


const ClientTable = (props) => {
  const { getSubUsers, subUsers } = props;

  const classes = useStyles();

  useEffect(() => {
    getSubUsers();
  }, []);

  const returnPublishers = () => {
    let publishers = subUsers.filter( s => s.role === "Media Owner");
    return publishers;
  }

  return (
    <div>
      <MaterialTable

        title="Clients"
        columns={[
          { title : "Name", field : "name", headerStyle: { fontWeight: "bold" }},
          { title : "Company Name", field : "company_name", headerStyle: { fontWeight: "bold" }},
          { title : "E-mail", field : "email", headerStyle: { fontWeight: "bold" }},
          { title : "Role", field : "role", headerStyle: { fontWeight: "bold" }, render : row => roleFormatter(row.role)},
          { title : "Remove", headerStyle: { fontWeight: "bold" }, render : rowData => {
            return <RemoveClient data={rowData} />
          }}

        ]}

        data={[...subUsers]}
        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar  {...props} />

              <div>
                <Grid container>
                  <Grid item>
                    {/* <AddClient publishers={returnPublishers()} /> */}

                    <Button
                      startIcon={<PersonAdd />}
                      className={classes.margin}
                      color="primary"
                      variant="contained"
                      component={LinkRouter}
                      to={{
                        pathname : "/clients/addAgencyClients",
                        state : {
                          publishers : returnPublishers()
                        }
                      }}
                    >
                      Add user
                    </Button>

                    <Button
                      startIcon={<Edit />}
                      className={classes.margin}
                      color="primary"
                      variant="contained"
                      component={LinkRouter}
                      to={{
                        pathname : "/clients/updateAgencyMarkup",
                        state : {
                          publishers : returnPublishers()
                        }
                      }}
                    >
                      Update Markup
                    </Button>

                  </Grid>
                </Grid>
              </div>
            </div>
          )
        }}

      />
      
    </div>
  )
}

const mapStateToProps = (state) => {

  // console.log("Users :", state.profile);
  return {
    subUsers : state.profile.subUsers
  };
}

export default connect(mapStateToProps, { getSubUsers })(ClientTable)