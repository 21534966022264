import {
    FETCH_INVENTORY_SCREENS,
    ADD_TO_INVENTORY_CART,
    CLEAR_INVENTORY_CART,
    FETCH_INVENTORY_FILTER_OPTIONS,
    INVENTORY_TABLE_LOADING,
    SET_CITY_FILTER_OPTIONS,
    SET_STATE_FILTER_OPTIONS,
    SET_LOCATION_STRUCTURE_LIST,
    FETCH_FILTER_DATA,
    FETCH_LOCATIONS_IN_RADIUS

} from "../Actions/types";

const initialState = {
    inventoryScreens : [],
    country: [],
    states : [],
    cities : [],
    defaultStates : [],
    defaultCities: [],
    locationStruct : [],
    network:[],
    network_name:[],
    category: [],
    cart : [],
    locationsInRadius :[],
    tableLoading : true
}


export default (state=initialState, action) => {

    const { type, payload } = action;

    // console.log("type", type);

    switch(type){
        case FETCH_INVENTORY_SCREENS:
            return {
                ...state,
                inventoryScreens : [...payload.data]
            }
        case SET_LOCATION_STRUCTURE_LIST:
            return {
                ...state,
                locationStruct : {...payload.locationStruct}
            }
        case INVENTORY_TABLE_LOADING:{
            return {
                ...state,
                tableLoading : payload
            }
        }
        case ADD_TO_INVENTORY_CART:
            return {
                ...state,
                cart : [...payload]
            }
        case CLEAR_INVENTORY_CART:
            return {
                ...state,
                cart : []
            }
        case FETCH_INVENTORY_FILTER_OPTIONS:
            return {
                ...state,
                country : [...payload.country],
                states : [...payload.states],
                cities : [...payload.cities],
                defaultStates: [...payload.states],
                defaultCities : [...payload.cities],
                network : [...payload.network],
                network_name : [...payload.network_name],
                category : [...payload.category]
            }
        case SET_CITY_FILTER_OPTIONS :{
            return {
                ...state,
                cities: [...payload]
            }
        }

        case SET_STATE_FILTER_OPTIONS : {
            return {
                ...state,
                states : [...payload]
            }
        }
        case FETCH_FILTER_DATA : 
            return {
                ...state,
                inventoryScreens : [...payload]
            }
        case FETCH_LOCATIONS_IN_RADIUS:
            return {
                ...state,
                inventoryScreens : [...payload]
            }
        default:
            return state;
    }
}