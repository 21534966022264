import React, { useEffect}  from 'react';
import { connect } from "react-redux";
import {} from "@material-ui/icons";

import PublisherCount from "./PublisherCounts";
import AdvertiserCount from "./AdvertiserCounts";
import AgencyCount from "./AgencyCounts";
import ClientCount from "./ClientCounts";
// import Ability from "../../Config/ability";

import { fetchDashboardStats  } from "../../Actions/dashboardActions";

const Dashboard = ({role, fetchDashboardStats}) => {

    useEffect(() => {
        fetchDashboardStats();
    }, [])

    const showDashboard = () => {

        if(role === "Media Owner"){
            return <PublisherCount />;
        } else if(role === "Advertiser"){
            return <AdvertiserCount />
        } else if(role === "AgencyPartnerAdmin"){
            return <AgencyCount />
        } else if(role === "Client"){
            return <ClientCount />
        } else {
            return <div>Navigate Using SideBar</div>
        }
    }

    return (
        <div>
            {showDashboard()}

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        dashboard : state.dashboard,
        role : state.auth.user.role
    }
}


export default connect(mapStateToProps,{fetchDashboardStats})(Dashboard);
