import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button } from '@material-ui/core';
import { Save, Close } from "@material-ui/icons";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import MapSearch from "./SearchInMap";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "600px"
  },
  margin: {
    margin: theme.spacing(1),
  }
}));

function MapModal({setCords, cords, center}) {
  
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [point, setPoint] = useState({
    lat : '',
    lng : '',
    location : ''
  });

  // useEffect(() => {
  //   // console.log("Point changed", point);
  //   // setCords({
  //   //   ...cords,
  //   //   ...point
  //   // });
  // }, [point])
  

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSave = () => {

    setCords({
      ...cords,
      ...point
    });

    handleClose();
  }

  const onCancel = () => {
    handleClose();
  }

  return (
    <div>
      <Button type="button" onClick={handleOpen} variant="contained" color="primary">
        Screen on map
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <MapSearch point={point} setPoint={setPoint} center={center}/>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={classes.margin}
                startIcon={<Save/>}
                onClick={onSave}
              >
                Save
              </Button>

              <Button
                variant="contained"
                color="secondary"
                className={classes.margin}
                startIcon={<Close/>}
                onClick={onCancel}
              >
                close
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default MapModal;
