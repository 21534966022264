import React, {Component} from 'react';
import _ from "lodash";
import { connect } from "react-redux";
import { GoogleMap, Marker, withGoogleMap  } from "react-google-maps";
import  DrawingManager   from "react-google-maps/lib/components/drawing/DrawingManager"
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import CustomMarker from "./CustomMarker";


const refs = {};
const google = window.google;

const icon = "https://screenodata.s3.ap-southeast-1.amazonaws.com/icons/marker_c.png";


const MapComponent = withGoogleMap((props) => {

  // console.log("Map Props :", google);

  return  <GoogleMap
              ref={props.onMapMounted}
              defaultZoom={10}
              defaultCenter={{ lat: -34.397, lng: 150.644 }}
              ref={props.onMapMounted}
              defaultCenter={props.point}
              // onBoundsChanged={props.onBoundsChanged}
          >

            <MarkerClusterer
              onClick={props.onMarkerClustererClick}
              averageCenter
              enableRetinaIcons
              gridSize={60}
            >

              {  props.markers.map(marker => {
                // console.log("Marker :", JSON.parse(marker.latlng));
                const {lng, lat} = JSON.parse(marker.latlng);


                return <CustomMarker 
                  key={marker.screen_name}
                  marker={marker}
                  position={{lat, lng }}
                  icon={icon}
                  setFieldValue={props.setFieldValue}
                  values={props.values}
                />
              })}
              </MarkerClusterer>
              
              

              <DrawingManager
                  // defaultDrawingMode={window.google.maps.drawing.OverlayType.CIRCLE}
                  onPolygonComplete={props.onPolygonComplete}
                  defaultOptions={{
                    drawingControl: true,
                    drawingControlOptions: {
                      position: google.maps.ControlPosition.TOP_CENTER,
                      drawingModes: [
                        // google.maps.drawing.OverlayType.CIRCLE,
                        google.maps.drawing.OverlayType.POLYGON,
                        // google.maps.drawing.OverlayType.POLYLINE,
                        // google.maps.drawing.OverlayType.RECTANGLE,
                      ],
                    },
                    circleOptions: {
                      fillColor: `#ffff00`,
                      fillOpacity: 1,
                      strokeWeight: 5,
                      clickable: false,
                      editable: true,
                      zIndex: 1,
                    },
                  }}
                />

        </GoogleMap>
}
)


class GoogleMapLocations extends Component {

  state = {
    bounds: null,
    center: this.props.center ||  {lat: 28.5433158, lng: 77.2127729 },
    markers: [],
    loading : true
  }

  onMapMounted = ref => {

    console.log("value", ref)
    // refs.map = ref;
  }

  onBoundsChanged = () => {
    this.setState({
        bounds: refs.map.getBounds(),
        center: refs.map.getCenter(),
    })
  }

  onSearchBoxMounted = ref => {
    refs.searchBox = ref;
  }

  onPlacesChanged = () => {
    const places = refs.searchBox.getPlaces();
    const bounds = new window.google.maps.LatLngBounds();

    places.forEach(place => {
        if (place.geometry.viewport) {
            bounds.union(place.geometry.viewport)
        } else {
            bounds.extend(place.geometry.location)
        }

        
        const point = {
            location : place.formatted_address,
            lat : place.geometry.location.lat(),
            lng : place.geometry.location.lng()
        }

        // console.log("Point :", point);

        this.props.setPoint(point);



    });
    const nextMarkers = places.map(place => ({
        position: place.geometry.location,
    }));
    const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

    this.setState({
        center: nextCenter,
        markers: nextMarkers,
    });

      // refs.map.fitBounds(bounds);
  }


  componentDidMount(){
    const { lat, lng } = this.props.point;
    if(lat !== '' && lng !== ''){
        this.setState({
            ...this.state,
            center : {
                lat,
                lng
            }
        })
    }
      
  }

  handleMapMounted(bounds, map) {

    this._map = map;
    if (map && bounds) {
        map.fitBounds(bounds)
        map.panToBounds(bounds);
    }
  }

  onPolygonComplete = (data) => {

    data.setMap(null);

    const { sites, setFieldValue, values } = this.props;

    let latLng;

    const result = sites.filter(site => {

      const { lat, lng } = JSON.parse(site.latlng);

      latLng = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));

      return google.maps.geometry.poly.containsLocation(latLng, data);
      
    });

    let screens = [...values.screens_from_map, ...result];

    screens = _.uniqBy(screens, "screen_name");

    setFieldValue("screens_from_map", screens);

    // console.log("Google", google.maps);
  }

  onMarkerClustererClick = (markerClusterer) => {
    const clickedMarkers = markerClusterer.getMarkers()
    // console.log(`Current clicked markers length: ${clickedMarkers.length}`)
    // console.log(clickedMarkers)
  }


  render(){

    // console.log("Sites", this.props.sites);
    const {  tableLoading } = this.props.inventory;

    if(!tableLoading){

      let bounds = new window.google.maps.LatLngBounds();

      this.props.sites.forEach(site => {
        const {lng, lat} = JSON.parse(site.latlng);
        // const [ lng, lat ] = site.geometry.coordinates;
        let latlng;
        try {

          
          latlng = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
          
        } catch(err){
          console.log(err)
        }

        bounds.extend(latlng);

      })

      return (
        <div>
  
          <MapComponent
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `550px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            // onSearchBoxMounted={this.onSearchBoxMounted}
            // onPlacesChanged={this.onPlacesChanged}
            onMapMounted={this.handleMapMounted.bind(this, bounds)}
            onBoundsChanged={this.onBoundsChanged}
            bounds={bounds}
            markers={this.props.sites}
            center={this.state.center}
            setFieldValue={this.props.setFieldValue}
            values={this.props.values}
            onMarkerClustererClick={this.onMarkerClustererClick}
            onPolygonComplete={this.onPolygonComplete}
  
          />
          
        </div>
      )

    } else {
      return <div>Loading...</div>
    }
  }
}

const mapStateToProps = (state) => {

  return {
    inventory: state.inventory
  }

}

export default connect(mapStateToProps, {})(GoogleMapLocations);
