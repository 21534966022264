import React, { useState, useEffect} from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
// import { Add } from "@material-ui/icons";
import { Link as LinkRouter } from "react-router-dom";
import ChangeScreenApproval from "./ChangeScreenApprovalButtons";
import ChangeCPMApproval from "./ChangeCPMApprovalButton";
import { fetchDOOHCampaignsForApproval } from "../../Actions/campaignActions";
import { connect } from "react-redux";
import ReviseCampaignMarkup from "./reviseCampaignMarkupByPublisher";
import StatusChip from '../Common/StatusChip';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));


const CampaignScreensForApproval = (props) => {

  // const [ camp , setCamp ] = useState({});

  const classes = useStyles();
  const { campaign_name, _id } = props.location.state;
  const { fetchDOOHCampaignsForApproval, campaigns} = props;

  const { state : campaign } = props.location;

  const {user_id} = props;
  const { id } = props.match.params;

  useEffect(() => {
    fetchDOOHCampaignsForApproval()
  }, []);

  // console.log("props", props);
  // console.log("props", user_id);

  const getScreens = () => {
    const campaign = campaigns.find(camp => camp._id === id);
    // console.log("Found", campaign);
    if(campaign) {
      // const screens = campaign.screens.filter(screen => screen.user_id === user_id );
      const screens = campaign.screens.filter(screen => user_id.includes(screen.user_id) );
      return screens;
    } else {
      return [];
    }
  }

  const ApprovalStatus = (rowData) => {

    let { isApproved } = rowData;

    if(isApproved === "Pending"){
        return <StatusChip bColor="#F1C40F" label={isApproved} />

    } else if(isApproved === "Approved"){
        return <StatusChip bColor="green" label={isApproved} />

    } else if(isApproved === "Disapproved") {
        return <StatusChip bColor="red" label={isApproved} />
    }

}

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        component={LinkRouter}
        to={`/campaignApproval`}
        className={classes.margin}
      >
        < ArrowBackIos />Back
      </Button>
      <MaterialTable
        title={`${campaign_name} Campaign's Screens`}
        columns={[
          { title: "Screen Name", headerStyle: { fontWeight: "bold" }, field: 'screen.screen_name' },
          { title: "Location", headerStyle: { fontWeight: "bold" }, field: 'screen.location' },
          { title: "Campaign Approval Status", headerStyle: { fontWeight: "bold" }, render : ApprovalStatus },
          { title : "Publisher", headerStyle: { fontWeight: "bold" }, field : 'screen.network_name' },
          // { title : "Network", headerStyle: { fontWeight: "bold" }, field : "screen.network"},
          { title: "CPM", headerStyle: { fontWeight: "bold" }, field : "screen.cpm"},
          { title: "Propsed CPM (Agency)", headerStyle: { fontWeight: "bold" }, field : "proposedPublisherCPM"},
          // { title: "Final CPM", headerStyle: { fontWeight: "bold" }, field : "finalCPM"},
          { title: "CPM Approval (Publisher)", headerStyle: { fontWeight: "bold" }, field : "isCpmApprovedByPublisher", type : "boolean"},
          {title :"Edit CPM Status", field : "",  headerStyle : { fontWeight : "bold" }, render: rowData => {
            return <ChangeCPMApproval data={{...rowData, camp_id : id}}/>
          }},
          {title :"Go live", field : "",  headerStyle : { fontWeight : "bold" }, render: rowData => {
            return <ChangeScreenApproval data={{...rowData, camp_id : id}}/>
          }}
        ]}

        // data={[...screens.filter(s => s.screen.user_id === id)]}
        data={getScreens()}
        options={{
          pageSize : 10,
          pageSizeOptions : [20, 50, 100]
        }}

        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar  {...props} />

              <div>
                <Grid container>
                  <Grid item>
                    <ReviseCampaignMarkup campaign={campaign}/>
                  </Grid>
                </Grid>
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user_id : [state.auth.user.id],
    campaigns : state.campaign.approvalCampaigns
  }
}

export default connect(mapStateToProps, {fetchDOOHCampaignsForApproval})(CampaignScreensForApproval);
// export default CampaignScreensForApproval;