import React, { useState, useEffect } from 'react';
import {
  Grid, Paper, Typography, AppBar, Tabs, Tab, Box, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction,
  Button, FormControl, FormControlLabel, RadioGroup, Radio, FormHelperText, TextField
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { Image, PlayCircleFilledOutlined, Link, Add, Delete } from "@material-ui/icons";
import InputField from "../CampaignFormItems/FormFields/InputField";
import SelectField from "../CampaignFormItems/FormFields/SelectField";
import DateField from "../CampaignFormItems/FormFields/DateField";
import ScheduleField from "../CampaignFormItems/FormFields/ScheduleField";
import {
  fetchApprovedCreatives, fetchCampaignLocationList, createDOOHCampaign
} from "../../../Actions/campaignActions";
import { fetchInventory } from "../../../Actions/inventoryActions";
import GoogleMapLocations from "./GoogleMapLocations";
import InventoryFilter from "../../Inventory/InventoryFilter";
import CampaignValidationSchema from "./CampaignValidation";
import CreativePreview from "./CreativePreview";
import SelectedCreativesComp from "./SelectedCreatives";
import SelectedSitesComponent from "./SelectedSites";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


const useStyles = makeStyles((theme) => ({

  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },

  mapList: {

    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 500,

  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none"
  },

  margin: {
    margin: theme.spacing(1)
  },

  formContent: {
    margin: theme.spacing(4),
    padding: theme.spacing(4)
  },

  formControlWidth: {
    width: "100%"
  }


}));

// Main Function
const CreateCampaign = (props) => {


  const classes = useStyles();

  const theme = useTheme();
  const {
    fetchApprovedCreatives, approvedCreatives, fetchCampaignLocationList, locations, fetchInventory, sites, createDOOHCampaign
  } = props;
  const [formData, setFormData] = useState({
    campaign_name: '',
    campaign_type: null,
    weather: null,
    start_date: new Date().toString(),
    end_date: '',
    schedule_type: '',
    schedule: [],
    select_location_type: '',
    screens: null,
    screens_from_map: [],
    creatives: [],
    campaign_budget: "",
    daily_budget_limit: "",
    impressions: '',
    daily_impression_limit: '',
    frequency: "",
    floor_cpm: "",
    bid_price: ""

  });

  const [term, setTerm] = useState('');

  const [cart, setCart] = useState([]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const removeFromCart = (marker, screens, setFieldValue) => {

    const result = screens.filter(s => s.screen_name !== marker.screen_name);

    setFieldValue("screens_from_map", result);
  }

  useEffect(() => {
    fetchApprovedCreatives();
    fetchCampaignLocationList();
    fetchInventory();
  }, []);

  useEffect(() => {

    setCart([...sites.map(site => {
      return {
        ...site,
        cart: false
      }
    })])

  }, [sites]);


  const addCreative = (creative, values, setFieldValue) => {
    const { creatives } = values;

    setFieldValue("creatives", [...creatives, creative]);


  }

  const removeCreative = (creative, index, values, setFieldValue) => {

    // const contents = [...formData.creatives];
    const contents = [...values.creatives];
    if (creative.original_name === contents[index].original_name) {

      // console.log("Math", index);
      contents.splice(index, 1);

      setFieldValue("creatives", contents);

    }

  }

  const returnHelperTextSelectLocationType = (errors, touched) => {
    let isError = errors.select_location_type && touched.select_location_type;

    // console.log("MyError is :", errors);

    if (isError) {
      return <FormHelperText>{errors.select_location_type}</FormHelperText>
    }
  }

  const makeImageList = (values, setFieldValue) => {
    const images = approvedCreatives.filter(c => c.original_name.toLowerCase().includes(term.toLowerCase())).filter((item) => item.file_type.includes("image"));

    return images.map((image, index) => (
      <ListItem key={index}>
        <ListItemIcon>
          {/* <Image /> */}
          <CreativePreview data={image} />
        </ListItemIcon>
        <ListItemText primary={image.original_name} />
        <ListItemSecondaryAction>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => addCreative(image, values, setFieldValue)}
          >
            <Add />
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    ));

  }

  const makeVideoList = (values, setFieldValue) => {
    const videos = approvedCreatives.filter(c => c.original_name.toLowerCase().includes(term.toLowerCase())).filter((item) => item.file_type.includes("video"));
    return videos.map((video, index) => (
      <ListItem key={index}>
        <ListItemIcon>
          {/* <PlayCircleFilledOutlined /> */}
          <CreativePreview data={video} />
        </ListItemIcon>
        <ListItemText primary={video.original_name} />
        <ListItemSecondaryAction>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => addCreative(video, values, setFieldValue)}
          >
            <Add />
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    ));
  }

  const makeWebLinkList = (values, setFieldValue) => {
    const webLinks = approvedCreatives.filter(c => c.original_name.toLowerCase().includes(term.toLowerCase())).filter((item) => item.file_type.includes("html"));
    return webLinks.map((webLink, index) => (
      <ListItem key={index}>
        <ListItemIcon>
          <a href={`${webLink.file_url}`} target="_blank">
            <Link />
          </a>
          {/* <CreativePreview data={webLink}/> */}
        </ListItemIcon>
        <ListItemText primary={webLink.original_name} />
        <ListItemSecondaryAction>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => addCreative(webLink, values, setFieldValue)}
          >
            <Add />
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    ));

  }

  const makeCartList = (values, setFieldValue) => {

    const { screens_from_map } = values;

    // const cartList = screens_from_map.filter(item => item.cart === true);

    return screens_from_map.map((list, index) => (
      <ListItem key={index}>
        <ListItemText primary={list.screen_name} />
        <ListItemSecondaryAction>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => removeFromCart(list, values.screens_from_map, setFieldValue)}
          >
            <Delete />
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    ))
  }

  const getListIcon = (creative) => {

    if (creative.file_type.includes("image")) {

      return <Image />;

    } else if (creative.file_type.includes("video")) {

      return <PlayCircleFilledOutlined />;

    } else if (creative.file_type.includes("html")) {

      return <Link />

    }
  }

  const selectedCreatives = (values, setFieldValue) => {

    const { creatives } = values;

    return creatives.map((creative, index) => (
      <ListItem key={index}>
        <ListItemIcon>
          {getListIcon(creative)}
        </ListItemIcon>
        <ListItemText primary={creative.original_name} />
        <ListItemSecondaryAction>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => removeCreative(creative, index, values, setFieldValue)}
          >
            <Delete />
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    ))

  }

  return (
    <div>
      <Formik

        initialValues={formData}
        enableReinitialize={true}
        validationSchema={CampaignValidationSchema}
        onSubmit={(data) => {

          createDOOHCampaign(data, () => {
            props.history.push("/campaign");
          });

          // console.log("Data",  data);
        }}
      >
        {
          ({ values, errors, touched, setFieldValue }) => (
            <Form>

              <Paper className={classes.formContent}>
                <Grid container spacing={4}>

                  <Grid item md={12} sm={12}>
                    <Typography variant="h6" align="left">Campaign details</Typography>
                  </Grid>

                  <Grid item md={6}>

                    <InputField
                      type="text"
                      name="campaign_name"
                      label="Campaign Name"
                      fullWidth

                    />
                  </Grid>
                  <Grid item md={6}>

                    <SelectField
                      name="campaign_type"
                      label="Campaign type"
                      data={[
                        { value: "DOOH", title: "DOOH" }
                      ]}
                      // data={["DOOH", "MOBILE", "WEB"]}
                      title="title"
                      // valueField="value"
                      // multiple
                      fullWidth
                    />

                  </Grid>

                  <Grid item md={6}>
                    <DateField
                      name="start_date"
                      fullWidth
                      label="Start Date"
                    />
                  </Grid>

                  <Grid item md={6}>

                    <DateField
                      name="end_date"
                      fullWidth
                      label="End Date"
                    />
                  </Grid>

                  <Grid item md={6}>
                    <InputField
                      type="number"
                      name="campaign_budget"
                      label="Campaign Budget"
                      fullWidth
                    />
                  </Grid>

                  <Grid item md={6}>
                    <InputField
                      type="number"
                      name="daily_budget_limit"
                      label="Daily budget limit (optional)"
                      fullWidth
                    />
                  </Grid>

                  {/* <Grid item md={6}>
                      <InputField 
                        type="number"
                        name="impressions"
                        label="Impressions"
                        fullWidth
                      />
                    </Grid> */}

                  <Grid item md={6}>
                    <InputField
                      type="number"
                      name="daily_impression_limit"
                      label="Daily impression limit (optional)"
                      fullWidth
                    />
                  </Grid>

                  <Grid item md={6}>
                    <InputField
                      type="number"
                      name="frequency"
                      label="Frequency gap in sec (optional)"
                      fullWidth
                    />
                  </Grid>

                  {/* <Grid item md={6}>
                      <InputField 
                        type="number"
                        name="bid_price"
                        label="Bid price(optional)"
                        fullWidth
                      />
                    </Grid>

                    <Grid item md={6}>
                      <InputField 
                        type="number"
                        name="floor_cpm"
                        label="Floor CPM"
                        fullWidth
                      />
                    </Grid> */}

                </Grid>
              </Paper>

              <Paper className={classes.formContent}>
                <Grid container spacing={4}>

                  <Grid item md={12} sm={12}>
                    <Typography variant="h6" align="left">Set Schedule</Typography>
                  </Grid>

                  <Grid item md={12} sm={12}>
                    <ScheduleField name="schedule_type" label="Schedule" scheduleFieldName="schedule" />
                  </Grid>

                </Grid>
              </Paper>

              <Paper className={classes.formContent}>
                <Grid container spacing={4}>

                  <Grid item md={12} sm={12}>
                    <Typography variant="h6" align="left">Select Locations</Typography>
                  </Grid>

                  <Grid item md={12} sm={12}>
                    {/* <FormControl component="fieldset" error={isError}> */}
                    <FormControl component="fieldset" error={errors.select_location_type && touched.select_location_type}>

                      <RadioGroup

                        name="select_location_type"
                        value={values.select_location_type}
                        onChange={(e) => {
                          setFieldValue('select_location_type', e.target.value);
                          // setFormData({
                          //   ...formData,
                          //   select_location_type : e.target.value
                          // })
                        }}

                      >
                        <FormControlLabel value="CHOOSE_FROM_SAVED_LOCATIONS" control={<Radio />} label="Choose from saved locations" />
                        <FormControlLabel value="SELECT_FROM_MAP" control={<Radio />} label="Select from map" />
                      </RadioGroup>
                      {returnHelperTextSelectLocationType(errors, touched)}
                    </FormControl>
                  </Grid>


                  {values.select_location_type === "CHOOSE_FROM_SAVED_LOCATIONS" &&

                    <Grid item md={12} sm={12}>
                      <SelectField
                        name="screens"
                        label="Select location group"
                        title="name"
                        data={locations}
                        fullWidth
                      />
                    </Grid>
                  }

                  {values.select_location_type === "SELECT_FROM_MAP" &&
                    <React.Fragment>
                      {/* <FormControl component="fieldset" error={errors.screens_from_map && touched.screens_from_map}> */}
                      <Grid item md={12} sm={12}>
                        <InventoryFilter />
                      </Grid>
                      <Grid item md={8} sm={12}>
                        <GoogleMapLocations
                          point={{ lat: 28.5433158, lng: 77.2127729 }}
                          sites={cart}
                          values={values}
                          setFieldValue={setFieldValue}
                          name="screens_from_map"
                        />
                      </Grid>

                      <Grid item md={4} sm={12}>
                        {/* Location List */}
                        {/* <div className={classes.mapList}>
                          <FormControl component="fieldset" error={errors.screens_from_map && touched.screens_from_map} className={classes.formControlWidth}>
                            <List dense={true}>
                              {makeCartList(values, setFieldValue)}
                              
                            </List>
                            { returnHelperTextScreensFromMap(errors, touched)}
                          </FormControl>
                        </div> */}

                        <SelectedSitesComponent
                          values={values}
                          errors={errors}
                          touched={touched}
                          removeFromCart={removeFromCart}
                          setFieldValue={setFieldValue}
                          sites={cart}
                        />

                      </Grid>
                      {/* </FormControl> */}
                    </React.Fragment>
                  }

                </Grid>
              </Paper>


              {/* <Paper className={classes.formContent}>

                 <Grid container spacing={4}>

                  <Grid item md={12} sm={12}>
                    <Typography variant="h6" align="left">Select Weather</Typography>
                  </Grid>

                  <Grid item md={6} sm={6}>

                    <SelectField 
                      name="weather"
                      label="Select Weather"
                      data={[
                          { value : "ANY WEATHER", title : "Any weather" },
                          { value : "STRONG WIND", title : "Strong wind" },
                          { value : "STORM", title : "Storm" },
                          { value : "FOG", title : "Fog" },
                          { value : "SNOW", title : "Snow" },
                          { value : "RAIN", title : "Rain" },
                          { value : "CLOUDY", title : "Cloudy" },
                          { value : "CLEAR", title : "Clear" },
                      ]}
                      // data={["DOOH", "MOBILE", "WEB"]}
                      title="title"
                      // valueField="value"
                      // multiple
                      fullWidth
                    />

                  </Grid>

                 </Grid>

               </Paper> */}

              <Paper className={classes.formContent}>
                <Grid container spacing={4}>

                  <Grid item md={12} sm={12}>
                    <Typography variant="h6" align="left">Select Creatives</Typography>
                  </Grid>

                  <Grid item md={5} sm={5}>
                    Creative Selected
                    {/* <div className={classes.list}>
                      <FormControl component="fieldset" error={!!errors.creatives && touched.creatives} className={classes.formControlWidth}>
                        <List dense={true}>
                          {selectedCreatives(values, setFieldValue)}
                          
                        </List>
                        { returnHelperTextCreatives(errors, touched) }
                      </FormControl>
                    </div> */}
                    <SelectedCreativesComp
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      removeCreative={removeCreative}
                      touched={touched}
                    />
                  </Grid>

                  <Grid item md={7} sm={7}>
                    <div >
                      <AppBar position="static" color="default">
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="fullWidth"
                          aria-label="full width tabs example"
                        >
                          <Tab label="Images" {...a11yProps(0)} />
                          <Tab label="Videos" {...a11yProps(1)} />
                          <Tab label="Web" {...a11yProps(2)} />
                        </Tabs>
                      </AppBar>

                      <TabPanel value={value} index={0} dir={theme.direction}>

                          <TextField
                            type="text"
                            name="term"
                            onChange={(e) => setTerm(e.target.value)}
                            variant="outlined"
                            label="Search Creative"
                            // fullWidth
                            size="small"
                            className={classes.margin}
                          />
                        <div className={classes.list}>
                          <List dense={true}>
                            {makeImageList(values, setFieldValue)}
                          </List>
                        </div>

                      </TabPanel>

                      <TabPanel value={value} index={1} dir={theme.direction}>

                          <TextField
                            type="text"
                            name="term"
                            onChange={(e) => setTerm(e.target.value)}
                            variant="outlined"
                            label="Search Creative"
                            // fullWidth
                            size="small"
                            className={classes.margin}
                          />
                        <div className={classes.list}>
                          <List dense={true}>
                            {makeVideoList(values, setFieldValue)}
                          </List>
                        </div>

                      </TabPanel>

                      <TabPanel value={value} index={2} dir={theme.direction}>

                          <TextField
                            type="text"
                            name="term"
                            onChange={(e) => setTerm(e.target.value)}
                            variant="outlined"
                            label="Search URL"
                            // fullWidth
                            size="small"
                            className={classes.margin}
                          />
                        <div className={classes.list}>
                          <List dense={true}>
                            {makeWebLinkList(values, setFieldValue)}
                          </List>
                        </div>

                      </TabPanel>
                    </div>
                  </Grid>


                </Grid>
              </Paper>

              {/* <Grid container spacing={4}> */}
              <div className={classes.formContent}>
                <Button type="submit" color="primary" variant="contained">Submit</Button>
              </div>
              {/* </Grid> */}

            </Form>
          )
        }
      </Formik>
    </div>
  )
}

const mapStateToProps = (state) => {

  return {
    approvedCreatives: state.campaign.approvedCreatives,
    locations: state.campaign.locationList,
    sites: state.inventory.inventoryScreens
  }
}

export default connect(mapStateToProps, {
  fetchApprovedCreatives, fetchCampaignLocationList, fetchInventory, createDOOHCampaign
})(CreateCampaign);
