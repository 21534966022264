import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
// import { Refresh } from "@material-ui/icons";
// import { Grid } from '@material-ui/core';
import CampaignDetails from "./CampaignFormItems/CampaignDetails";
import CampaignSchedule from "./CampaignFormItems/CampaignSchedule";
import CampaignLocationList from "./CampaignFormItems/CampaignLocationList";
import CampaignCreatives from "./CampaignFormItems/CompaignCreatives";
import { connect } from "react-redux";
// import MuiAlert from '@material-ui/lab/Alert';
// import * as Yup from "yup";
import { createDOOHCampaign } from "../../Actions/campaignActions";
import { Formik, Form  } from "formik";
import validationSchema from "./FormValidation";

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  }
}));

function getSteps() {
  // return ['Campain details', 'Select locations', 'Attach Creative'];
  return ['Campain details', 'Set Schedule', 'Select locations', 'Attach Creative'];
}


function CampaignForm(props) {

  const { createDOOHCampaign } = props;
  const [state, setState] = useState({
    campaign_name : '',
    campaign_type : null,
    start_date : '',
    end_date: '',
    schedule_type: '',
    schedule : [],
    screens : null,
    creatives: [],
    campaign_budget :0

  });

  // const [ error, setError ] = useState({
  //   showError : false,
  //   errors : []
  // });

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const currentValidationSchem = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1; 

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <CampaignDetails state={state} setState={setState} />;
      case 1:
        return <CampaignSchedule state={state} setState={setState} />;
      case 2:
        return <CampaignLocationList state={state} setState={setState} />;
      case 3:
        return <CampaignCreatives state={state} setState={setState} />
      default:
        return <div>Unknown stepIndex</div>;
    }
  }

  // const handleNext = () => {
    
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleReset = () => {
  //   setError({
  //     showError : false,
  //     errors : []
  //   });
  //   setActiveStep(0);
  // };

  const handleSubmit = (values, actions) => {

    if (isLastStep) {
      // _submitForm(values, actions);

      // console.log("Values", values);

      createDOOHCampaign(values, () => {
        props.history.push("/campaign");
      });


    } else {
      setActiveStep(activeStep + 1);

      actions.setTouched({});
      actions.setSubmitting(false);
    }

  }

  // const onCampaignSubmit = async () => {

    
  //   try {

  //     // const data = await schema.validate(state, { abortEarly : false });
  //     // console.log("Data :", data);
  //     // createDOOHCampaign(data, () => {
  //     //   props.history.push("/campaign");
  //     // });

  //   } catch(err) {
  //     // const { errors } = err;
  //     // setError({
  //     //   ...error,
  //     //   showError : true,
  //     //   errors
  //     // });
  //     // console.log("error :", err);
  //   }
  // }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            
            Form End
           
          </div>
        ) : (
          <div>
            <Formik
              initialValues={state}
              onSubmit={handleSubmit}
              validationSchema={currentValidationSchem}
              // validator={() => ({})}
            >
              {
                (formik) => {
                  const { isSubmitting } = props;
                  return (
                    <Form id="campaignForm">

                      {getStepContent(activeStep)}

                      <div className={classes.wrapper}>
                        {activeStep !== 0 && (
                          <Button onClick={handleBack} className={classes.margin} color="primary" variant="contained">
                            Back
                          </Button>
                        )}
                        {/* <div > */}
                          <Button
                            disabled={isSubmitting}
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.margin}
                          >
                            {isLastStep ? 'Submit' : 'Next'}
                          </Button>
                          {/* {isSubmitting && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )} */}
                        {/* </div> */}
                      </div>
                    </Form>
                  )
                }
              }
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
}

export default connect(null, {createDOOHCampaign})(CampaignForm);