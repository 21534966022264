export let rootURL;

if(process.env.NODE_ENV === "production"){

	rootURL = "https://screenoapi.locad.net";
	
} else if(process.env.NODE_ENV === "development"){

	rootURL = "http://localhost:5000";
}



export const secondsToTotalTime = function (value) {
	const sec = parseInt(value, 10); // convert value to number if it's string
	let hours = Math.floor(sec / 3600); // get hours
	let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
	let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
	// add 0 if value < 10; Example: 2 => 02
	if (hours < 10) {
		hours = "0" + hours;
	}
	if (minutes < 10) {
		minutes = "0" + minutes;
	}
	if (seconds < 10) {
		seconds = "0" + seconds;
	}
	return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
};

export const roleFormatter = (role) => {

	if(role === "Media Owner"){
		return "Publisher";
	} else if(role === "Advertiser"){
		return "Advertiser";
	}

}

export const rolePToM = (role) => {

	if(role === "Publisher")  {
		return "Media Owner";
	} else if(role === "Advertiser"){
		return "Advertiser";
	}
}
