import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Button, TextField, LinearProgress, FormControl, FormHelperText, Grid } from "@material-ui/core";
import { makeStyles, withStyles, lighten } from '@material-ui/core/styles';
import { Backup } from "@material-ui/icons";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Alert from "../../Common/Alert";

import { creativeUpload } from "../../../Actions/creativeActions";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  margin: {
    margin: theme.spacing(1),
  }
}));


const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: lighten('#ff6c5c', 0.5),
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#ff6c5c',
  },
})(LinearProgress);

function VideoUpload(props) {

  const { progress, creativeUpload, close, creativeError} = props;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const [formData, setFormData] = useState({
    file: '',
    fileName: '',
    type: '',
    duration: 0,
    size: 0
  });

  const [vidoeURL, setVideoURL] = useState("");


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // useEffect(() => {
    
  //   if(creativeError !=="") {

  //     setOpen(true);
  //   }
  // }, [creativeError]);

  const closeModal = () => {
    setFormData({
      file: '',
      fileName: '',
      type: '',
      duration: 0,
      size: 0
    });

    close();
  }

  const Vid = () => {
    return (<video
        style={{display : "none"}}
        onLoadedMetadata={(e) =>{
        
        setFormData({
            ...formData,
            duration: e.target.duration
        });
    }}
        >
        <source src={vidoeURL}/>
    </video>)
  }

  return (
    <div>
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          fileName : Yup.string().required("Please select the creative"),
          duration : Yup.number().positive("Duration cannot be negative").required("Creative duration required"),
          type : Yup.string().required("Creative Type is required"),
          size : Yup.number().required()
        })}

        onSubmit={(data, formikProps) => {
          creativeUpload(data, ()=>{
            handleClose();
            formikProps.resetForm();
          });
        }}
      >
        {({values, errors, setFieldValue, touched}) => {
          return (
            <Form>
              <Grid container>

                <Grid item md={12}>
                  <FormControl component="fieldset" error={errors.fileName && touched.fileName}>
                    <input 
                      type="file"
                      // name="file"
                      onChange={(e) => {

                        // console.log(e.target.files[0])

                        setFieldValue("file", e.target.files[0]);
                        setFieldValue("fileName", e.target.files[0].name);
                        setFieldValue("type", e.target.files[0].type);
                        setFieldValue("size", e.target.files[0].size);

                        setFormData({
                          ...formData,
                          file: e.target.files[0],
                          fileName: e.target.files[0].name,
                          type: e.target.files[0].type,
                          size: e.target.files[0].size
                        });
                    
                        if(e.target.files[0].type === "video/mp4"){
                          setVideoURL(URL.createObjectURL(e.target.files[0]))
                        }

                      }}
                    
                    />
                    { errors.fileName && touched.fileName ? <FormHelperText>{touched.fileName && errors.fileName}</FormHelperText> : null }
                  </FormControl>
                </Grid>

                <Grid item md={12}>
                  <Field 
                    value={values.duration}
                    as={TextField}
                    name="duration"
                    type="number"
                    label="Duration"
                    error={errors.duration && touched.duration}
                    helperText={touched.duration && errors.duration}
                    placeholder="Duration"
                  />

                </Grid>

                <Grid item md={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<Backup />}
                  > 
                    Upload
                  </Button>

                    <Button
                      onClick={closeModal}
                      variant="contained"
                      className={classes.margin}
                      color="secondary"
                      startIcon={<Backup />}
                    > 
                      Close
                    </Button>

                </Grid>

              </Grid>
              {vidoeURL !== "" && <Vid/> }
            </Form>
          );
        }}
      </Formik>
      
      {progress > 0 &&
        <BorderLinearProgress
          className={classes.margin}
          variant="determinate"
          color="secondary"
          value={progress}
        />
      }

      <Alert />
      {/* {JSON.stringify(formData, 2, null)} */}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    progress: state.uploadProgress,
    creativeError : state.creativeError.videoError
  }
}

export default connect(mapStateToProps, { creativeUpload })(VideoUpload);