import React, { useState, useEffect, createRef } from 'react';
import { connect } from "react-redux";
import MaterialTable, { MTableToolbar } from "material-table";
import { List, ListItem, Grid, Button } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from "@material-ui/core/styles";
import { CSVLink } from "react-csv";
import { CheckBox, CloudDownload } from "@material-ui/icons";
import {
    fetchInventory, addToCart
} from "../../Actions/inventoryActions";
// import { Link as LinkRouter } from "react-router-dom";
import SaveList from "../Campaign/MakeSiteList";

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    image : {
        height: "100%",
        width : "100%"
    },
    textToLeft : {
        textAlign : "right"
    },
    tableCss : {
        position: "relative",
        top: "50%",
        transform:`translateY(-50%)`
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


function InventoryTable(props) {

    // console.log("props :", props);
    const { fetchInventory, inventory, addToCart, push, auth } = props;
    const { inventoryScreens, tableLoading, cart } = inventory;

    const tableRef = createRef();
    const classes = useStyles();
    const [exportCSV, setExportCSV] = useState(false)
    const [ selected, setSelected ] = useState([]);

    const [open, setOpen] = React.useState(false);

    // const [modalOpen, setModalOpen] = React.useState(false);

    // const handleModalOpen = () => {
    //     setModalOpen(true);
    // };

    // const handleModalClose = () => {
    //     setModalOpen(false);
    // };

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const onSelectionChange = (data) => {
        
        // console.log("Table :", data);
        // let newItems = data.filter

        let locations = [];
        data.forEach( l => {

            const item = selected.find(s => s._id === l._id);

            if(typeof(item) !== "undefined"){
                
            } else {
                // return l;
                locations = [...locations, l];   
            }

        });

        setSelected([...selected,...locations]);

        // let item = data.map(d => {
        //     let temp = { ...d };
        //     delete temp.tableData;
        //     return temp;
        // })
        // addToCart(item);
    }

    const makeTableData = () => {
        
        let locations = inventoryScreens.map( l => {

            const item = selected.find(s => s._id === l._id);

            if(typeof(item) !== "undefined"){
                return item;
            } else {
                return l;
            }

        });

        return locations;
    }

    console.log("tableRef", tableRef);

    const onClickCsvReady = () => {

        if (cart.length === 0) {
            handleClick();
        } else {

            setExportCSV(true);
        }
    }

    const onClickClear = () => {

        tableRef.current.onAllSelected(false);
        fetchInventory();
        setExportCSV(false);
        setSelected([]);
    }

    const csvHeaders = [
        { label: "State", key: "state" },
        { label: "City", key: "city" },
        { label: "Category", key: "category" },
        { label: "Network", key: "network" },
        { label: "Address", key: "location" },
        { label: "Screen count", key: "screen_count" },
        { label: "Monthly Cost", key: "monthly_cost" },
        { label: "Monthly Impressions", key: "impressions" },
        { label: "Monthly Footfalls", key: "footfall" },
        { label: "Ad Slot (Sec)", key: "ad_slot" },
        { label: "Operating Hours", key: "operating_hours" },
        { label: "Loop Time (Min)", key: "loop_time" },
        { label: "Resolution", key: "screen_resolution" },
        { label: "Screen Type", key: "screen_type" }
        // { label: "", key : "" },
        // { label: "", key : "" },
    ];

    useEffect(() => {

        if(inventoryScreens.length === 0){

            fetchInventory();
            
        } else {
            
            let item = selected.map(d => {
                let temp = { ...d };
                delete temp.tableData;
                return temp;
            })
            addToCart(item);
        }
    }, [selected]);

    const renderCost = (rowData) => {

        // console.log("RowData :", rowData);

        // if(rowData.business_unit && rowData.business_unit.length > 0){
            
        //     let first = rowData.business_unit[0];
        //     const result = rowData.business_unit_costs.find(b => b.unit_name === first);

        //     // console.log("Result", result);

        //     return result.costs.SC
        //     // return "-";
        // } else {
            
        //     return rowData.monthly_cost;
        // }

        return rowData.monthly_cost;

    }

    const returnTableHeaders = () => {
        
        if(auth.role === "Advertiser"){

            return [
                { title: 'State', field: 'state', headerStyle: { fontWeight: "bold" } },
                { title: 'City', field: 'city', headerStyle: { fontWeight: "bold" } },
                { title: 'Category', field: 'category', headerStyle: { fontWeight: "bold" } },
                { title: 'Network', field: 'network', headerStyle: { fontWeight: "bold" } },
                { title: 'Screen Code', field: 'screen_name', headerStyle: { fontWeight: "bold" } },
                { title: 'Location', field: 'location', headerStyle: { fontWeight: "bold" } },
                { title: 'Screen count', field: 'screen_count', headerStyle: { fontWeight: "bold" }, type : "numeric" },
                { title: 'Impressions (Monthly)', field: 'impressions', headerStyle: { fontWeight: "bold" },type : "numeric" },
                { title: 'Footfall (Monthly)', field: 'footfall', headerStyle: { fontWeight: "bold" },type : "numeric" },
                { title: 'CPM', field: 'cpm', headerStyle: { fontWeight: "bold" } },

            ]

        } else {
            return [
                { title: 'State', field: 'state', headerStyle: { fontWeight: "bold" } },
                { title: 'City', field: 'city', headerStyle: { fontWeight: "bold" } },
                { title: 'Category', field: 'category', headerStyle: { fontWeight: "bold" } },
                { title: 'Network', field: 'network', headerStyle: { fontWeight: "bold" } },
                { title: 'Screen Code', field: 'screen_name', headerStyle: { fontWeight: "bold" } },
                { title: 'Location', field: 'location', headerStyle: { fontWeight: "bold" } },
                { title: 'Screen count', field: 'screen_count', headerStyle: { fontWeight: "bold" }, type : "numeric" },
                { title: 'Impressions (Monthly)', field: 'impressions', headerStyle: { fontWeight: "bold" },type : "numeric" },
                { title: 'Footfall (Monthly)', field: 'footfall', headerStyle: { fontWeight: "bold" },type : "numeric" },
                // { title: 'Monthly Cost',  headerStyle: { fontWeight: "bold" }, render : renderCost, type : "numeric" },
                { title: 'CPM', field: 'cpm', headerStyle: { fontWeight: "bold" } }

            ]
        }
    }

    return (
        <div>

            {/* <button onClick={onClick}>Doit</button> */}
            <Snackbar open={open} autoHideDuration={3500} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    No site selected
                </Alert>
            </Snackbar>

            <MaterialTable
                title="DOOH Inventory List"
                // data={inventoryScreens}
                data={makeTableData()}
                tableRef={tableRef}
                columns={returnTableHeaders()}

                isLoading={tableLoading}
                onSelectionChange={onSelectionChange}
                options={{
                    exportButton: true,
                    selection: true,
                    pageSize : 10,
                    pageSizeOptions : [20, 50, 80, 100]
                }}

                detailPanel={[
                    {
                        tooltip: 'Show more',
                        render: rowData => {
                            // console.log("Row Data:", rowData);
                            return <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    {/* <List>
                                        <ListItem><strong>State</strong> : {rowData.state}</ListItem>
                                        <ListItem><strong>City</strong> : {rowData.city}</ListItem>
                                        <ListItem><strong>Location</strong> : {rowData.location}</ListItem>
                                        <ListItem><strong>Screen Count</strong> : {rowData.screent_count}</ListItem>
                                        <ListItem><strong>Monthly Cost</strong> : {rowData.monthly_cost}</ListItem>
                                    </List> */}
                                    <img src={ rowData.file_url === "" ? `https://screeno.locad.net/img/no-image.jpg` : `${rowData.file_url}`} alt="site image" className={classes.image}/>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <table className={classes.tableCss}>
                                        <tbody>

                                            <tr>
                                                <th className={classes.textToLeft}>State</th>
                                                <td>{rowData.state}</td>
                                            </tr>
                                            <tr>
                                                <th className={classes.textToLeft}>City</th>
                                                <td>{rowData.city}</td>
                                            </tr>
                                            <tr>
                                                <th className={classes.textToLeft}>Location</th>
                                                <td>{rowData.location}</td>
                                            </tr>
                                            <tr>
                                                <th className={classes.textToLeft}>Screen Count</th>
                                                <td>{rowData.screen_count}</td>
                                            </tr>
                                            <tr>
                                                <th className={classes.textToLeft}>Monthly Cost</th>
                                                <td>{rowData.monthly_cost}</td>
                                            </tr>

                                        </tbody>
                                        
                                    </table>

                                </Grid>
                            </Grid>
                        }
                    }
                ]}

                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <div>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.margin}
                                    onClick={onClickClear}
                                    endIcon={<CheckBox />}
                                >
                                    Clear
                                </Button>
                                {/* <pre>Total Count : {JSON.stringify(cart[0], null, 2)}</pre> */}
                                {
                                    exportCSV ?

                                        <Button
                                            component={CSVLink}
                                            data={cart}
                                            headers={csvHeaders}
                                            filename={`Sites_list_${new Date()}.csv`}
                                            variant="contained"
                                            color="primary"
                                            startIcon={<CloudDownload />}
                                        >
                                            Download now
                                    </Button>

                                        :

                                        <Button
                                            onClick={onClickCsvReady}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Export CSV
                                    </Button>
                                }
                                {
                                    cart.length >= 0 &&
                                    
                                    <React.Fragment>
                                        <SaveList data={selected} push={push} clearTable={onClickClear}/>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    )
                }}

            />

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        inventory: state.inventory,
        auth : state.auth.user
    }
}

export default connect(mapStateToProps, { fetchInventory, addToCart })(InventoryTable);
