import React, { useEffect } from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
import { Button, Grid } from "@material-ui/core";
import { CloudDownload, Sync, Delete }  from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import { connect } from "react-redux";
import NewReportModal from "./NewReportModal";

import {
  fetchAllReports, deleteReport
} from "../../Actions/reportActions";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(2),
  }
}));

function ReportsTable(props) {


  const classes = useStyles();
  const { fetchAllReports, reports, deleteReport  } = props;

  const dateFormatter = (date) => {
    return format(new Date(date.updated_at), 'dd/MM/yyyy HH:mm:ss aaa')
  }

  const ReportDownload = (row) => {

    const { report_url } = row;

    if(report_url !== ""){

      return (
        <Button
          key={row._id}
          variant="contained"
          color="primary"
          // startIcon={}
          href={`${row.report_url}`}
        >
          <CloudDownload/>
        </Button>  
        
      );

    } else {

      return (
        <Button
          key={row._id}
          variant="contained"
          color="primary"
          // startIcon={<CloudDownload/>}
          href={`${row.report_url}`}
          disabled={true}
        >
          <CloudDownload/>
        </Button>
      );

    }
  }

  useEffect(() => {
    fetchAllReports();
  }, []);

  return (
    <div>
      <MaterialTable 
        title="Reports"
        columns={[
          { title : "Name", field : "report_name", headerStyle: { fontWeight: "bold" }},
          { title : "Start date", field : "start_date", headerStyle: { fontWeight: "bold" }},
          { title : "End date", field : "end_date", headerStyle: { fontWeight: "bold" }},
          { title : "Status", field : "report_status", headerStyle: { fontWeight: "bold" }},
          { title : "Last updated(D/M/Y)", field : "updated_at", headerStyle: { fontWeight: "bold" }, render : dateFormatter},
          // { title : "Last updated(D/M/Y)", field : "updated_at", headerStyle: { fontWeight: "bold" }, type : "datetime"},
          { title : "Download", field : "updated_at", headerStyle: { fontWeight: "bold" }, render : ReportDownload},
          { title : "Delete", field : "updated_at", headerStyle: { fontWeight: "bold" }, render : rowData => (
            <Button
              color="secondary"
              onClick={ () => deleteReport(rowData._id)}
              variant="contained"
            >
              <Delete/>
            </Button>
          )},
          
        ]}

        data={reports}
        components={{
          Toolbar : props => (
            <div>
              <MTableToolbar {...props} />
              <div>
              <Grid container spacing={1}>
                <Grid item>
                  <NewReportModal />
                  
                </Grid>
                <Grid item>
                  <Button
                      onClick={() => fetchAllReports()}
                      color="primary"
                      variant="contained"
                      className={classes.margin}
                      startIcon={<Sync />}
                    >
                      Refresh
                  </Button>
                </Grid>
              </Grid>
              </div>
            </div>
          )
        }}
      />
      
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    reports : state.reports.reports
  }
}

export default connect(mapStateToProps, {fetchAllReports, deleteReport})(ReportsTable);
