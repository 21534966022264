import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { 
  Button, TextField, Grid, FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText
 } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import * as Yup from "yup";
import { ArrowBack, CloudDownload } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import { Link as LinkRouter } from "react-router-dom";
import { CSVLink } from "react-csv";
import { Formik, Form, Field } from "formik";
import { addSubUser, getSubUsers, updateClientAccessMarkup } from "../../../Actions/profileActions";
import { fetchScreensForMarkup  } from "../../../Actions/adminActions";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));


// clients/updateAgencyMarkup
function UpdateClientMarkup(props) {

  const classes = useStyles();
  const { fetchScreensForMarkup, getSubUsers, subUsers, screens, updateClientAccessMarkup } = props;

  const [ formData, setFormData ] = useState({
    advertiser: null,
    accessTo : null,
    publishersOfAgency : null,
    specificPublishers : [],
    markupType : "",
    sameMarkup : "",
    file: "",
    filename: "",
  });

  const [ show, setShow ] = useState(false);

  let advertiserTypes = [
    "Publishers of this agency",
    "Specific Publishers"
  ];

  const [operation, setOperation] = useState("");
  const [ templateExport, setTemplateExport ] = useState([]);

  const returnPublishers = () => {
    let publishers = subUsers.filter( s => s.role === "Media Owner");
    return publishers;
  }

  const returnAdvertisers = () => {
    let advertisers = subUsers.filter( s => s.role === "Advertiser");
    return advertisers;
  }

  useEffect(() => {
    fetchScreensForMarkup();
    getSubUsers();
  }, []);

  const returnAccessUpdate = () => {
    
    return (
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          advertiser : Yup.string().required("Please select an advertiser").nullable(),
          accessTo : Yup.string().required("Please select the option").nullable(),
          specificPublishers : Yup.array().when('accessTo', {
            is: "Specific Publishers",
            then : Yup.array().required("Please select publishers"),
            otherwise : Yup.array()
          }),
          markupType : Yup.string().required("Please choose markupType"),
          filename : Yup.string().when("markupType", {
            is: "VARIABLE",
            then: Yup.string().required("Please select markup file"),
            otherwise : Yup.string()
          }),
          sameMarkup : Yup.number().when("markupType", {
            is : "SAME_FOR_ALL",
            then : Yup.number().required("Please enter markup value"),
            otherwise : Yup.number()
          })

        })}
        onSubmit={(data, formikProps) => {

          const formData = new FormData();

          formData.append("markupFile", data.file);
          formData.append("filename", data.filename);
          formData.append("advertiser", JSON.stringify(data.advertiser));
          formData.append("accessTo", data.accessTo);
          formData.append("specificPublishers", JSON.stringify(data.specificPublishers));
          formData.append("markupType", data.markupType);
          formData.append("sameMarkup", data.sameMarkup);

          updateClientAccessMarkup(formData, () => {
            setOperation("");
            getSubUsers();
            formikProps.resetForm();
          });

          // formikProps.resetForm();
        }}
      >
        {({values, errors, touched, setFieldValue}) => (
          <Form>
            <Grid container spacing={4}>

              <Grid item md={6}>
                <Autocomplete
                    id="advertiser"
                    value={values.advertiser ? values.advertiser : null}
                    name="advertiser"
                    options={returnAdvertisers()}
                    size="small"
                    onChange={(e, value, reason) => {

                      // console.log("Value :", value);
                      
                      if(value === null) {
                        setFieldValue("advertiser", "");
                      } else {

                        // console.log(value);
                        setFieldValue("advertiser", value);
                        setFieldValue("accessTo", value.accessTo);

                        if(value.accessTo === "Publishers of this agency"){
                          let pubs = returnPublishers();
                          
                          setFieldValue("publishersOfAgency", pubs);
                          setScreenForTemplate(pubs);
                        } else if(value.accessTo === "Specific Publishers"){

                          setFieldValue("specificPublishers", value.publisherIds);
                          
                        }
                      }
                    }}
                    
                    getOptionLabel={option => option.email }
                    renderOption={(option) => (
                      <React.Fragment>
                        {`${option.name} <${option.email}>`}
                      </React.Fragment>
                    )}
                    getOptionSelected={(option, value) => option.email === value.email}
                  
                    renderInput={(params) => (
                      <TextField 
                        // as={TextField} 
                        {...params} 
                        name="advertiser" 
                        label="Select advertiser"
                        // {...getFieldProps("advertiser")}
                        error={touched.advertiser && !!errors.advertiser}
                        helperText={touched.advertiser && errors.advertiser}
                        
                      />
                    )}
                  />
              </Grid>

              { values.advertiser ?

              <Grid item md={6}>
                  <Autocomplete 
                    id="accessTo"
                    value={values.accessTo ? values.accessTo : null}
                    options={advertiserTypes}
                    size="small"
                    onChange={(e, value) => {

                      if(value === null) {
                        setFieldValue("accessTo", "");
                        
                      } else {
                        setFieldValue("accessTo", value);

                        if(value === "Publishers of this agency"){
                          let pubs = returnPublishers();
                          setScreenForTemplate(pubs);
                          
                          setFieldValue("publishersOfAgency", pubs);
                        } else if(value === "Specific Publishers"){

                          setScreenForTemplate(values.advertiser.publisherIds);

                          setFieldValue("specificPublishers", values.advertiser.publisherIds)
                        }
                      }
                      
                    }}

                    getOptionLabel={option => option }

                    renderInput={(params) => (
                      <TextField 
                        // as={TextField} 
                        {...params} 
                        name="accessTo" 
                        label="Access To"

                        error={touched.accessTo && !!errors.accessTo}
                        helperText={touched.accessTo && errors.accessTo}
                        
                      />
                    )}
                  />

                </Grid>
                  : null
                }

                {
                  values.advertiser && values.accessTo === "Specific Publishers" ?
                  <Grid item md={12}>
                    <Autocomplete 
                      id="publisherIds"
                      value={values.specificPublishers ? values.specificPublishers : null}
                      options={returnPublishers()}
                      size="small"
                      onChange={(e, value) => {

                        // console.log("Pubs :", value);
                        if(value === null) {
                          setFieldValue("specificPublishers", []);
                        } else {
                          setScreenForTemplate(value);
                          setFieldValue("specificPublishers", value);
                        }
                        
                      }}
                      multiple
                      getOptionLabel={option => `${option.name} <${option.email}>` }
                      getOptionSelected={(option, value) => option.email === value.email}
                      renderInput={(params) => (
                        <TextField 
                          // as={TextField} 
                          {...params} 
                          name="specificPublishers" 
                          label="Select Publishers"

                          error={touched.specificPublishers && !!errors.specificPublishers}
                          helperText={touched.specificPublishers && errors.specificPublishers}
                          
                        />
                      )}
                    />

                  </Grid> 
                  : null
                }

                { showMarkupMenu(values, errors, touched, setFieldValue) }

                {
                values.advertiser && values.markupType && values.markupType === "SAME_FOR_ALL" ? (
                  <Grid item md={12}>
                    <Field
                      type="number"
                      name="sameMarkup"
                      label="Enter Markup value (%)"
                      as={TextField}
                      fullWidth
                      error={touched.sameMarkup && !!errors.sameMarkup}
                      helperText={touched.sameMarkup && errors.sameMarkup}
                      
                    />
                </Grid>
                ) : 
                
                values.advertiser && values.markupType &&
                <Grid item md={12}>
                  <Grid container spacing={4}>

                    <Grid item md={6} sm={6}>
                      {
                        show ? (
                          <React.Fragment>
                          <Button
                            component={CSVLink}
                            filename={"fill_Markup.csv"}
                            data={templateExport}
                            color="primary"
                            variant="contained"
                            startIcon={<CloudDownload />}
                          >
                            Download template  
                          </Button>
                          <p>Please set the markup values in sheet and upload using file picker present on the right side</p>
                          </React.Fragment>

                        ) : (
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => setShow(!show)}
                          >
                            Get Template
                          </Button>
                        )
                      }

                    </Grid>

                    <Grid item md={6}>
                      <FormControl component="fieldset" error={errors.filename && touched.filename}>
                      <input
                        name="markupFile"
                        // value={invoiceData.filename}
                        type="file"
                        
                        onChange={(e, value) => {
                          
                          setFieldValue("file", e.currentTarget.files[0]);
                          setFieldValue("filename", e.currentTarget.files[0].name);
                        }}
                      />
                      { errors.filename && touched.filename ? <FormHelperText>{touched.filename && errors.filename}</FormHelperText> : null }
                      </FormControl>
                    </Grid>

                  </Grid>
                </Grid>
              }

                <Grid item md={12} sm={12}>

                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Submit
                  </Button>

                </Grid>

            </Grid>
          </Form>
        )}

      </Formik>
    )
  }

  function setScreenForTemplate (publishers){
    let templateScreens = [];
    let temp;
    // console.log("TempPPP :", publishers);

    publishers.forEach(p => {
      temp = screens.filter(s => s.user_id === p._id);
      
      temp = temp.map(t => {

        let obj = {...t}
        delete obj.user_id;
        
        return {
          ...obj,
          publisher : p.email,
          markup : ""
        }
      });

      templateScreens = [...templateScreens, ...temp];
    });

    // console.log("Temp :", templateScreens);

    setTemplateExport(templateScreens)
  }

  function showMarkupMenu(values, errors, touched, setFieldValue){

    const { advertiser, accessTo} = values;

    if(advertiser && accessTo !== null){

      return (
        <Grid item md={12} sm={12}>
          <h3>Choose markup type</h3>
          <FormControl component="fieldset" error={errors.markupType && touched.markupType}>
  
            <RadioGroup
              name="markupType"
              value={values.markupType}
              onChange={(e) => {
  
                if(e.target.value === "VARIABLE"){
                  setFieldValue("markupType", e.target.value);
  
                } else {
                  setFieldValue("markupType", e.target.value);
                  
                }
  
              }}
            >
  
              <FormControlLabel
                value="SAME_FOR_ALL" 
                control={<Radio />} 
                label="Same for all"
                
              />

              <FormControlLabel 
                value="VARIABLE" 
                control={<Radio />} 
                label="Variable" 
                
              />
  
            </RadioGroup>

            { errors.markupType && touched.markupType ? <FormHelperText>{touched.markupType && errors.markupType}</FormHelperText> : null }
            
          </FormControl>
  
        </Grid>
      )

    } else {
      return null;
    }
    
  }

  const returnMarkupUpdate = () => {

    return (
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          advertiser : Yup.string().required("Please select an advertiser").nullable(),
          accessTo : Yup.string().required("Please select the option").nullable(),
          specificPublishers : Yup.array().when('accessTo', {
            is: "Specific Publishers",
            then : Yup.array().required("Please select publishers"),
            otherwise : Yup.array()
          }),
          markupType : Yup.string().required("Please choose markupType"),
          filename : Yup.string().when("markupType", {
            is: "VARIABLE",
            then: Yup.string().required("Please select markup file"),
            otherwise : Yup.string()
          }),
          sameMarkup : Yup.number().when("markupType", {
            is : "SAME_FOR_ALL",
            then : Yup.number().required("Please enter markup value"),
            otherwise : Yup.number()
          })

        })}
        onSubmit={(data, formikProps) => {

          const formData = new FormData();

          formData.append("markupFile", data.file);
          formData.append("filename", data.filename);
          formData.append("advertiser", JSON.stringify(data.advertiser));
          formData.append("accessTo", data.accessTo);
          formData.append("specificPublishers", JSON.stringify(data.specificPublishers));
          formData.append("markupType", data.markupType);
          formData.append("sameMarkup", data.sameMarkup);

          updateClientAccessMarkup(formData, () => {
            setOperation("");
            getSubUsers();
            formikProps.resetForm();
          });

          // formikProps.resetForm();
        }}
      >
        {({values, errors, touched, setFieldValue}) => (
          <Form>
            <Grid container spacing={4}>

              <Grid item md={6}>

                <Autocomplete
                    id="advertiser"
                    value={values.advertiser ? values.advertiser : null}
                    name="advertiser"
                    options={returnAdvertisers()}
                    size="small"
                    onChange={(e, value, reason) => {

                      // console.log("Value :", value);
                      
                      if(value === null) {
                        setFieldValue("advertiser", "");
                      } else {

                        // console.log(value);
                        setFieldValue("advertiser", value);
                        setFieldValue("accessTo", value.accessTo);

                        if(value.accessTo === "Publishers of this agency"){
                          let pubs = returnPublishers();
                          
                          setFieldValue("publishersOfAgency", pubs);
                          setScreenForTemplate(pubs);
                        } else if(value.accessTo === "Specific Publishers"){

                          setFieldValue("specificPublishers", value.publisherIds);
                          setScreenForTemplate(value.publisherIds);
                        }
                      }
                    }}
                    
                    
                    getOptionLabel={option => option.email }
                    renderOption={(option) => (
                      <React.Fragment>
                        {`${option.name} <${option.email}>`}
                      </React.Fragment>
                    )}
                    getOptionSelected={(option, value) => option.email === value.email}
                  
                    renderInput={(params) => (
                      <TextField 
                        // as={TextField} 
                        {...params} 
                        name="advertiser" 
                        label="Select advertiser"
                        // {...getFieldProps("advertiser")}
                        error={touched.advertiser && !!errors.advertiser}
                        helperText={touched.advertiser && errors.advertiser}
                        
                      />
                    )}
                    
                  />

              </Grid>
              { showMarkupMenu(values, errors, touched, setFieldValue) }

                {
                values.advertiser && values.markupType && values.markupType === "SAME_FOR_ALL" ? (
                  <Grid item md={12}>
                    <Field
                      type="number"
                      name="sameMarkup"
                      label="Enter Markup value (%)"
                      as={TextField}
                      fullWidth
                      error={touched.sameMarkup && !!errors.sameMarkup}
                      helperText={touched.sameMarkup && errors.sameMarkup}
                      
                    />
                </Grid>
                ) : 
                
                values.advertiser && values.markupType &&
                <Grid item md={12}>
                  <Grid container spacing={4}>

                    <Grid item md={6} sm={6}>
                      {
                        show ? (
                          <React.Fragment>
                          <Button
                            component={CSVLink}
                            filename={"fill_Markup.csv"}
                            data={templateExport}
                            color="primary"
                            variant="contained"
                            startIcon={<CloudDownload />}
                          >
                            Download template  
                          </Button>
                          <p>Please set the markup values in sheet and upload using file picker present on the right side</p>
                          </React.Fragment>

                        ) : (
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => setShow(!show)}
                          >
                            Get Template
                          </Button>
                        )
                      }

                    </Grid>

                    <Grid item md={6}>
                      <FormControl component="fieldset" error={errors.filename && touched.filename}>
                      <input
                        name="markupFile"
                        // value={invoiceData.filename}
                        type="file"
                        
                        onChange={(e, value) => {
                          
                          setFieldValue("file", e.currentTarget.files[0]);
                          setFieldValue("filename", e.currentTarget.files[0].name);
                        }}
                      />
                      { errors.filename && touched.filename ? <FormHelperText>{touched.filename && errors.filename}</FormHelperText> : null }
                      </FormControl>
                    </Grid>

                  </Grid>
                </Grid>
              }

                <Grid item md={12} sm={12}>

                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Submit
                  </Button>

                </Grid>

            </Grid>
          </Form>
        )}

      </Formik>
    )
  }

  return (
    <div>

      <Button
        variant="contained"
        color="primary"
        startIcon={<ArrowBack />}
        // className={classes.margin}
        component={LinkRouter}
        to={"/clients"}
      >
        Back
      </Button>


      <Grid container spacing={2}>
        
        <Grid item md={6} sm={12}>
          <h3>Select the operation</h3>
          <FormControl component="fieldset">
            <RadioGroup
                name="operation"
                value={operation}
                onChange={(e) => {
                  setFormData({
                    advertiser: null,
                    accessTo : null,
                    publishersOfAgency : null,
                    specificPublishers : [],
                    markupType : "",
                    sameMarkup : "",
                    file: "",
                    filename: "",
                  })
                  setOperation(e.target.value)
                }}
              >
    
                <FormControlLabel
                  value="UPDATE_ACCESS" 
                  control={<Radio />} 
                  label="Update advertiser access and markup"
                  
                />

                <FormControlLabel 
                  value="UPDATE_MARKUP" 
                  control={<Radio />} 
                  label="Update advertiser markup" 
                  
                />
    
              </RadioGroup>
          </FormControl>
        </Grid>
        
        <Grid item md={12}>

          {
            operation && operation === "UPDATE_ACCESS" ? 

            returnAccessUpdate() 
            :
            operation && returnMarkupUpdate() 
            // null
          }
        </Grid>

      </Grid>


      
      
    </div>
  )
}

const mapStateToProps = (state) => {

  return {
    subUsers : state.profile.subUsers,
    screens : state.admin.screensForMarkup
  }
}

export default connect(mapStateToProps, {
  fetchScreensForMarkup, getSubUsers, updateClientAccessMarkup
})(UpdateClientMarkup);
