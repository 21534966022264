import { combineReducers } from "redux";
import auth from "./authReducer";
import screens from "./screenReducer";
import creatives from "./creativeReducer";
import creativeError from "./creativeErrorReducer";
import uploadProgress from "./uploadProgressReducer";
import inventory from "./inventoryReducer";
import creativeAdmin from "./creativeApprovalReducer";
import campaign from "./campaignReducer";
import screenshots from "./screenshotReducers"
import dashboard from "./dashboardReducer";
import profile from "./profileReducer";
import admin from "./adminReducer";
import reports from "./reportReducer";
import alert from "./alertReducer";
 
const appReducer =  combineReducers({
    auth,
    screens,
    creatives,
    creativeError,
    uploadProgress,
    inventory,
    creativeAdmin,
    campaign,
    screenshots,
    dashboard,
    profile,
    admin,
    reports,
    alert
});

// const rootReducer = (state, action) => {

//     if(action.type === "USER_LOGOUT"){
//         state = undefined;
//     }

//     return appReducer(state, action);

// }

export default appReducer;
