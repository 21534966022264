import * as Yup from "yup";

// let minDate = new Date(new Date().setHours(0, 0, 0));
let minDate = new Date(new Date());
// let minDate = new Date(Date.now());

export default [
  Yup.object().shape({
    campaign_name : Yup.string().required("Campaign name is required"),
    campaign_type : Yup.string().required("Campaign type is required").nullable(),
    campaign_budget:  Yup.number().positive('Campaign Budget must be a positive number'),
    start_date : Yup.date().min(minDate, "Start date can't be less than today").required("Please select Start date"),
    end_date : Yup.date().when('start_date', (start_date, schema) => {
      // console.log("Start_date :", start_date);
      // console.log("schema_:", schema);
      return start_date && schema.min(start_date, "End Date Must be greater than Start Date")
    }).required("Please Select End Date")
  }),

  Yup.object().shape({
    schedule_type : Yup.string().oneOf(['RUN_ALL_TIME', 'RUN_ON_SCHEDULE']).required("Schedule type is required"),
    schedule :Yup.array().required("Schedule is required"),
  }),

  Yup.object().shape({
    screens : Yup.object().required('No locations selected from list')
  }),

  Yup.object().shape({
    creatives : Yup.array().required('No creatives selected from list')
  })
]

