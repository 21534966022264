import React from 'react';
import {Grid} from "@material-ui/core"

const NotFound = () => {
    return (
        <Grid container justify="center">
            <h2>404 Found not found</h2>
        </Grid>
    )
}

export default NotFound;