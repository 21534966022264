import { GET_ALL_SCREENSHOTS } from "../Actions/types";

const initState = [];

export default (state=initState, action) => {

  const { type , payload } = action;

  switch(type){

    case GET_ALL_SCREENSHOTS:
      return payload;

    default:
      return state;
  }

}