import {
  FETCH_ALL_CREATIVE_ADMIN, APPROVE_CREATIVE_ADMIN, DISAPPROVE_CREATIVE_ADMIN
} from "../Actions/types";

const initialState = [];

export default (state=initialState, action) => {

  const { type, payload } = action;

  switch(type){

    case FETCH_ALL_CREATIVE_ADMIN:
      return payload;
    
    case APPROVE_CREATIVE_ADMIN:
      return [
        ...state.map(s => payload._id === s._id ? payload : s)
      ];

    case DISAPPROVE_CREATIVE_ADMIN:
      return [
        ...state.map(s => payload._id === s._id ? payload : s)
      ];
    
      default:
        return state;
  }

}