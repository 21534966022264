import React, { useState, useEffect } from 'react';
import { Button, Modal, Grid, TextField, FormControl, FormHelperText } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { AttachMoney, CloudDownload } from "@material-ui/icons";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';
import { Formik, Field, Form } from "formik";
import { getSubUsers } from "../../../Actions/profileActions";
import { updateCampaignMarkup } from "../../../Actions/adminActions";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none"
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const ReviseCampaignMarkupAgency = (props) => {

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {  subUsers, campaign, updateCampaignMarkup } = props;

  const [formData, setFormData] = useState({
    file : "",
    filename : "",
    campaign : null
  });

  const [templateExport, setTemplateExport] = useState([]);

  // console.log("Data :", formData);
  // console.log("Props :", props);

  const handleOpen = () => {
    setOpen(true);

  }

  const handleClose = () => {
    setOpen(false);

  };

  const returnTemplateData = () => {

    const { screens } = props.campaign;
    const publihsers = subUsers.filter(s => s.role === "Media Owner");
    let find;

    let data = screens.map(s => {

      find = publihsers.find(p => p._id === s.screen.user_id);

      return {
        campaign : props.campaign.campaign_name,
        screen_name : s.screen.screen_name,
        publisher : find.email,
        markup :s.markup,
        publisherCPM : s.publisherCPM,
        advertiserCPM : s.advertiserCPM,
        proposed_publisher_cpm : s.proposedPublisherCPM,
        cpm_approval : s.isCpmApprovedByAgency
        
      }
    });

    setTemplateExport(data);

  }

  useEffect(() => {
    if(subUsers.length > 0){
      returnTemplateData();
    }
  }, [subUsers])


  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        startIcon={<AttachMoney />}
        className={classes.margin}
      >
        Update CPM
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Share Campaigns</h2>
            <div>

              
            </div>
            <Paper style={{width: "50vw", padding: "1rem" }}>
              <Formik
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                  filename : Yup.string().required("Please select markup file"),
                  campaign : Yup.object().required()
                })}
                initialValues={formData}

                onSubmit={async (data, props) => {

                  const formData = new FormData();

                  formData.append("markupFile", data.file);
                  formData.append("filename", data.filename);
                  formData.append("campaign", JSON.stringify(data.campaign));

                  updateCampaignMarkup(formData, ()=>{
                    console.log("data", data);
                  });
                  
                }}

              >
                {({ values, errors, touched, setFieldValue }) => (
                  <Form>
                    <Grid container spacing={2}>
                    
                      <Grid item md={6} sm={12}>

                          <Button
                            component={CSVLink}
                            filename={"fill_Markup.csv"}
                            data={templateExport}
                            color="primary"
                            variant="contained"
                            startIcon={<CloudDownload />}
                          >
                            Download template  
                          </Button>
                          

                      </Grid>

                      <Grid item md={6} sm={12}>

                        <FormControl component="fieldset" error={errors.filename && touched.filename}>
                        <input
                          name="markupFile"
                          // value={invoiceData.filename}
                          type="file"
                          
                          onChange={(e, value) => {
                            
                            setFieldValue("file", e.currentTarget.files[0]);
                            setFieldValue("filename", e.currentTarget.files[0].name);
                            setFieldValue("campaign", campaign);
                          }}
                        />
                        { errors.filename && touched.filename ? <FormHelperText>{touched.filename && errors.filename}</FormHelperText> : null }
                        </FormControl>

                      </Grid>

                      <Grid item md={12}>
                        <Button type="submit" color="primary" variant="contained"> Submit </Button>
                      </Grid>


                      <Grid item md={12}>
                        <p>Please set the markup values in sheet and upload using file picker present on the right side</p>
                      </Grid>

                    </Grid>
                  </Form>
                )}
              </Formik>

            </Paper>
          </div>
        </Fade>
      </Modal>

    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    subUsers: state.profile.subUsers
  };
}

export default connect(mapStateToProps, { getSubUsers, updateCampaignMarkup })(ReviseCampaignMarkupAgency);
