import React, { useState, useEffect } from 'react';
import { 
  Button, Modal, Grid, TextField, FormControl, InputLabel, MenuItem, FormHelperText, Select
 } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Assignment } from "@material-ui/icons";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { format } from "date-fns";
import { 
  getDataForReports, generateReport, getCampaignReport, getCampaignProfitMarginReport, publisherMonetizationReport,
  screenLogReport, contentLogReport
} from "../../Actions/reportActions";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none"
  },
  margin: {
    margin: theme.spacing(2),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function NewReportModal(props) {

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { 
    getDataForReports, role, screens, creatives, campaigns, advertiserCampaigns , generateReport, getCampaignReport, 
    getCampaignProfitMarginReport, publisherMonetizationReport, screenLogReport, contentLogReport
  } = props;

  const [formData, setFormData] = useState({
    selectReportType : "",
    from : format(new Date(), 'yyyy-MM-dd'),
    to : format(new Date(), 'yyyy-MM-dd'),
    screen : [],
    creative : [],
    campaign : [],
    advertiserCampaign : [],
    report_type : "Impression"
  });

  // let startDate = format(new Date(), 'yyyy-MM-dd');


  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  let selectTypes = () =>  {

    if(role === "Media Owner"){

      return ['Campaign Report', 'Creative Log Report', 'Screen Log Report', "Publisher Monetization Report"].map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>);

    } else if(role === "Advertiser") {

      return ['Creative Log Report', 'Campaign Report'].map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>);

    } else if (role === "AgencyPartnerAdmin"){

      return <MenuItem value='Campaign Profit'>Campaign Profit margin</MenuItem>;

    } else {

      return <MenuItem value='Creative Log Report'>Creative</MenuItem>;

    }

  }

  const getFormFields = (formik) => {

    const { values, errors, touched, setFieldValue } = formik;

    if(role === "Media Owner"){

      if(values.selectReportType === "Screen Log Report"){
        return (
          <Autocomplete 
            id="screen"
            value={values.screen}
            options={screens}
            size="small"
            onChange={(e, value) => {

              setFieldValue("screen", value);
              
            }}
            multiple
            getOptionLabel={option =>  option.screen_name}
            getOptionSelected={(option, value) => option.screen_name === value.screen_name}
            renderInput={(params) => (
              <TextField 
                {...params} 
                name="screen" 
                label="Select screen"

                error={touched.screen && !!errors.screen}
                helperText={touched.screen && errors.screen}
                
              />
            )}
          />
        );
      } else if(values.selectReportType === "Creative Log Report"){

        return (
          <Autocomplete 
            id="creative"
            value={values.creative}
            options={creatives}
            size="small"
            onChange={(e, value) => {

              setFieldValue("creative", value);
              
            }}
            multiple
            getOptionLabel={option =>  option.original_name}
            getOptionSelected={(option, value) => option.original_name === value.original_name}
            renderInput={(params) => (
              <TextField 
                {...params} 
                name="creative" 
                label="Select creative"

                error={touched.creative && !!errors.creative}
                helperText={touched.creative && errors.creative}
                
              />
            )}
          />
        );

      } else if(values.selectReportType === "Campaign Report"){

        return (
          <Autocomplete 
            id="campaign"
            value={values.campaign}
            options={campaigns}
            size="small"
            onChange={(e, value) => {
  
              setFieldValue("campaign", value);
              
            }}
            multiple
            getOptionLabel={option =>  option.campaign_name}
            getOptionSelected={(option, value) => option.campaign_name === value.campaign_name}
            renderInput={(params) => (
              <TextField 
                {...params} 
                name="campaign" 
                label="Select campaign"
  
                error={touched.campaign && !!errors.campaign}
                helperText={touched.campaign && errors.campaign}
                
              />
            )}
          />
        );
  
      } else if(values.selectReportType === "Publisher Monetization Report"){

        return (
          <Autocomplete 
            id="advertiserCampaign"
            value={values.advertiserCampaign}
            options={advertiserCampaigns}
            size="small"
            onChange={(e, value) => {
  
              setFieldValue("advertiserCampaign", value);
              
            }}
            multiple
            getOptionLabel={option =>  option.campaign_name}
            getOptionSelected={(option, value) => option.campaign_name === value.campaign_name}
            renderInput={(params) => (
              <TextField 
                {...params} 
                name="campaign" 
                label="Select campaign"
  
                error={touched.advertiserCampaign && !!errors.advertiserCampaign}
                helperText={touched.advertiserCampaign && errors.advertiserCampaign}
                
              />
            )}
          />
        );
  
      } 
    } else if(role === "Advertiser") {

      if(values.selectReportType === "Creative Log Report"){

        return (
          <Autocomplete 
            id="screen"
            value={values.creative}
            options={creatives}
            size="small"
            onChange={(e, value) => {
  
              setFieldValue("creative", value);
              
            }}
            multiple
            getOptionLabel={option =>  option.original_name}
            getOptionSelected={(option, value) => option.original_name === value.original_name}
            renderInput={(params) => (
              <TextField 
                {...params} 
                name="creative" 
                label="Select creative"
  
                error={touched.creative && !!errors.creative}
                helperText={touched.creative && errors.creative}
                
              />
            )}
          />
        );
      } else if(values.selectReportType === "Campaign Report"){

        return (
          <Autocomplete 
            id="campaign"
            value={values.campaign}
            options={campaigns}
            size="small"
            onChange={(e, value) => {
  
              setFieldValue("campaign", value);
              
            }}
            multiple
            getOptionLabel={option =>  option.campaign_name}
            getOptionSelected={(option, value) => option.campaign_name === value.campaign_name}
            renderInput={(params) => (
              <TextField 
                {...params} 
                name="campaign" 
                label="Select campaign"
  
                error={touched.campaign && !!errors.campaign}
                helperText={touched.campaign && errors.campaign}
                
              />
            )}
          />
        );
  
      } 


    } else if(role === "AgencyPartnerAdmin"){

      if(values.selectReportType === "Campaign Profit"){

        return (
          <Autocomplete 
            id="campaign"
            value={values.campaign}
            options={campaigns}
            size="small"
            onChange={(e, value) => {
  
              setFieldValue("campaign", value);
              
            }}
            multiple
            getOptionLabel={option =>  option.campaign_name}
            getOptionSelected={(option, value) => option.campaign_name === value.campaign_name}
            renderInput={(params) => (
              <TextField 
                {...params} 
                name="campaign" 
                label="Select campaign"
  
                error={touched.campaign && !!errors.campaign}
                helperText={touched.campaign && errors.campaign}
                
              />
            )}
          />
        );

      }

    }
  }

  useEffect(() => {
    getDataForReports();
  }, []);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        startIcon={<Assignment />}
        className={classes.margin}
      >
        Generate Report
      </Button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Create new reports</h2>
            <div>

              
            </div>
            <Paper style={{width: "50vw", padding: "1rem" }}>
              <Formik
                enableReinitialize={true}
                initialValues={formData}

                validationSchema={Yup.object().shape({

                  selectReportType : Yup.string().required("Select report type"),
                  screen : Yup.array().when('selectReportType', {
                    is : 'Screen',
                    then : Yup.array().required("Select screen").nullable(),
                    otherwise : Yup.array().nullable()
                  }),
                  creative : Yup.array().when('selectReportType', {
                    is : 'Creative',
                    then : Yup.array().required("Select creative").nullable(),
                    otherwise : Yup.array().nullable()
                  }),
                  campaign : Yup.array().when('selectReportType', {
                    is : 'Campaign',
                    then : Yup.array().required("Select campaign").nullable(),
                    otherwise : Yup.array().nullable()
                  }),
                  advertiserCampaign : Yup.array().when('selectReportType', {
                    is : '"Publisher Monetization Report"',
                    then : Yup.array().required("Select campaign").nullable(),
                    otherwise : Yup.array().nullable()
                  }),
                  from : Yup.date().required("Start date is required"),
                  to : Yup.date().when("from", (from, schema) => {
                    return from && schema.min(from, "End date must be greater than Start Date")
                  }).required('End date is required')
                })}

                onSubmit={(data, props) => {

                  let values;
                  // console.log("Data :", data);

                  if(data.selectReportType === "Screen Log Report"){

                    let obj = {
                      screens : data.screen,
                      start_date : data.from,
                      end_date : data.to
                    }

                    // values = data.screen.map(s => s.screen_name);
                    // generateReport("screen", values, data.from, data.to);
                    screenLogReport(obj);
                    handleClose();

                  }else if(data.selectReportType === "Creative Log Report"){

                    let obj = {
                      creatives : data.creative,
                      start_date : data.from,
                      end_date : data.to
                    }

                    // values = data.creative.map(c => c.original_name);
                    // generateReport("content", values, data.from, data.to);
                    contentLogReport(obj);
                    handleClose();
                    
                  }else if(data.selectReportType === "Campaign Report"){

                    let obj = {
                      campaign : data.campaign,
                      start_date : data.from,
                      end_date : data.to
                    }
                    
                    getCampaignReport(obj);
                    handleClose();
                  } else if(data.selectReportType === "Campaign Profit"){

                    let obj = {
                      campaign : data.campaign,
                      start_date : data.from,
                      end_date : data.to
                    }
                    
                    getCampaignProfitMarginReport(obj);
                    handleClose();
                  } else if(data.selectReportType === "Publisher Monetization Report"){

                    let obj = {
                      campaign : data.advertiserCampaign,
                      start_date : data.from,
                      end_date : data.to
                    }
                    
                    publisherMonetizationReport(obj);
                    handleClose();
                  }

                  // console.log("Values :", values);
                  // console.log("data :", data);


                }}

              >
                {({ values, errors, touched, setFieldValue }) => (
                  <Form>
                    <Grid container spacing={4}>

                      
                      <Grid item md={6}>
                        <FormControl className={classes.formControl} fullWidth error={errors.selectReportType && touched.selectReportType}>
                          <InputLabel id="selectReportType"> Select report type </InputLabel>
                          <Select
                            labelId="selectReportType"
                            id="selectReportType"
                            value={values.selectReportType}
                            onChange={(e)=> {
                              setFieldValue('selectReportType', e.target.value);
                              console.log("Selected :", e.target.value);
                            }}
                          >
                            {selectTypes()}

                          </Select>
                          <FormHelperText>{touched.selectReportType && errors.selectReportType}</FormHelperText>
                        </FormControl>
                    
                      </Grid>

                      <Grid item md={6}>

                        { values.selectReportType !== "" ?  getFormFields({ values, errors, touched, setFieldValue })   : null }

                      </Grid>

                      <Grid item md={6}>
                        <TextField
                          id="from"
                          label="From"
                          type="date"
                          name="from"
                          onChange={(e) => {

                            setFieldValue('from', e.target.value);
                          
                          }}
                          // defaultValue="2017-05-24"
                          defaultValue={values.from}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={touched.from && !!errors.from}
                          helperText={touched.from && errors.from}
                        />
                      </Grid>

                      <Grid item md={6}>
                        <TextField
                          id="to"
                          label="To"
                          name="to"
                          type="date"
                          onChange={(e) => {
                            
                            setFieldValue('to', e.target.value);

                          }}
                          // defaultValue="2017-05-24"
                          defaultValue={values.to}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={touched.to && !!errors.to}
                          helperText={touched.to && errors.to}
                        />
                      </Grid>

                      <Grid item md={6}>
                        <Button type="submit" variant="contained" color="primary"> Submit </Button>
                      </Grid>

                    </Grid>

                  </Form>
                )}
              </Formik>

            </Paper>
          </div>
        </Fade>
      </Modal>
      
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    screens : state.reports.screens,
    creatives : state.reports.creatives,
    campaigns : state.reports.campaigns,
    advertiserCampaigns : state.reports.advertiserCampaigns,
    role : state.auth.user.role
  }
}

export default connect(mapStateToProps, {
  getDataForReports, generateReport, getCampaignReport, getCampaignProfitMarginReport, publisherMonetizationReport,
  screenLogReport, contentLogReport
})(NewReportModal);
