import * as Yup from "yup";
import { sub } from "date-fns";


let minDate = new Date(sub(new Date(), { minutes : 30 }));

export default Yup.object().shape({

  campaign_name : Yup.string().required("Campaign name is required"),
  campaign_type : Yup.string().required("Campaign type is required").nullable(),
  weather : Yup.string().nullable(),
  start_date : Yup.date().min(minDate, "Start date can't be less than today").required("Please select Start date"),
  end_date: Yup.date().when('start_date', (start_date, schema) => {
    // console.log("Start_date :", start_date);
    // console.log("schema_:", schema);
    return start_date && schema.min(start_date, "End Date Must be greater than Start Date")
    }).required("Please Select End Date"),
  schedule_type: Yup.string().oneOf(['RUN_ALL_TIME', 'RUN_ON_SCHEDULE']).required("Schedule type is required"),
  schedule : Yup.array().when('schedule_type', {
    is : value => value === "RUN_ON_SCHEDULE",
    then : Yup.array().required("Select the time slots"),
    otherwise : Yup.array()
  }),
  select_location_type : Yup.string().oneOf(['CHOOSE_FROM_SAVED_LOCATIONS', 'SELECT_FROM_MAP']).required("Select the option"),
  screens : Yup.object().when('select_location_type', {
    is : (val) => val === "CHOOSE_FROM_SAVED_LOCATIONS",
    then : Yup.object().required("Select from locations").nullable(),
    otherwise : Yup.object().nullable()
  }),

  screens_from_map : Yup.array().when("select_location_type", {
    is : (val) => val === "SELECT_FROM_MAP",
    then : Yup.array().required("Select the locations"),
    otherwise : Yup.array()
  }),
  creatives: Yup.array().required('No creatives selected from list'),
  campaign_budget : Yup.number().positive('Campaign Budget must be a positive number').required("Campaign Budget is required"),
  daily_budget_limit : Yup.number().positive('Daily Budget must be a positive number'),
  impressions : Yup.number().positive('Impression must be a positive number'),
  daily_impression_limit: Yup.number().positive('Daily impressions must be a positive number'),
  frequency : Yup.number().positive('Frequency must be a positive number'),
  floor_cpm : Yup.number().positive('Floor CPM must be a positive number'),
  bid_price : Yup.number().positive('Big price must be a positive number')
});