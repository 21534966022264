import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button, TextField } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { AccessTime } from "@material-ui/icons";
import Fade from '@material-ui/core/Fade';
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

function UpdateDuration({ data, updateDuration }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div>
      <Button type="button" onClick={handleOpen} variant="contained" color="primary">
        <AccessTime />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Update duration</h2>
            <div>
              <Formik
                initialValues={{ duration: data.duration }}
                validationSchema={Yup.object().shape({
                  duration : Yup.number().required("Duration is required")
                })}
                onSubmit={(formData, formikProps) => {

                  let obj = {
                    original_name : data.original_name,
                    _id : data._id,
                    updatedDuration : formData.duration
                  }

                  updateDuration(obj, () => {
                    formikProps.resetForm();
                    handleClose();
                  });
                  
                }}
              >
                {({ values, errors, touched }) => (
                  <Form>
                    <Field
                      as={TextField}
                      value={values.duration}
                      name="duration"
                      variant="outlined"
                      label="Duration"
                      error={errors.duration && touched.duration}
                      helperText={touched.duration && errors.duration}
                    />
                    <Button type="submit" color="primary" variant="contained" className={classes.margin} >Submit</Button>
                  </Form>
                )}
              </Formik>

            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default UpdateDuration;
