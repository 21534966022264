import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button, CardMedia, Card } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { Cancel, Image, PlayCircleFilledOutlined } from "@material-ui/icons";
// import { createMuiTheme, withStyles, ThemeProvider } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: "80vh"
  },
  margin: {
    margin: theme.spacing(1),
  },

  anchor: {
    textDecoration: "none"
  },
  cover : {
    width: 151
  }
}));

function CreativePreview({data}) {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [fileData, setFileData] = useState({
    file: '',
    fileName: ''
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const previewCreative = (data) => {
    const {file_type, file_url} = data;

    if(file_type === 'image/jpeg' || file_type === 'image/jpg' || file_type === 'image/png'){

        return <img src={file_url} alt="Image" style={{ height : '70vh' }}/>
    } else if(file_type === 'video/mp4') {

        return <video src={file_url} style={{ height : '70vh' }} autoPlay></video>
    } else {
        return <h3>Html</h3>
    }
  }

  const showButton = (data) => {
    const {file_type, file_url} = data;

    if(file_type === 'image/jpeg' || file_type === 'image/jpg' || file_type === 'image/png'){

        // return <Button type="button" onClick={handleOpen} variant="contained" color="primary">
        //     Image
        // </Button>
      return (
        <img
          src={`${file_url}`}
          onClick={handleOpen}
          // width={141}
          style={{ width : '141px', cursor : "pointer" }}
        />
      )

    } else if(file_type === 'video/mp4') {

        // return <Button type="button" onClick={handleOpen} variant="contained" color="primary" startIcon={<PlayCircleFilledOutlined />}>
        //     Video
        // </Button>

      return(
        <video src={file_url} style={{ width : '141px', cursor : "pointer" }} onClick={handleOpen}></video>
      )
    }
  }


  return (
    <div>
      {showButton(data)}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>

            {previewCreative(data)}
            <div style={{textAlign : "center"}}>
                <Button type="button" onClick={handleClose} variant="contained" color="secondary" startIcon={<Cancel/>}>
                    Close
                </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default CreativePreview;