import React from 'react';
import {Route, Switch} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./store";
import setAuthToken from "./Utils/setAuthToken";
import { setCurrentUser } from "./Actions/authActions"
import jwt_decode from "jwt-decode";
import Routes from "./Components/Routes/Routes";
import './App.css';



const App = () =>{

  // useEffect(() => {
    
    // if(localStorage.token){
    //   setAuthToken(localStorage.token);
    //   const decoded = jwt_decode(localStorage.token);
    //   store.dispatch(setCurrentUser(decoded));
    // }

  // }, [])

  if(localStorage.token){
    setAuthToken(localStorage.token);
    const decoded = jwt_decode(localStorage.token);
    const {role, id, company_name} = JSON.parse(localStorage.getItem("userData"));
    store.dispatch(setCurrentUser({decoded, role, id, company_name}));
  }

  return (

    <Provider store={store}>
      <Switch>
        <Route component={Routes}/>
      </Switch>
    </Provider>
  );
}

export default App;
