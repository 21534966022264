import React, { useEffect } from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from "@material-ui/core";
import { Visibility, ArrowForward } from "@material-ui/icons";
import { Link as LinkRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import {
  fetchDOOHCampaignsForApproval
} from "../../Actions/campaignActions";
import ShowCampaignSchedule from "../Campaign/ShowCampaignSchedule";

// import Virtual from "./Virtual";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));


function CampaignApproval(props) {
  
  const { fetchDOOHCampaignsForApproval, campaigns }= props;
  const classes = useStyles();
  // const { fetchCampaignLocationList, locationList } = props;

  useEffect(() => {
    fetchDOOHCampaignsForApproval();
    // fetchScreensToEditCampaignScreens();

  }, []);

  return (
    <div>

      <MaterialTable
        title="DOOH Campaigns Approvals"
        columns={[
          { title: "Name", headerStyle: { fontWeight: "bold" }, field: 'campaign_name' },
          { title: "Status", headerStyle: { fontWeight: "bold" }, field: 'campaign_status' },
          { title : "Screen Count", headerStyle: { fontWeight: "bold" }, render : rowData => {
            let count = 0
            // console.log("row",rowData)
            rowData.screens.forEach(screen => {
              count += screen.screen.screen_count;
            });

            return count;
          }},
          {title : "Campaign Approval", headerStyle: { fontWeight: "bold" }, render : rowData => {

            return (<Button
              key={rowData._id} 
              variant="contained" 
              component={LinkRouter}
              startIcon={<Visibility />} 
              to={{
                  pathname: `/campaignApproval/${rowData._id}/screens`,
                  state : rowData
              }}
              color="primary">view</Button>)
          }},
          {title : "Creatives", headerStyle: { fontWeight: "bold" }, render : rowData => {

            return (<Button
              key={rowData._id} 
              variant="contained" 
              component={LinkRouter}
              // startIcon={<Visibility />} 
              to={{
                  pathname: `/campaignApproval/${rowData._id}/creatives`,
                  state : rowData
              }}
              color="primary"><ArrowForward /></Button>)
          }},
          {
            title: "Start Date", headerStyle: { fontWeight: "bold" }, render: rowData => {
              return `${new Date(rowData.start_date).toDateString()}`
            }
          },
          {
            title: "End Date", headerStyle: { fontWeight: "bold" }, render: rowData => {
              return `${new Date(rowData.end_date).toDateString()}`
            }
          },
          {
            title: "Schedule", headerStyle: { fontWeight: "bold" }, render: rowData => {

              // console.log(rowData.schedule);
              let interval = rowData.schedule.map( d => {
                return {
                  start : moment(new Date(new Date(d.start).toISOString())),
                  end : moment(new Date(new Date(d.end).toISOString())),
                  value : ""
                }
              });

              // console.log(interval);
              return <ShowCampaignSchedule data={interval} />
            }
          },
          {
            title: "Created on", headerStyle: { fontWeight: "bold" }, render: rowData => {
              return `${new Date(rowData.createdAt).toDateString()}`
            }
          },
          // { title : "Update", headerStyle: { fontWeight: "bold" }, render : rowData => {
          //   return <UpdateDOOHCampaign data={rowData} allScreens={screensForEdit}/>
          // }},
          // { title : 'Remove', headerStyle: { fontWeight: "bold" }, render : rowData => {
          //     return <RemoveDOOHCampaign data={rowData} />
          // }}
        ]}

        data={campaigns}
        
        options={{
          pageSize : 10,
          pageSizeOptions : [20, 50, 100]
        }}

        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar  {...props} />

              <div>
                <Grid container className={classes.margin}>
                  <Grid item>
                    {/* <Button
                      variant="contained"
                      color="primary"
                      component={LinkRouter}
                      to={`/campaign/create/dooh`}
                      className={classes.margin}
                      startIcon={<Add />}
                    >
                      Add
                    </Button> */}
                    
                  </Grid>

                  {/* <Grid item>
                    <SharedCampaignModel/>
                  </Grid> */}
                </Grid>
              </div>
            </div>
          )
        }}
      />
      {/* <Virtual screens={screensForEdit}/> */}
    </div>
  )
}

const mapStateToProps = (state) => {
  
  return {
    campaigns : state.campaign.approvalCampaigns,
    // screensForEdit : state.campaign.screensForDOOHScreenEdit
  }
}

export default connect(mapStateToProps, {fetchDOOHCampaignsForApproval})(CampaignApproval);