import {
  FETCH_ALL_CAMPAIGNS_ADMIN, SET_SCREENS_FOR_MARKUP
} from "../Actions/types";


const initState = {
  campaigns : [],
  screensForMarkup : []
}

export default (state=initState, action) => {
  
  const { type, payload } = action;

  switch(type){

    case FETCH_ALL_CAMPAIGNS_ADMIN:
      return {
        ...state,
        campaigns : [...payload]
      }

    case SET_SCREENS_FOR_MARKUP:
      return {
        ...state,
        screensForMarkup : payload
      }

    default:
      return state;
  }

}