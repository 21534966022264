import React, { useState, useEffect} from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
// import { Add } from "@material-ui/icons";
import { Link as LinkRouter } from "react-router-dom";
import ChangeScreenApproval from "./ChangeScreenApprovalButtons"
import ChangeCPMApproval from "./ChangeCpmApprovalButton";
import { fetchDOOHCampaignsForApproval } from "../../../Actions/campaignActions";
import { getSubUsers } from "../../../Actions/profileActions";
import { connect } from "react-redux";

import ReviseCampaignMarkup from "./reviseCampaignMarkupByAgency";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));


const CampaignScreensForApproval = (props) => {

  // const [ camp , setCamp ] = useState({});

  const classes = useStyles();
  const { campaign_name, _id } = props.location.state;
  const { fetchDOOHCampaignsForApproval, campaigns, getSubUsers} = props;

  const { state : campaign  } = props.location;

  const {user_id} = props;
  const { id } = props.match.params;

  useEffect(() => {
    fetchDOOHCampaignsForApproval();
    getSubUsers();
  }, []);
  // console.log("props", user_id);

  const getScreens = () => {
    const campaign = campaigns.find(camp => camp._id === id);
    // console.log("Found", campaign);
    if(campaign) {
      // const screens = campaign.screens.filter(screen => screen.user_id === user_id );
      const screens = campaign.screens.filter(screen => user_id.includes(screen.user_id) );
      return screens;
    } else {
      return [];
    }
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        component={LinkRouter}
        to={`/campaignApprovalAgency`}
        className={classes.margin}
      >
        < ArrowBackIos />Back
      </Button>
      <MaterialTable
        title={`${campaign_name} Campaign's Screens`}
        columns={[
          { title: "Screen Name", headerStyle: { fontWeight: "bold" }, field: 'screen.screen_name' },
          { title: "Location", headerStyle: { fontWeight: "bold" }, field: 'screen.location' },
          { title: "Approval Status", headerStyle: { fontWeight: "bold" }, field: 'isApproved' },
          { title : "Publisher", headerStyle: { fontWeight: "bold" }, field : 'screen.network_name' },
          // { title : "Network", headerStyle: { fontWeight: "bold" }, field : "screen.network"},
          { title: "Publisher CPM", headerStyle: { fontWeight: "bold" }, field : "publisherCPM"},
          { title: "Advertiser CPM", headerStyle: { fontWeight: "bold" }, field : "advertiserCPM"},
          { title: "Proposed Publisher CPM", headerStyle: { fontWeight: "bold" }, field : "proposedPublisherCPM"},
          { title: "CPM Approved (Agency)", headerStyle: { fontWeight: "bold" }, field : "isCpmApprovedByAgency", type : "boolean"},
          { title: "CPM Approved (Publisher)", headerStyle: { fontWeight: "bold" }, field : "isCpmApprovedByPublisher", type : "boolean"},
          {title :"Edit CPM Status", field : "",  headerStyle : { fontWeight : "bold" }, render: rowData => {
            // console.log("RowData :", rowData);
            return <ChangeCPMApproval data={{...rowData, camp_id : id}}/>
          }},
          {title :"Go live", field : "",  headerStyle : { fontWeight : "bold" }, render: rowData => {
            // console.log("RowData :", rowData);
            return <ChangeScreenApproval data={{...rowData, camp_id : id}}/>
          }},
         
        ]}

        // data={[...screens.filter(s => s.screen.user_id === id)]}
        data={getScreens()}
        options={{
          pageSize : 10,
          pageSizeOptions : [20, 50, 100]
        }}

        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar  {...props} />

              <div>
                <Grid container>
                  <Grid item>
                    <ReviseCampaignMarkup campaign={campaign} />
                  </Grid>
                </Grid>
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user_id : state.profile.subUsers.filter(user => user.role === "Media Owner").map(user => user._id),
    campaigns : state.campaign.approvalCampaigns
  }
}

export default connect(mapStateToProps, {fetchDOOHCampaignsForApproval, getSubUsers})(CampaignScreensForApproval);
// export default CampaignScreensForApproval;