import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal, Button, TextField, Grid, FormControl, FormHelperText
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { Save } from "@material-ui/icons";
// import { createMuiTheme, withStyles, ThemeProvider } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import { connect } from "react-redux";
import { addCampaignLocationList } from "../../Actions/campaignActions";
import { Formik, Form, Field  } from "formik";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

function SaveLocationList(props) {

  const { data, addCampaignLocationList, clearTable } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [formData , setFormData] = useState({
    data,
    name : ""
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {

    setFormData({
      ...formData,
      data
    });
  }, [data]);

  return (
    <React.Fragment>
      <Button type="button" onClick={handleOpen} variant="contained" color="primary" className={classes.margin} startIcon={<Save/>}>
        Save Locations to list
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Save selected locations for Campaign</h2>
            <div>

              <Formik

                initialValues={formData}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                  name : Yup.string().required("Name is required"),
                  data : Yup.array().required("No sites selected from inventory")
                })}
                onSubmit={(formValues, formikProps) => {

                  addCampaignLocationList(formValues.name, data, () => {

                    handleClose();
                    clearTable();
                    
                    props.push("/savedlist");

                    
                  })
                  // console.log("FormValues :", formValues);
                  formikProps.resetForm();
                }}
              >
                {({ values, errors, touched}) => (
                  <Form>
                    <Grid container spacing={2}>

                      <Grid item md={6} md={12}>
                        
                        <Field
                          as={TextField}
                          name="name"
                          variant="outlined"
                          label="Enter list name"
                          value={values.name}
                          size="small"
                          helperText={touched.name && errors.name}
                          error={errors.name && touched.name }
                          fullWidth
                        />

                        <FormControl error={!!errors.data}>
                          <FormHelperText >{errors.data}</FormHelperText>
                        </FormControl>
                      </Grid>

                      
                      <Button 
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.margin}
                      >
                        Save
                      </Button>

                    </Grid>

                  </Form>
                )}
              </Formik>
                
            </div>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}

export default connect(null,{addCampaignLocationList})(SaveLocationList);