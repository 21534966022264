import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { Backup, GetApp } from "@material-ui/icons";
// import { createMuiTheme, withStyles, ThemeProvider } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import { connect } from "react-redux";
import { fetchScreens, addScreensWithExcel} from "../../../Actions/screensActions";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none"
  },
  margin: {
    margin: theme.spacing(1),
  },

  anchor: {
    textDecoration: "none"
  }
}));

function ScreenExcelUpload(props) {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [fileData, setFileData] = useState({
    file: '',
    fileName: ''
  });

  const onChange = (e) => {
    setFileData({
      file: e.target.files[0],
      fileName: e.target.files[0].name
    });
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', fileData.file);

    props.addScreensWithExcel(formData, () => {
      props.fetchScreens();
      handleClose();
    });

  }

  return (
    <div>
      <Button type="button" onClick={handleOpen} variant="contained" color="primary" className={props.class} startIcon={<Backup />}>
        Excel Upload
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <p>Please use this excel data template to fill and upload.

            <a
                href="https://screenodata.s3-ap-southeast-1.amazonaws.com/ScreenUploadTemplate/template.xlsx"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.anchor}
              >
                <Button
                  variant="contained"
                  color="default"
                  className={classes.margin}
                  startIcon={<GetApp />}
                >
                  Template
              </Button>
              </a>

            </p>
            <div>
              {/* <Button
                    variant="contained"
                    className={classes.margin}
                    color="primary"
                    startIcon={<Backup/>}
                >
                    Upload
                </Button> */}

              {/* <Button
                    variant="contained"
                    color="secondary"
                    className={classes.margin}
                    onClick={handleClose}
                >
                    Cancel
                </Button> */}

              <form onSubmit={onSubmit}>

                <input
                    type="file"
                    onChange={onChange}
                  />
                <div>
                  {/* <label>Upload Excel </label> */}

                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.margin}
                    color="primary"
                    startIcon={<Backup/>}
                  >
                    Upload
                  </Button>
                  
                </div>
              </form>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default connect(null, {fetchScreens, addScreensWithExcel})(ScreenExcelUpload);