import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { fetchAllCreativeAdmin } from "../../../Actions/AdminCreativeActions";
import MaterialTable, { MTableToolbar } from 'material-table';
import { Typography, Chip } from "@material-ui/core";
import { makeStyles} from '@material-ui/core/styles';
import EditApprovalStatus from "./EditAppovalStatus";
import CreativePreview from "./CreativePreview";
import StatusChip from "../../Common/StatusChip";
// import CreativeUpload from "./CreativeUpload";


const AdminCreativeTable = ({fetchAllCreativeAdmin, creatives}) => {

    const ApprovalStatus = (rowData) => {

        let { approval_status } = rowData;

        if(approval_status === "Pending"){
            return <StatusChip bColor="yellow" label={approval_status} />

        } else if(approval_status === "Approved"){
            return <StatusChip bColor="green" label={approval_status} />

        } else if(approval_status === "Disapproved") {
            return <StatusChip bColor="red" label={approval_status} />
        }

    }

    useEffect(() => {
      fetchAllCreativeAdmin();
    }, []);

    // console.log("cretiv", history);

    return (
        <div style={{maxWidth : "100%"}}>

            <MaterialTable 
                title="Creatives"

                columns={[
                    {title :"Preview",  headerStyle : { fontWeight : "bold" }, render : rowData => {
                        return <CreativePreview data={rowData} />
                    }},
                    {title :"File Name", field : "original_name",  headerStyle : { fontWeight : "bold" }},
                    {title :"Type", field : "file_type",  headerStyle : { fontWeight : "bold" }},
                    {title :"File Size",  headerStyle : { fontWeight : "bold" }, render : rowData => {
                        return `${parseInt(rowData.file_size/1024)} KB`;
                    }},
                    {title :"Approval",  headerStyle : { fontWeight : "bold" }, render : ApprovalStatus},
                    {title :"Uploaded on",  headerStyle : { fontWeight : "bold" }, render : rowData => {
                        return `${new Date(rowData.created_at).toDateString()}`;
                    }},
                    {title :"Edit Status", field : "",  headerStyle : { fontWeight : "bold" }, render: rowData => {
                        return <EditApprovalStatus data={rowData}/>
                    }},

                ]}

                data={[...creatives]}
            />
            
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        creatives : state.creativeAdmin
    }
}

export default connect(mapStateToProps, { fetchAllCreativeAdmin })(AdminCreativeTable);
