import React, { useState } from 'react';
import { Marker, InfoWindow } from "react-google-maps";
import { Grid } from "@material-ui/core";
import CardMarker from "./CardMarker";

function CustomMarker(props) {

  const [ isOpen , setIsOpen ] = useState(false);
  const { position , icon, marker, values, setFieldValue } = props;
  // console.log("Marker :", marker);

  
  return (
    <div>
      <Marker
        // key={key}
        position={position}
        icon={icon}
        onClick={() => setIsOpen(!isOpen)}
      >

        { isOpen && <InfoWindow onCloseClick={() => setIsOpen(!isOpen)}>
          <Grid container spacing={2}>

            <CardMarker 
              marker={marker} 
              values={values}
              setFieldValue={setFieldValue}
            />

          </Grid>
        </InfoWindow> }

      </Marker>
    </div>
  )
}

export default CustomMarker
