import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { connect } from "react-redux";
import { removeAlert } from "../../Actions/alertActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin : "0 auto",
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function Alerts({alerts, removeAlert}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <Alert severity="error">This is an error alert — check it out!</Alert> */}
      {/* <Alert severity="error">This is an error alert — check it out!</Alert> */}
      { alerts !== null && alerts.length > 0 && alerts.map(alert => (
        <Alert severity={alert.alertType} key={alert.id} onClose={() => removeAlert(alert.id)}>{alert.msg}</Alert>
      ))}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    alerts : state.alert
  }
}

export default connect(mapStateToProps, {removeAlert})(Alerts);
