import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom';
import {connect} from "react-redux";
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container';
import {login} from "../../Actions/authActions";
import Alert from "../Common/Alert";


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://screeno.locad.net/">
                Screeno
      </Link>{' '}
            {new Date().getFullYear()}
            {''}
        </Typography>
    )
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))


const SignIn = ({login, history}) => {
    
    const classes = useStyles();

    const [formData, setFormData] = useState({
        email : '',
        password: ''
    });


    const onChange = e => {
        setFormData({...formData, [e.target.name] : e.target.value});
    }

    const onSubmit = async e => {
        e.preventDefault();
        
        login(formData, () => {
            history.push("/");
        });
    }


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                <form className={classes.form} noValidate onSubmit={onSubmit}>
                    <TextField
                        // error={isLoginError}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        // helperText={loginError}
                        autoFocus
                        onChange={onChange}
                        // onChange={(e) => handleOnChange(e.target.value, e.target.name)}
                    />
                    <TextField
                        // error={isLoginError}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={onChange}
                        // onChange={(e) => handleOnChange(e.target.value, e.target.name)}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        // onClick={() => dispatch(actions.signIn(values))}
                    >
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                        </Link>
                        </Grid>
                        <Grid item>
                            <Link component={RouterLink} to="#" variant="body2" href="#" className={classes.link}>
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
                <Alert />
            </div>
            
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    )
}

export default connect(null, {login})(SignIn);