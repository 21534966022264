import React, { useEffect } from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
// import { Add, Visibility } from "@material-ui/icons";
import { getSubUsers } from "../../Actions/profileActions";
import AddUser from "./AddSubUser";
import RemoveSubUser from "./RemoveClientModal";

// const useStyles = makeStyles((theme) => ({
//   margin: {
//     margin: theme.spacing(1),
//   },
// }));


const SubUserTable = (props) => {
  const { getSubUsers, subUsers } = props;

  useEffect(() => {
    getSubUsers();
  }, []);

  return (
    <div style={{ width : "50vw" }}>
      <MaterialTable

        title="Sub Users"
        columns={[
          { title : "Name", field : "name", headerStyle: { fontWeight: "bold" }},
          { title : "Company Name", field : "company_name", headerStyle: { fontWeight: "bold" }},
          { title : "E-mail", field : "email", headerStyle: { fontWeight: "bold" }},
          { title : "Role", field : "role", headerStyle: { fontWeight: "bold" }},
          { title : "Edit", headerStyle: { fontWeight: "bold" }, render : rowData => {
            return <RemoveSubUser data={rowData}/>
          }}

        ]}

        data={[...subUsers]}
        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar  {...props} />

              <div>
                <Grid container>
                  <Grid item>
                    <AddUser />
                  </Grid>
                </Grid>
              </div>
            </div>
          )
        }}

      />
      
    </div>
  )
}

const mapStateToProps = (state) => {

  // console.log("Users :", state.profile);
  return {
    subUsers : state.profile.subUsers
  };
}

export default connect(mapStateToProps, { getSubUsers })(SubUserTable)