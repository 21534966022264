import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button, Grid } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { CameraAlt, CloudDownload, Height } from "@material-ui/icons";
// import { createMuiTheme, withStyles, ThemeProvider } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import { unpairScreen } from "../../../Actions/screensActions";
import { connect } from "react-redux";
import mqttClient from "../../../Utils/mqtt_config";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none"
  },
  margin: {
    margin: theme.spacing(1),
  },
  date: {
    margin: theme.spacing(1),
    textAlign : "left"
  },

  modalDiv : {
    width : "70vw",
    textAlign : "center"
  },

  imageVertical : {
    height : "70vh"
  },

  imageHorizontal : {
    width : "70vw"
  },

  imageSquare : {
    width : "65vw",
    height : "65vh",
  }
}));

function UnpairScreenModal({ data, unpairScreen }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [ imageType, setImageType ] = useState("Horizontal");
  const [screenshotData, setScreenshotData] = React.useState({
    screenshot_url : "",
    date : ""
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleScreenShot = () => {

    mqttClient.publish(`cmd_${data.screen_name}_${data.user_id}`, JSON.stringify({ "message": { "cmd": "screen_shot" } }));
    mqttClient.subscribe(`cmd_screenshot_${data.user_id}_${data.screen_name}`);

    // handleOpen(); // For Testing

    mqttClient.on('message', (topic, message) => {
      let dataS = JSON.parse(message.toString());

      if (topic === `cmd_screenshot_${data.user_id}_${data.screen_name}`) {
        if (dataS.screenshot) {

          // console.log("Date :", dataS.screenshot.date);
          imageDimensionCalulation(dataS.screenshot.screenshot_url);
          // this.setState({ screenshot_status: true, screenshot_url: data.screenshot.screenshot_url, date: data.screenshot.date, modal: true });
          setScreenshotData({
            ...screenshotData,
            date : dataS.screenshot.date,
            screenshot_url : dataS.screenshot.screenshot_url
          });

          handleOpen();
        }
      }

    });

  }

  const handleDownload = () => {
    window.open(screenshotData.screenshot_url);
  }

  

  function imageDimensionCalulation (url){

    let img = new Image();

    img.onload = function(){
      // console.log("Height",img.height);
      // console.log("Width",img.width);

      if(img.height > img.width){

        setImageType("Vertical");

        // return <img src={screenshotData.screenshot_url} alt="preview" className={classes.imageVertical}/>

      } else if(img.height < img.width) {
        
        setImageType("Horizontal");

        // return <img src={screenshotData.screenshot_url} alt="preview" className={classes.imageHorizontal}/>

      } else {

        // return <img src={screenshotData.screenshot_url} alt="preview" className={classes.imageSquare}/>
        setImageType("Square");

      }

    }
    img.src = url;
  }

  function returnClassForImage () {
    if(imageType === "Horizontal"){

      return classes.imageHorizontal;
    } else if(imageType === "Vertical"){

      return classes.imageVertical;
    } else {
      
      return classes.imageSquare;
    }
  }


  return (
    <div>
      <Button type="button" onClick={handleScreenShot} variant="contained" color="primary">
        <CameraAlt />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.modalDiv}>
            {/* <div className={returnClassForImage()}> */}
              <img src={screenshotData.screenshot_url} alt="preview" className={returnClassForImage()}/>
              <Grid container spacing={2}>
                <Grid item md={2}>
                  <Button
                    onClick={handleDownload}
                    variant="contained"
                    color="primary"
                    className={classes.margin}
                  >
                    <CloudDownload />
                    
                  </Button>
                </Grid>
                <Grid item md={10}>
                  <div className={classes.date}>{screenshotData.date}</div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default connect(null, { unpairScreen })(UnpairScreenModal);