import axios from "axios";
import {
    REGISTER_SUCCESS, REGISTER_FAIL, SET_CURRENT_USER
} from "./types";
import { rootURL } from "../Utils/config";
import jwt_decode from "jwt-decode";
import setAuthToken from "../Utils/setAuthToken";
import { setAlert } from "./alertActions";


// Register User 
export const register = (data, cb) => async dispatch => {
    const {email, company : company_name, role, password, password2 : confirm_password, name} = data;

    const user = {
        name,
        email,
        company_name,
        password,
        confirm_password,
        role
    }

    try {

        const res = await axios.post(`${rootURL}/api/signup`, {...user});
        // console.log(res.data);

        dispatch({
            type : REGISTER_SUCCESS,
            payload : res.data
        });

        cb();

    } catch (err) {

        // console.log(err.response.data);
        // console.log(err.response.status);

        if(err.response.status === 401){
            dispatch(setAlert("Invalid credentials", "error"));
        } else {

            Object.keys(err.response.data).forEach(r => {
                dispatch(setAlert(err.response.data[r], "error"));
            });
        }

    }
}

// Login user
export const login = (data, cb) => async dispatch => {

    try {

        const res = await axios.post(`${rootURL}/api/signin`, {...data});
        const {token} = res.data;

        // console.log("res :", res.data);

        // Setting token in localstorage
        localStorage.setItem('token', token);
        localStorage.setItem('userData', JSON.stringify(res.data));

        // Set Auth Header
        setAuthToken(token);

        const decoded = jwt_decode(token);
        const {role, id, company_name} = res.data;
        // console.log("decoded", decoded);

        dispatch(setCurrentUser({decoded, role, id, company_name}));

        cb();

    } catch (err) {
        // console.log(err.response.status);
        // console.log(err.response.data);
        // if(err.response.status === 401){
        //     dispatch(setAlert("Email or Password invalid", "error"));
        // } else {
        //     dispatch(setAlert("Invalid credentials", "error"));
        // }

        dispatch(setAlert("Invalid credentials", "error"));

    }
}

export const setCurrentUser = (decoded) => {
    // console.log("SetCurrentUser :", decoded);
    return { type: SET_CURRENT_USER, payload : decoded}
}

export const logoutUser = () => dispatch =>{
    //Remove token from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
  
    //Remove the auth Token;
    setAuthToken(false);
  
    // Set Current User to empty
    dispatch({
        type : "USER_LOGOUT",
        payload : false
    });
    dispatch(setCurrentUser({}));
    
    // dispatch({type : LOGOUT_USER});
  
  }