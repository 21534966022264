import React, { useState, useEffect } from 'react';
import { Button, Modal, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Edit } from "@material-ui/icons";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';
import { Formik, Field, Form } from "formik";
import moment from "moment-timezone";

let timeZones = moment.tz.names();
let offsetTmz=[];

for(let i in timeZones){
  offsetTmz.push(`(GMT${moment.tz(timeZones[i]).format('Z')}) ${timeZones[i]}`);
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none"
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const ProfileUpdateModal = (props) => {

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const { updateProfileData } = props;

  const { name, role, email, company_name, creativeList, defaultCreative, timezone } = props.user;

  const [formData, setFormData] = useState({
    name,
    oldPassword :"",
    newPassword :"",
    confirmPassword :"",
    role,
    email,
    company_name,
    defaultCreative,
    timezone
  });

  useEffect(() => {

    const { name, password, role, email, company_name, defaultCreative, timezone } = props.user;
    setFormData({
      ...formData,
      name,
      role,
      email,
      company_name,
      defaultCreative,
      timezone
    });

  }, [props.user])

  // console.log("Data :", formData);

  const handleOpen = () => {
    setOpen(true);

  }

  const handleClose = () => {
    setOpen(false);

  };


  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        startIcon={<Edit />}
      >
        Update
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Update Profile</h2>

            <Paper style={{ width: "50vw", padding: "1rem" }}>
              <Formik
                enableReinitialize={true}
                initialValues={formData}

                validationSchema={Yup.object().shape({
                  name : Yup.string().required("Name is Required"),
                  company_name : Yup.string().required("Company name is required"),
                  email : Yup.string().email(),
                  role : Yup.string(),
                  oldPassword : Yup.string(),
                  newPassword : Yup.string(),
                  confirmPassword : Yup.string().when('newPassword', {
                    is : val => (val && val.length > 0 ? true : false),
                    then : Yup.string().oneOf(
                      [Yup.ref('newPassword')],
                      'New Password and Confirm passowrd must match'
                    )
                  })

                })}

                onSubmit={async (data) => {
                  // console.log("ON Submit", data)
                  updateProfileData(data, ()=>{
                    handleClose();
                  });
                }}

              >
                {({ values, handleSubmit, errors, touched, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container spacing={4}>

                      <Grid item md={6}>
                        <Field
                          type="text"
                          name="name"
                          label="Name"
                          as={TextField}
                          fullWidth
                          error={touched.name && !!errors.name}
                          helperText={touched.name && errors.name}
                        />
                      </Grid>

                      <Grid item md={6}>
                        <Field
                          type="text"
                          name="company_name"
                          label="Company Name"
                          as={TextField}
                          fullWidth
                          error={touched.company_name && !!errors.company_name}
                          helperText={touched.company_name && errors.company_name}
                        />
                      </Grid>

                      <Grid item md={6}>
                        <Field
                          type="text"
                          name="email"
                          label="Email"
                          as={TextField}
                          fullWidth
                          disabled
                        />
                      </Grid>

                      <Grid item md={6}>
                        <Field
                          type="password"
                          name="oldPassword"
                          label="Current Password"
                          as={TextField}
                          fullWidth
                          error={touched.oldPassword && !!errors.oldPassword}
                          helperText={touched.oldPassword && errors.oldPassword}
                        />
                      </Grid>

                      <Grid item md={6}>
                        <Field
                          type="password"
                          name="newPassword"
                          label="New Password"
                          as={TextField}
                          fullWidth
                          error={touched.newPassword && !!errors.newPassword}
                          helperText={touched.newPassword && errors.newPassword}
                        />
                      </Grid>
                      
                      <Grid item md={6}>
                        <Field
                          type="password"
                          name="confirmPassword"
                          label="Confirm new Password"
                          as={TextField}
                          fullWidth
                          error={touched.confirmPassword && !!errors.confirmPassword}
                          helperText={touched.confirmPassword && errors.confirmPassword}
                        />
                      </Grid>

                      <Grid item md={6}>
                        <Field
                          type="text"
                          name="role"
                          label="Role"
                          as={TextField}
                          fullWidth
                          disabled
                        />
                      </Grid>

                      <Grid item md={6}>
                      
                        <Autocomplete
                          id="defaultCreative"
                          value={values.defaultCreative}
                          options={creativeList}
                          size="small"
                          onChange={(e, value) => {
                            // console.log(value);
                            setFieldValue('defaultCreative', value);
                          }}
                          getOptionLabel={(option) => option.original_name}
                          getOptionSelected={(option, value) => option.original_name == value.original_name}
                          // style={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} name="defaultCreative" label="Default Creative " />}
                          // loading={true}
                        />
                      </Grid>

                      <Grid item md={12}>

                        <Autocomplete
                          id="timezone"
                          value={values.timezone}
                          options={offsetTmz}
                          size="small"
                          onChange={(e, value) => {
                            // console.log(value);
                            setFieldValue('timezone', value);
                          }}
                          fullWidth
                          getOptionLabel={(option) => option}
                          // getOptionSelected={(option, value) => option.original_name == value.original_name}
                          // style={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} name="timezone" label="Select Timezone" />}
                          
                        />
                      </Grid>

                      <Grid item md={6}>
                        <Button type="submit" variant="contained" color="primary"> Submit </Button>
                      </Grid>

                    </Grid>

                  </Form>
                )}
              </Formik>

            </Paper>
          </div>
        </Fade>
      </Modal>

    </div>
  )
}

export default ProfileUpdateModal;
