import React from 'react';
import { useHistory  } from "react-router-dom";
import InventoryFilter from "./InventoryFilter";
import InventoryTable from "./InventoryTable";

function Inventory(props) {
    
    const { push  } = useHistory();
    return (
        <div>
            <InventoryFilter />
            <InventoryTable push={push}/>
        </div>
    )
}

export default Inventory;
