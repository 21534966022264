import {
     SET_CURRENT_USER
} from "../Actions/types";
import _ from "lodash";


const initialState = {
    isAuthenticated : false,
    user : {}
}

export default (state = initialState, action) => {

    // console.log(action.type);

    const { type, payload } = action;

    switch (type) {

        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated : !_.isEmpty(payload),
                user : {
                    ...state.user,
                    ...payload
                }
            }

        default:
            return state;
    }

}