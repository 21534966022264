import {
  GET_PROFILE, UPDATE_PROFILE, GET_SUB_USERS, ADD_SUB_USER, REMOVE_CLIENT
} from "./types";

import { rootURL } from "../Utils/config";
import axios from "axios";
import { setAlert } from "./alertActions";

export const getUserProfile = () => async dispatch => {

  try {

    const response = await axios.get(`${rootURL}/api/getUserProfile`);
    const creatives = await axios.get(`${rootURL}/api/getCreativeNamesForUser`);
    const { user } = response.data;

    // console.log("Suet", creatives.data);
    const payload = {
      name : user.name,
      company_name : user.company_name ? user.company_name : 'NA' ,
      email : user.email,
      role : user.role,
      defaultCreative : user.defaultCreative,
      creativeList: creatives.data.creatives,
      timezone : user.timezone
    }

    dispatch({
      type : GET_PROFILE,
      payload
    });

  }catch(err){
    console.log(err);
  }

}


export const updateProfileData = (data, cb) => async dispatch => {

  try {

    // console.log("Here");

    const response = await axios.post(`${rootURL}/api/userProfileUpdate`, {data});
    // console.log("Response :", response.data);
    const { user } = response.data;

    // console.log("Suet", creatives.data);
    const payload = {
      name : user.name,
      company_name : user.company_name ? user.company_name : 'NA' ,
      email : user.email,
      role : user.role,
      defaultCreative : user.defaultCreative,
      timezone : user.timezone
    }

    dispatch({
      type : UPDATE_PROFILE,
      payload
    });

    cb();

  } catch (err){

  }
}

export const getSubUsers = () => async dispatch => {

  const response = await axios.get(`${rootURL}/api/getSubUsers`);

  // console.log(response.data);

  dispatch({
    type : GET_SUB_USERS,
    payload : response.data.users
  });

}

export const addSubUser = (data, cb) => async dispatch => {

  try {

    const response = await axios.post(`${rootURL}/api/addSubUsers`, data,{
      headers : {
        'Content-Type' : 'multipart/form-data'
      }
    });
    
    // console.log("Data", response.data);
  
    dispatch({
      type : ADD_SUB_USER,
      payload : response.data.user
    });
    // dispatch(getSubUsers());
    cb();

  } catch(err){

    if(err.response.status === 401){
        dispatch(setAlert("Invalid credentials", "error"));
    } else {

        Object.keys(err.response.data).forEach(r => {
            dispatch(setAlert(err.response.data[r], "error"));
        });
    }
  }

  
}

export const removeClient = (id) => async dispatch => {

  try {

    const response = await axios.post(`${rootURL}/api/removeSubUser`, {id});

    dispatch({
      type : REMOVE_CLIENT,
      payload : id
    });

  } catch(err) {
    console.log(err)
  }


}

export const updateClientAccessMarkup = (data, cb) => async dispatch => {

  try{

    const response = await axios.post(`${rootURL}/api/updateClientAccess`, data, {
      headers : {
        'Content-Type' : 'multipart/form-data'
      }
    });

    cb();

  } catch(err) {
    console.log(err);
  }
} 