import React, { useEffect } from 'react';
import MaterialTable from "material-table";
import { makeStyles} from '@material-ui/core/styles';
import { fetchCampaignLocationList } from "../../../Actions/campaignActions";
import { connect } from "react-redux";
import RemoveDOOHList from "./DOOHListRemove";

function DOOHSavedListTable(props) {

  const { fetchCampaignLocationList, locationList } = props;

  useEffect(() =>{
    fetchCampaignLocationList();

  }, []);

  return (
    <div>
      <MaterialTable
        title="Save DOOH Locations"
        columns={[
          { title : "List Name", headerStyle : { fontWeight : "bold" }, field : 'name' },
          { title : "Locations", headerStyle : { fontWeight : "bold" }, render : rowData => {
            return `${rowData.locations.length}`;
          }},
          { title : "Created on", field : 'createdAt',headerStyle : { fontWeight : "bold" }, type: "datetime"},
          { title : "Remove", headerStyle : { fontWeight : "bold" }, render : rowData => {
            return <RemoveDOOHList data={rowData} />
          }}
        ]}

        data={locationList}
      />
    </div>
  )
}

const mapStateToProps = (state) => {

  return {
    locationList : state.campaign.locationList
  }
}

export default connect(mapStateToProps, {fetchCampaignLocationList})(DOOHSavedListTable);