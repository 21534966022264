import React, { useState, useEffect } from 'react';
import { 
   Modal, Grid, TextField, Button, useMediaQuery, ListSubheader, Checkbox
} from "@material-ui/core";
import {
  Edit, CheckBoxOutlineBlank, CheckBox
} from "@material-ui/icons";
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Autocomplete } from "@material-ui/lab";
import { Formik, Field, Form } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { VariableSizeList } from 'react-window';
import PropTypes from 'prop-types';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { updateDOOHCampaign, fetchApprovedCreatives } from "../../Actions/campaignActions";

const LISTBOX_PADDING = 8; // px

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}


// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none"
  },
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',   
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  endDatePicker: {
    margin: theme.spacing(1),
  },
  buttons: {
    margin: theme.spacing(1),
  }

}));

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const useStylesList = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});


const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];



function UpdateDoohCampaign(props) {

  const classes = useStyles();
  const classesList = useStylesList();
  const [open, setOpen] = useState(false);
  const { campaign, updateDOOHCampaign, allScreens, fetchApprovedCreatives, approvedCreatives } = props;
  const [ formData, setFormData ] = useState({
    end_date : campaign.end_date,
    campaign_status : campaign.campaign_status,
    screens : [...campaign.screens.map(s => s.screen)],
    creatives : [...campaign.creatives]
  });

  // console.log("Camapign :", campaign);
  

  useEffect(() => {

    setFormData({
      end_date : campaign.end_date,
      campaign_status : campaign.campaign_status,
      screens : [...campaign.screens.map(s => s.screen)],
      creatives : [...campaign.creatives]
    });

    // fetchApprovedCreatives();

  }, [campaign]);
  
  const statusOptions = ['Active', 'Inactive', 'Expired', 'Stop'];

  const handleOpen = () => {
    setOpen(true);

  }

  const handleClose = () => {
    setOpen(false);

  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        // startIcon={<Edit />}
        className={classes.margin}
      >
        <Edit />
      </Button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Update</h2>
            <Paper style={{width: "50vw", padding: "1rem" }}>

                <Formik 
                  initialValues={formData}
                  enableReinitialize={true}

                  validationSchema={Yup.object().shape({
                    end_date : Yup.date().min(new Date(new Date()), "End date can't be less than today").required("Please select Start date"),
                    campaign_status : Yup.string().oneOf(['Active', 'Inactive', 'Expired', 'Stop']).required("Campaign status is required").nullable(),
                    screens : Yup.array().required("Please select screens").nullable(),
                    creatives : Yup.array().required("Please select creatives").nullable(),
                  })}
                  
                  onSubmit={async (data) => {

                    const state = {
                      formikData : data,
                      campaign
                    }

                    // console.log("data", data);
                    
                    updateDOOHCampaign(state, campaign._id, () => {
                      handleClose();
                    });
                  }}
                >
                  {({ values, touched, errors, setFieldValue, handleBlur }) => (
                    <Form>

                      <Grid container spacing={2}>

                        <Grid item md={6} sm={12}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                              label="Update End Date"
                              name="end_date"
                              format="yyyy/MM/dd hh:mm a"
                              onChange={(value) => {

                                setFieldValue('end_date', value);
                                setFormData({
                                  ...formData,
                                  end_date : value
                                });

                              }}
                              value={values.end_date}
                              minDate={new Date().toISOString()}
                              // className={classes.endDatePicker}
                              helperText={touched.end_date && errors.end_date}
                              error={errors.end_date && touched.end_date}
                              invalidDateMessage={touched.end_date && errors.end_date}
                              fullWidth
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item md={6} sm={12}>

                          <Autocomplete 

                            id="campaign_status"
                            value={values.campaign_status}
                            options={statusOptions}
                            size="small"
                            onChange={(e, value) => {

                              setFieldValue("campaign_status", value);
                            }}
                            onBlur={handleBlur}
                            // getOptionLabel={(option) => option }
                            renderInput={(params) => (
                              <TextField 

                                {...params}
                                name="campaign_status"
                                label="Campaign Status"
                                error={errors.campaign_status && touched.campaign_status}
                                helperText={touched.campaign_status && errors.campaign_status}
                                variant="outlined"
                                
                              />
                            )}
                          
                          />

                        </Grid>

                        <Grid item md={12} sm={12}>

                          <Autocomplete 

                            id="campaign_creatives"
                            value={values.creatives}
                            options={approvedCreatives}
                            size="small"
                            multiple
                            onBlur={handleBlur}
                            onChange={(e, value) => {

                              setFieldValue("creatives", value);
                            }}
                            getOptionLabel={(option) => option.original_name }
                            getOptionSelected={(option, value) => option.original_name === value.original_name}
                          
                            renderInput={(params) => (
                              <TextField 

                                {...params}
                                name="creatives"
                                label="Campaign Creatives"
                                variant="outlined"
                                error={errors.creatives && touched.creatives}
                                helperText={touched.creatives && errors.creatives}
                                
                              />
                            )}
                          
                          />

                        </Grid>

                        <Grid item md={12} sm={12}>
                          <Autocomplete
                            id="screens"
                            // style={{ width: 600 }}
                            multiple
                            limitTags={3}
                            // disableListWrap
                            size="small"
                            onChange={(e, value) => {
                              
                              setFieldValue("screens", value);
                            }}
                            onBlur={handleBlur}
                            value={values.screens}
                            disableCloseOnSelect
                            classes={classesList}
                            ListboxComponent={ListboxComponent}
                            renderGroup={renderGroup}
                            options={allScreens}
                            getOptionSelected={(option) => values.screens.find(s => s.screen_name === option.screen_name)}
                            getOptionLabel={(option) => option.screen_name}
                            renderInput={(params) => <TextField
                              {...params} 
                              variant="outlined" 
                              label="Screens"
                              error={errors.screens && touched.screens}
                              helperText={touched.screens && errors.screens}
                            />}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.screen_name}
                              </React.Fragment>
                            )}
                          />

                        </Grid>

                        <Grid item md={12} sm={12}>
                          <Button
                            type="submit"
                            // onClick={handleClose} 
                            color="primary"
                            variant="contained"
                            className={classes.buttons}
                          >
                            Update
                          </Button>

                          <Button
                            onClick={handleClose}
                            color="secondary"
                            variant="contained"
                            className={classes.buttons}
                          >
                            Cancel
                          </Button>

                        </Grid>

                        <Grid>
                        {/* { console.log(errors.creatives && touched.creatives) } */}
                        </Grid>
                      </Grid>
                    </Form>
                    
                  )}
                </Formik>
                
            </Paper>
          </div>
        </Fade>
      </Modal>
      
    </div>
  )
}

// const mapStateToProps = (state) => {

//   return {
//     approvedCreatives: state.campaign.approvedCreatives
//   }
// }

export default connect(null, {updateDOOHCampaign, fetchApprovedCreatives})(UpdateDoohCampaign);
