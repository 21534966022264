import {
  FETCH_ALL_REPORTS, SET_DATA_FOR_REPORTS, SET_SCREEN_CREATIVE_LIST, UPDATE_CREATIVE_COUNT_IN_SCREEN, UPDATE_SCREEN_IMPRESSION_COUNT
} from "./types";
import { rootURL } from "../Utils/config";
import axios from "axios";

let reportURL;

if(process.env.NODE_ENV === "production"){

	reportURL = "https://screenoapplogs.locad.net";
	
} else if(process.env.NODE_ENV === "development"){

	reportURL = "http://localhost:5000";
}



export const fetchAllReports = () => async dispatch => {

  try {

    const response = await axios.get(`${rootURL}/api/getReports`);

    dispatch({
      type : FETCH_ALL_REPORTS,
      payload : response.data
    });

  } catch(err){
    console.log("Error :", err);
  }


}

export const getDataForReports = () => async dispatch => {

  try {

    const response = await axios.get(`${rootURL}/api/getDataForReports`);

    // console.log(response.data);

    dispatch({
      type : SET_DATA_FOR_REPORTS,
      payload : response.data
    });

  } catch(err){
    console.log(err);
  }
}

export const generateReport = (reportType, values, start, end ) => async dispatch => {

  try {

    const response = await axios.post(`${reportURL}/screeno_api/generateReportV2`, {reportType, values, start, end});
    // console.log("Reponse :", response);

    dispatch(fetchAllReports());

  } catch(err){
    console.log(err);
  }

}

export const deleteReport = (reportId) => async dispatch => {

  try {
    
    const response = await axios.post(`${rootURL}/api/deleteReport`, {reportId});

    dispatch(fetchAllReports());

  } catch(err){
    console.log("Error :", err);
  }
}

export const getScreenCreativeListForCampaign = (id) => async dispatch => {

  try {

		const response = await axios.post(`${rootURL}/api/getScreenCreativeListForCampaign`, {id});

		// console.log("Rdata :", response.data);

		dispatch({
			type : SET_SCREEN_CREATIVE_LIST,
			payload : response.data
		});

	} catch(e) {
		console.log(e);
	}

}

export const fetchScreenImpressionCount = (data) => async dispatch => {

	// console.log("Data to fetch", data);

	try {

		const response = await axios.post(`${reportURL}/screeno_api/getImpressionCountsForScreenCampaign`, {
			data
		});

		// console.log("Screen Count", response.data.count);

		let payload = {
			...data,
			impression_count : response.data.count,
			publisher_impression_count : response.data.count
		};

		// console.log("Payload :", payload);

		dispatch({
			type : UPDATE_SCREEN_IMPRESSION_COUNT,
			payload
		});

		const { count } = response.data;

		
		if(data.creatives && count >= 0){

			data.creatives.forEach(creative => {
	
				// let obj = {
				// 	...creative,
				// 	...data
				// };

				let obj = {
					content_name : creative.original_name,
					screen_name : data.screen_name,
					start_date : data.start_date,
					end_date : data.end_date,
					campaign_name : data.campaign_name
				};

				// console.log("Obj Screative", obj);
	
				dispatch(getScreenCreativeImpressionCount(obj));
			});
			
		}

	} catch(e){
		console.log(e);
	}
}

export const getScreenCreativeImpressionCount = (data) => async dispatch => {

	try {

		const response = await axios.post(`${reportURL}/screeno_api/getLiveImpressionCountForCampaign`, {
			data
		});

		// console.log(data.content_name, response.data.count);
		dispatch({
			type : UPDATE_CREATIVE_COUNT_IN_SCREEN,
			payload : {
				...data,
				count : response.data.count
			}
		});

	} catch(e){
		console.log(e);
	}

}

export const getImpressionCountForScreen = (items) => async dispatch => {

	// console.log("item", items);

	items.screenCreativeList.forEach(item => {
		let obj = {
			...item,
			start_date : items.start_date,
			end_date : items.end_date,
			campaign_name : items.campaign_name
		}
		// console.log("item", obj);
		dispatch(fetchScreenImpressionCount(obj));
	});


}

export const resetScreenCreativeList = (id) => dispatch => {

  dispatch(getScreenCreativeListForCampaign(id));

}

export const getCampaignReport = (data) => async dispatch => {

	try{

		const campaign = await axios.post(`${rootURL}/api/getCampaignReport`, {data});

		dispatch(fetchAllReports());

		// console.log(JSON.stringify(campaign.data, null, 4));



	} catch(err){
		console.log(err);
	}

}

export const getCampaignProfitMarginReport = (data) => async dispatch => {

	try{

		const campaign = await axios.post(`${rootURL}/api/profitCalculationReport`, {data});

		dispatch(fetchAllReports());

		// console.log(JSON.stringify(campaign.data, null, 4));



	} catch(err){
		console.log(err);
	}

}

export const publisherMonetizationReport = (data) => async dispatch => {

	try{

		const campaign = await axios.post(`${rootURL}/api/publisherReport`, {data});

		dispatch(fetchAllReports());

		// console.log(JSON.stringify(campaign.data, null, 4));



	} catch(err){
		console.log(err);
	}

}

export const contentLogReport = (data) => async dispatch => {

	try {

		const response = await axios.post(`${rootURL}/api/contentLogReport`, {data});
		dispatch(fetchAllReports());

	} catch(err){
		console.log(err);
	}
}

export const screenLogReport = (data) => async dispatch => {

	try {

		const response = await axios.post(`${rootURL}/api/screenLogReport`, {data});
		dispatch(fetchAllReports());

	} catch(err){
		console.log(err);
	}
}