import { 
  FETCH_PUBLISHER_DASHBOARD, FETCH_ADVERTISER_DASHBOARD, FETCH_DASHBOARD_STATS
 } from "../Actions/types";

const initState = {
  totalScreenCount : null,
  totalLocationCount : null,
  totalImpressionCount : null,
  screenCount : null,
  contentCount: null,
  campaignCount : null,
  locationCount : null,
  impressionCount : null,
  pendingApprovalCount : null,
  clientCount : null,
  publisherCount : null,
  advertiserCount : null,
  clientScreenCount : null,
  clientCampaignCount : null,
  sharedReportCount: null,
  sharedCampaignCount: null,
  sharedCreativeCount: null

}

export default (state=initState, action) => {
  const { type, payload } = action;

  switch(type){
    case FETCH_PUBLISHER_DASHBOARD:
      return {
        ...state,
        totalImpressionCount : payload.totalImpressions,
        screenCount : payload.screenCount,
        contentCount : payload.contentCount,
        campaignCount : payload.campaignCount,
        locationCount : payload.locationCount,
        impressionCount: payload.impressionCount
      }
    
    case FETCH_ADVERTISER_DASHBOARD:
      return {
        ...state,
        totalImpressionCount : payload.totalImpressions,
        totalScreenCount : payload.totalScreenCount,
        contentCount : payload.contentCount,
        campaignCount : payload.campaignCount,
        totalLocationCount : payload.totalLocationCount,
        impressionCount: payload.impressionCount
      }
      
    case FETCH_DASHBOARD_STATS:
      return {
        ...state,
        ...payload
      }
    default:
      return state;
  }
}