import React, { useState } from 'react';
import { Button, Modal, Grid } from "@material-ui/core";
import { Filter, Loop, Clear, CloudDownload } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import LogTable from "./LogsVirtualizedTable";
import Paper from '@material-ui/core/Paper';
import mqttClient from "../../../Utils/mqtt_config";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none"
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

let subscribe = false;

function ScreenLiveLogs({data}) {

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState([]);

  const event_name = (event_id) => {
    const id = Number(event_id);
    let result = "";

    switch(id){
      case 0:{
        result = "Player Paired";
        break;
      }
      case 1:{
        result = "Unpairing Player";
        break;
      }
      case 2:{
        result = "Player Refreshed";
			  break;
      }
      case 3:{
        result = "Unpaired Player Loaded";
			  break;
      }
      case 4: {
        result = "Playlist In Progress";
        break;
      }
      case 5: {
        result = "Playlist Started";
        break;
      }
      case 6: {
        result = "Playlist Stopped";
        break;
      }
      case 7: {
        result = "Playing Content";
        break;
      }
      case 8: {
        result = "Paired Screen";
        break;
      }
      default:
			break;

    }

    return result;

  }

  const handleOpen = () => {
    const { screen_name, user_id } = data;
    setOpen(true);
    // this.setState({ modal: true });
    mqttClient.publish(
      `cmd_${screen_name}_${user_id}`,
      JSON.stringify({ message: { cmd: "start_live_logs" } })
    );

    if (subscribe === false) {
      mqttClient.subscribe(
        `cmd_sendlog_${user_id}_${screen_name}`,
        () => {
          console.log(`subscribed to receive logs`);
        }
      );
      subscribe = true;
    }

    mqttClient.on("message", (topic, iotData) => {
      if (
        topic === `cmd_sendlog_${user_id}_${screen_name}`
      ) {
        const dataLog = JSON.parse(iotData.toString());
        if (dataLog.msg) {
          let message = [...msg];
          if (screen_name === dataLog.msg[1]) {
            let localMsg = dataLog.msg;
            localMsg[2] = event_name(localMsg[2]);
            // new addition
            let flag = true;

            message.forEach(msg => {
              if (message[0] === localMsg[0]) {
                flag = false;
              }
            });

            if (flag) {
              message.unshift(localMsg);
            }
            // till here
            // msg.push(localMsg);    // old logic
            setMsg(message);
          }
        }
      }
    });
  };

  const handleClose = () => {
    const { screen_name, user_id } = data;
    setOpen(false);
    setMsg([]);
    mqttClient.publish(
			`cmd_${screen_name}_${user_id}`,
			JSON.stringify({ message: { cmd: "stop_live_logs" } })
    );
    
  };

  const handleRefresh = () => {
    const { screen_name, user_id } = data;
		mqttClient.publish(
			`cmd_${screen_name}_${user_id}`,
			JSON.stringify({ message: { cmd: "refresh" } })
		);
	}

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
      >
        <Filter />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Live Screen Logs</h2>
            <div>
                {/* <Button
                    variant="contained"
                    color="secondary"
                    className={classes.margin}
                    // onClick={confirmAction}
                >
                    Confirm
                </Button>

                <Button
                    variant="contained"
                    className={classes.margin}
                    onClick={handleClose}
                >
                    Cancel
                </Button> */}
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Loop />}
                    className={classes.margin}
                    onClick={handleRefresh}
                  > 
                    Refresh
                  </Button>
                </Grid>
                <Grid item md={4}>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Clear />}
                    className={classes.margin}
                    onClick={handleClose}
                  > 
                    Close
                  </Button>
                </Grid>
                <Grid item md={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudDownload />}
                    className={classes.margin}
                  > 
                    Download Logs
                  </Button>
                </Grid>
              </Grid>
            </div>
            <Paper style={{ height :"400px", width : "50vw" }}>

              <LogTable
              
                rowCount={msg.length}
                rowGetter={({ index }) => msg[index]}
                columns={[
                  {
                    width: 100,
                    label: '#',
                    dataKey: 'dessert',
                  },
                  {
                    width: 120,
                    label: 'Date',
                    dataKey: 'date',
                    numeric: true,
                  },
                  {
                    width: 200,
                    label: 'Playlist Name',
                    dataKey: 'playlist_name',
                    numeric: true,
                  },
                  {
                    width: 120,
                    label: 'Status',
                    dataKey: 'carbs',
                    numeric: true,
                  },
                  {
                    width: 200,
                    label: 'Content',
                    dataKey: 'protein',
                    numeric: true,
                  },
                ]}
              
              />
            </Paper>
          </div>
        </Fade>
      </Modal>
      
    </div>
  )
}

export default ScreenLiveLogs;
