import React, { useState, useEffect } from 'react';
import { Grid } from "@material-ui/core";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import { useField } from "formik";
import DateFnsUtils from '@date-io/date-fns';

function DateField(props) {
  const { label, ...rest } = props;
  const [ field, meta, helper ] = useField(props);
  const { error, touched } = meta;
  const { setValue } = helper;
  const { value } = field;

  const [selectedDate, setSelectedDate] = useState(new Date().toISOString());
  // const [selectedDate, setSelectedDate] = useState(null);

  const onChange = (value) => {
    // console.log("Value", value.toISOString());
    setSelectedDate(value);
    setValue(value);
  }

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      setSelectedDate(date);
    }
  }, [value]);


  return (
    <Grid container>

      <MuiPickersUtilsProvider utils={DateFnsUtils} >
        <DateTimePicker
          {...field}
          {...rest}
          label={label}
          value={selectedDate}
          onChange={onChange}
          value={selectedDate}
          format="yyyy/MM/dd hh:mm a"
          // minDate={new Date().toISOString()}
          // fullWidth
          
          error={error && touched}
          invalidDateMessage={touched && error}
          helperText={touched && error}
        />
      </MuiPickersUtilsProvider>
      
    </Grid>
  )
}

export default DateField;
