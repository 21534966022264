import React, { useEffect } from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { fetchSharedCampaigns } from "../../Actions/campaignActions";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));


function SharedCampains(props) {

  const { fetchSharedCampaigns, sharedCampaigns } = props;

  useEffect(() => {
    fetchSharedCampaigns();
  }, []);

  return (
    <div>
      <MaterialTable
        title="Campaigns"
        columns={[
          { title: "Name", headerStyle: { fontWeight: "bold" }, field: 'campaign_name' },
          { title: "Status", headerStyle: { fontWeight: "bold" }, field: 'campaign_status' },
          { title : "Screen Count", headerStyle: { fontWeight: "bold" }, render : rowData => {
            let count = 0
            rowData.screens.forEach(screen => {
              count += screen.screen_count;
            });

            return count;
          }},
          // {title : "Screens", headerStyle: { fontWeight: "bold" }, render : rowData => {

          //   return (<Button
          //     key={rowData._id} 
          //     variant="contained" 
          //     component={LinkRouter}
          //     startIcon={<Visibility />} 
          //     to={{
          //         pathname: `/campaign/${rowData._id}/screens`,
          //         state : rowData
          //     }}
          //     color="primary">view</Button>)
          // }},
          {
            title: "Start Date", headerStyle: { fontWeight: "bold" }, render: rowData => {
              return `${new Date(rowData.start_date).toDateString()}`
            }
          },
          {
            title: "End Date", headerStyle: { fontWeight: "bold" }, render: rowData => {
              return `${new Date(rowData.end_date).toDateString()}`
            }
          },
          {
            title: "Created on", headerStyle: { fontWeight: "bold" }, render: rowData => {
              return `${new Date(rowData.createdAt).toDateString()}`
            }
          }
        ]}

        data={sharedCampaigns}

        // components={{
        //   Toolbar: props => (
        //     <div>
        //       <MTableToolbar  {...props} />

        //       <div>
        //         <Grid container>
        //           <Grid item>
        //             <Button
        //               variant="contained"
        //               color="primary"
        //               component={LinkRouter}
        //               to={`/campaign/create/dooh`}
        //               className={classes.margin}
        //               startIcon={<Add />}
        //             >
        //               Add
        //             </Button>
                    
        //           </Grid>

        //           <Grid item>
        //             <SharedCampaignModel/>
        //           </Grid>
        //         </Grid>
        //       </div>
        //     </div>
        //   )
        // }}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  // console.log("State :", state);
  return {
    sharedCampaigns : state.campaign.sharedCampaigns
  }
}

export default connect(mapStateToProps, {fetchSharedCampaigns})(SharedCampains);
