import {
  FETCH_ALL_REPORTS, SET_DATA_FOR_REPORTS, SET_SCREEN_CREATIVE_LIST, UPDATE_SCREEN_IMPRESSION_COUNT,
  UPDATE_CREATIVE_COUNT_IN_SCREEN, PUBLISHER_REPORT_LOGS
} from "../Actions/types";

const initState = {
  reports : [],
  screens : [],
  creatives : [],
  campaigns: [],
  advertiserCampaigns : [],
  screenCreativeList : [],
  publisherReportLogs : []
}

export default (state=initState, action) => {

  const { type, payload } = action;

  // console.log(type);

  switch(type){

    case FETCH_ALL_REPORTS:
      return {
        ...state,
        reports : [...payload]
      }

    case SET_DATA_FOR_REPORTS:
      return {
        ...state,
        ...payload
      }
    case SET_SCREEN_CREATIVE_LIST :
      return {
        ...state,
        screenCreativeList : payload
      }

    case UPDATE_SCREEN_IMPRESSION_COUNT :
      return {
        ...state,
        screenCreativeList : state.screenCreativeList.map( s => s.screen_name === payload.screen_name ? payload : s)
      }

    case UPDATE_CREATIVE_COUNT_IN_SCREEN:{
    
      // console.log("payalod :", payload);
      return {
        ...state,
        screenCreativeList : state.screenCreativeList.map(s => {

          if(s.screen_name === payload.screen_name){

            let creatives = [...s.creatives];
            let { count } = payload;

            let time;

            creatives = creatives.map(cr => {

              if(cr.original_name === payload.content_name){
                time = parseFloat(cr.duration) * count;
                return {
                  ...cr,
                  impression_count : count,
                  publisher_impression_count : count,
                  total_playtime : parseFloat(cr.duration) * count,
                  publisher_playtime : parseFloat(cr.duration) * count

                }
              } else {
                return cr;
              }
            });

            // console.log("payload :", payload.content_name, "creatives", creatives);
            // console.log("payload :", payload.content_name);

            if(s.total_playtime !== ""){
              return {
                ...s,
                total_playtime : s.total_playtime + time,
                publisher_playtime : s.total_playtime + time,
                creatives
              }
            } else {
              return {
                ...s,
                total_playtime : time,
                publisher_playtime : time,
                creatives
              }
            }

          } else {
            return s;
          }
        })
      }
    }

    default:
      return state;
  }
}

