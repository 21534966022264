// Authenticaton actions
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

// Screen Related actions
export const FETCH_ALL_SCREENS = 'FETCH_ALL_SCREENS';
export const ADD_SCREEN = 'ADD_SCREEN';
export const REMOVE_SCREEN = 'REMOVE_SCREEN';
export const UPDATE_SCREEN = 'UPDATE_SCREEN';
export const ADD_SCREEN_TO_INVENTORY = 'ADD_SCREEN_TO_INVENTORY';
export const REMOVE_SCREEN_FROM_INVENTORY = 'REMOVE_SCREEN_FROM_INVENTORY';
export const UNPAIR_SCREEN = 'UNPAIR_SCREEN';
export const GET_ALL_SCREENSHOTS = 'GET_ALL_SCREENSHOTS';

// Creative related actions
export const FETCH_ALL_CREATIVES = 'FETCH_ALL_CREATIVES';
export const REMOVE_CREATIVE = 'REMOVE_CREATIVE';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_PROGRESS_STATUS = 'UPLOAD_PROGRESS_STATUS';
export const CREATIVE_UPLOAD_SUCCESS = 'CREATIVE_UPLOAD_SUCCESS';
export const CREATIVE_IMAGE_ERROR = 'CREATIVE_IMAGE_ERROR';
export const CREATIVE_VIDEO_ERROR = 'CREATIVE_VIDEO_ERROR';
export const CREATIVE_WEB_ERROR = 'CREATIVE_WEB_ERROR';
export const CLEAR_CREATIVE_IMAGE_ERROR = 'CLEAR_CREATIVE_IMAGE_ERROR';
export const CLEAR_CREATIVE_VIDEO_ERROR = 'CLEAR_CREATIVE_VIDEO_ERROR';
export const CLEAR_CREATIVE_WEB_ERROR = 'CLEAR_CREATIVE_WEB_ERROR';
export const FETCH_ALL_CREATIVE_ADMIN = 'FETCH_ALL_CREATIVE_ADMIN';
export const APPROVE_CREATIVE_ADMIN = 'APPROVE_CREATIVE_ADMIN';
export const DISAPPROVE_CREATIVE_ADMIN = 'DISAPPROVE_CREATIVE_ADMIN';
export const UPDATE_DURATION = 'UPDATE_DURATION';




// Inventory actions
export const FETCH_INVENTORY_SCREENS = 'FETCH_INVENTORY_SCREENS';
export const ADD_TO_INVENTORY_CART = 'ADD_TO_INVENTORY_CART';
export const CLEAR_INVENTORY_CART = 'CLEAR_INVENTORY_CART';
export const FETCH_INVENTORY_FILTER_OPTIONS = 'FETCH_INVENTORY_FILTER_OPTIONS';
export const FETCH_FILTER_DATA = 'FETCH_FILTER_DATA';
export const INVENTORY_TABLE_LOADING = 'INVENTORY_TABLE_LOADING';
// export const CITIES_ON_STATE_CHANGE = 'CITIES_ON_STATE_CHANGE';
// export const FILTER_STATE_CHANGE = 'FILTER_STATE_CHANGE'; //
// export const FILTER_CITY_CHANGE = 'FILTER_CITY_CHANGE'; 
export const SET_STATE_FILTER_OPTIONS = 'SET_STATE_FILTER_OPTIONS';
export const SET_CITY_FILTER_OPTIONS = 'SET_CITY_FILTER_OPTIONS';
// export const SET_STATE_CITY_LIST = 'SET_STATE_CITY_LIST';
export const SET_LOCATION_STRUCTURE_LIST = 'SET_LOCATION_STRUCTURE_LIST';
export const FETCH_LOCATIONS_IN_RADIUS = 'FETCH_LOCATIONS_IN_RADIUS';


// Location list grouping
export const FETCH_ALL_LOCATION_LIST = 'FETCH_ALL_LOCATION_LIST';
export const ADD_LOCATION_LIST = 'ADD_LOCATION_LIST';
export const REMOVE_LOCATION_LIST = 'REMOVE_LOCATION_LIST';

// Campaign related actions 
export const FETCH_APPROVED_CREATIVES = 'FETCH_APPROVED_CREATIVES';
export const FETCH_DOOH_CAMPAIGNS = 'FETCH_DOOH_CAMPAIGNS';
export const REMOVE_DOOH_CAMPAIGN = 'REMOVE_DOOH_CAMPAIGN';
export const UPDATE_DOOH_CAMPAIGN = 'UPDATE_DOOH_CAMPAIGN';
export const FETCH_SCREENS_TO_EDIT_CAMPAIGN_SCREENS = 'FETCH_SCREENS_TO_EDIT_CAMPAIGN_SCREENS';



// Dashboard Counts

export const FETCH_PUBLISHER_DASHBOARD = 'FETCH_PUBLISHER_DASHBOARD';
export const FETCH_ADVERTISER_DASHBOARD = 'FETCH_ADVERTISER_DASHBOARD';
export const FETCH_DASHBOARD_STATS = 'FETCH_DASHBOARD_STATS';

// User Profile

export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const GET_SUB_USERS = 'GET_SUB_USERS';
export const ADD_SUB_USER = 'GET_SUB_USER';
export const REMOVE_CLIENT = 'REMOVE_CLIENT';

// Clinet Actions

export const ADD_SHARED_CAMPAIGNS = 'ADD_SHARED_CAMPAIGNS';
export const REMOVE_SHARED_CAMPAIGNS = 'REMOVE_SHARED_CAMPAIGNS';
export const FETCH_SHARED_CAMPAIGNS = 'FETCH_SHARED_CAMPAIGNS';


// Camapign Actions
export const FETCH_CAMPAIGN_FOR_APPROVAL = "FETCH_CAMPAIGN_FOR_APPROVAL";
export const CHANGE_CAMPAIGN_APPROVAL = "CHANGE_CAMPAIGN_APPROVAL";
export const SET_SCREEN_CREATIVE_LIST = "SET_SCREEN_CREATIVE_LIST";
export const UPDATE_SCREEN_IMPRESSION_COUNT = "UPDATE_SCREEN_IMPRESSION_COUNT";
export const UPDATE_CREATIVE_COUNT_IN_SCREEN = "UPDATE_CREATIVE_COUNT_IN_SCREEN";
export const SET_COMBINE_CAMPAIGN_STATS = "SET_COMBINE_CAMPAIGN_STATS";
export const SET_PER_CAMPAIGN_STATS = "SET_PER_CAMPAIGN_STATS";


// Admin Actions
export const FETCH_ALL_CAMPAIGNS_ADMIN = "FETCH_ALL_CAMPAIGNS_ADMIN";


// Report Actions
export const FETCH_ALL_REPORTS = 'FETCH_ALL_REPORTS';
export const SET_DATA_FOR_REPORTS = 'SET_DATA_FOR_REPORTS';
export const GENERATE_REPORT = 'GENERATE_REPORT';
export const PUBLISHER_REPORT_LOGS = 'PUBLISHER_REPORT_LOGS';


// Alert Actions
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Agency Actions

export const SET_SCREENS_FOR_MARKUP = 'SET_SCREENS_FOR_MARKUP';



