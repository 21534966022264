import React from 'react';
import { TextField } from "@material-ui/core";
import { useField } from "formik";

function InputField(props) {
  const { type, ...rest } = props;
  const [ field, meta ] = useField(props);
  // const [ field, meta, helper ] = useField(props);

  const {error, touched} = meta;

  // console.log("rest", rest);
  
  // console.log("meta", meta);
  
  return (
    <TextField 
      type={type}
      {...field}
      {...rest}
      error={error && touched}
      helperText={touched && error}
    />
  )
}

export default InputField;
