import React from 'react';
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import Card from "./Card";

function AgencyCounts({dashboard}) {

  const { totalImpressionCount, publisherCount, advertiserCount, clientScreenCount, clientCampaignCount, pendingApprovalCount } = dashboard;

  const numFormater = (value) => {

    if(!Number.isInteger(value)){
      return value;
    }
   
   
    // if(value > 999 && value < 1000000){

    //   return `${(value/1000).toFixed(2)}K+`;
    // } else if(value > 1000000 && value < 1000000000){

    //   return `${(value/1000000).toFixed(2)}M+`;
    // } else if(value > 1000000000){

    //   return `${(value/1000000000).toFixed(2)}B+`;
    // } else if(value < 1000) {
      
    //   return value;
    // }

    return value;
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <Card text="Total Impressions" value={numFormater(totalImpressionCount)} item="impression count" />
        </Grid>
        <Grid item md={3}>
          <Card text="Approval pending" value={numFormater(pendingApprovalCount)} item="approval pending"/>
        </Grid>
        <Grid item md={3}>
          <Card text="Publishers" value={publisherCount} item="publisher"/>
        </Grid>        
        <Grid item md={3}>
          <Card text="Advertisers" value={advertiserCount} item="advertiser"/>
        </Grid>        
        <Grid item md={3}>
          <Card text="DOOH Screens" value={clientScreenCount} item="screen count"/>
        </Grid>
        {/* <Grid item md={3}>
          <Card text="Content uploaded" value={contentCount} item="content"/>
        </Grid> */}
        <Grid item md={3}>
          <Card text="Campaigns" value={clientCampaignCount} item="campaign"/>
        </Grid>
      </Grid>     
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    dashboard : state.dashboard
  }
}
export default connect(mapStateToProps, {})(AgencyCounts);
