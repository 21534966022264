import React, { useState, useEffect } from 'react';
import {
  Grid, Radio, RadioGroup, FormControl, FormHelperText, FormControlLabel
} from "@material-ui/core";
import WeekCalander from "react-week-calendar";
import "react-week-calendar/dist/style.css";
import { useField } from "formik";
import { Schedule } from '@material-ui/icons';



function ScheduleField(props) {

  const { scheduleFieldName, ...rest } = props;
  const [ field, meta, helper ] = useField(props);
  const [ fieldS, metaS, helperS ] = useField({name : scheduleFieldName});
  const { touched, error } = meta;

  const { touched : touchedS, error : errorS  } = metaS;

  const { setValue  } = helper;

  const [schedule, setSchedule] = useState("");
  const [intervales, setIntervals] = useState([]);

  const handleChange = (e) => {
    setValue(e.target.value);
    if(e.target.value === "RUN_ALL_TIME"){
      helperS.setValue([]);
    }
    setSchedule(e.target.value);
  }


  const onIntervalSelectHandler = (data) => {

    // console.log("data time", data);

    let temp = data.map((d, i) => {
      // console.log(d.start.toDate());
      // console.log(d.start.toISOString());
      // d.value = `Slot ${d.end.format('dddd')} ${d.end.format('e')}`;
      d.value = d.end.format('e');
      return d;
    });

    // console.log("Slot :", data);

    setValue(schedule);
    helperS.setValue(temp);

    setIntervals(intervales.concat(temp));
    // console.log("intervales", intervales);
  }

  // useEffect(() => {

  //   let temp = intervales.map(d => {
  //     return {
  //       ...d,
  //       start : d.start.toISOString(),
  //       end : d.end.toISOString()
  //     }
  //   });

  //   setState({
  //     ...state,
  //     schedule_type : value,
  //     schedule : [...temp]
  //   });
    
  // }, [value, intervales])

  const removeHandler = (event) => {

    const data = [...intervales];
    data.splice(event, 1);
    setIntervals(data);

    setValue(schedule);
    helperS.setValue(data);

  }

  const onClickEvent = (e) => {
    const index = intervales.indexOf(e);
    if (index > -1) {

      removeHandler(index);
    }
  }

  const isError = error && touched ;

  // console.log("Sch :", isError);
  // console.log("SchMeta :", meta);
  // console.log("SchValue :", field.value);

  const returnHelperText = () => {

    if(isError){
      return <FormHelperText>{error}</FormHelperText>
    }

  }

  const isErrorSchedule = errorS && touchedS;
  const returnHelperTextSchedule = () => {

    if(isErrorSchedule){
      return <FormHelperText>{errorS}</FormHelperText>
    }

  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={12} sm={12}>
          <FormControl component="fieldset" error={isError}>
            {/* <FormLabel component="legend">Set Schedule </FormLabel> */}
            <RadioGroup {...field} value={field.value} onChange={handleChange} {...rest}>
              <FormControlLabel value="RUN_ALL_TIME" control={<Radio />} label="Run all the time" />
              <FormControlLabel value="RUN_ON_SCHEDULE" control={<Radio />} label="Run on Schedule" />
            </RadioGroup>
            {returnHelperText()}
          </FormControl>

        </Grid>
        

        <Grid item md={12} sm={12}>
          {schedule === 'RUN_ON_SCHEDULE' &&
            <FormControl component="fieldset" error={errorS && touchedS}>

              <WeekCalander
                scaleUnit={60}
                selectedIntervals={intervales}
                onIntervalSelect={onIntervalSelectHandler}
                onIntervalRemove={removeHandler}
                useModal={false}
                onEventClick={onClickEvent}
                dayFormat='dddd'
  
              />
              { returnHelperTextSchedule()}
            </FormControl>

          }
        </Grid>


      </Grid>

    </div>
  )
}

export default ScheduleField;
