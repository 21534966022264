import React from 'react';
import { useField  } from "formik";
import { FormControl, FormHelperText, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

function SelectField(props) {
  const { type, data, label, title, valueField, ...rest } = props;
  const [ field, meta, helper ] = useField(props);
  // const { value : selectedValue } = field;
  const { value } = field;
  const { error, touched } = meta;

  const isError =  touched && error && true;
  const { setValue } = helper;
  const returnHelperText = () => {

    // console.log(field);
    if(isError){
      return <FormHelperText>{error}</FormHelperText>
    }
  }

  return (
    <FormControl {...rest} error={isError}>
      {/* <InputLabel>{label}</InputLabel> */}
     
      {/* <Select {...field} value={selectedValue ? selectedValue : ""} multiple>

        {data.map((item, index) => (
          <MenuItem key={index} value={item[valueField]}>
            {item[title]}
          </MenuItem>
        ))}

      </Select>
      {returnHelperText()} */}
      <Autocomplete
        
        {...field}
        {...rest}
        options={data}
        onChange={(e, data, c) => {
          // console.log(data);
          setValue(data);
        }}
        
        value={value ? value : null}
        getOptionLabel={(option) => title ? option[title] : option}
        // getOptionSelected={(option) => valueField ? option[valueField] : option}
        getOptionSelected={(option, value) => title ? option[title] === value[title] : option === value}
        renderInput={(params) => <TextField {...params} label={label} error={error && touched} helperText={touched && error} />}
      />
      {/* {returnHelperText()} */}
    </FormControl>
  )
}


export default SelectField;
