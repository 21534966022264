import React from 'react';
// import Map from "../Media/Screens/ScreenMap";
// import Map from "./TestMap";
import CreativeTable from "./CreativeTable";

function Creatives() {

    return (
        <div>
            <CreativeTable />
        </div>
    )
}

export default Creatives;