import React from 'react';
import {Button, Grid, Switch} from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import {Link as LinkRouter} from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable, { MTableToolbar } from 'material-table';
import { makeStyles} from '@material-ui/core/styles';
// import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import RemoveScreen from "./RemoveScreenConfirm";
import ScreenExcelUpload from "./ScreenExcelUploadModal";
import { addScreenToInventory, removeScreenFromInventory } from "../../../Actions/screensActions";

const ScreensTable = ({screens, addScreenToInventory, removeScreenFromInventory}) => {

    // console.log(screens);
    const useStyles = makeStyles((theme) => ({
        margin: {
          margin: theme.spacing(1),
        },
      }));

    // const theme = createMuiTheme({
    //     palette: {
    //         primary: green,
    //     },
    // });

    const handleAdd = (data) => {
        addScreenToInventory(data.screen_name);
    }

    const handleDelete = (data) => {
        removeScreenFromInventory(data.screen_name)
    }

    const inventoryStatus = (rowData) => {

        // console.log("RowData :", rowData);

        return(
            <Switch
                checked={rowData.inventory}
                onChange={(e) => {

                    console.log("Checked :", e.target.checked);

                    if(e.target.checked === true){
                        handleAdd(rowData);
                    } else {
                        handleDelete(rowData);
                    }

                }}
                name="inventoryStatus"
                color="primary"
                inputProps={{ 'aria-label': 'primary checked' }}
            />
        )

    }

    const classes = useStyles();
    
    return (
        <div style={{ maxWidth: '100%' }}>
        <MaterialTable
            title="DOOH"
            columns={[
            { title: 'Screen Name', field: 'screen_name', headerStyle : { fontWeight : "bold" } },
            { title: 'Location', field: 'location', headerStyle : { fontWeight : "bold" }, width : "25%" },
            { title: 'Pairing code', field: 'pairing_code', headerStyle : { fontWeight : "bold" }  },
            { title: 'Screen count', field: 'screen_count', headerStyle : { fontWeight : "bold" }, export: false  },
            { title: 'Paired', field: 'paried', render : rowData => rowData.paired.toString(), headerStyle : { fontWeight : "bold" }, export: false  },
            { title: 'Update',export: false, render : rowData => (<Button
                key={rowData._id} 
                variant="contained" 
                component={LinkRouter} 
                to={{
                    pathname: `/screens/edit/${rowData._id}`,
                    state : rowData
                }}
                color="primary">Edit</Button>)
                , headerStyle : { fontWeight : "bold" } 
            },
            {title : "Inventory", export : false, headerStyle : { fontWeight : "bold" }, render : inventoryStatus},
            {title : "Remove", export : false, headerStyle : { fontWeight : "bold" }, render : rowData => <RemoveScreen data={rowData}/>}
            ]}
            data={[...screens]}
            options={{
                exportButton : true,
                tableLayout : "fixed",
                pageSize : 10,
                pageSizeOptions : [20, 50, 100]
                // exportCsv : (columns, data) => {
                //     console.log("data :", data);
                // }
            }}
            components={{
                Toolbar : props => (
                    <div>
                        <MTableToolbar {...props} />
                        <div>
                            <Grid container>
                                <Grid item >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={LinkRouter}
                                    to={`/addscreen`}
                                    className={classes.margin}
                                    startIcon={<Add />}
                                >
                                    Add
                                </Button>
                                </Grid>

                                <Grid item >
                                    <ScreenExcelUpload class={classes.margin}/>
                                </Grid>
                            </Grid>
                            

                            
                        </div>
                    </div>
                )
            }} 
            
        />
        </div>
    )
}

export default connect(null, {addScreenToInventory, removeScreenFromInventory})(ScreensTable);