import React, { useState, useEffect } from 'react';
import { Button, Modal, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Edit } from "@material-ui/icons";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';
import { Formik, Field, Form } from "formik";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { addSubUser } from "../../Actions/profileActions";

let timeZones = moment.tz.names();
let offsetTmz=[];

let roles = [
  "Client"
];

for(let i in timeZones){
  offsetTmz.push(`(GMT${moment.tz(timeZones[i]).format('Z')}) ${timeZones[i]}`);
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none"
  },
  margin: {
    margin: theme.spacing(2),
  },
}));

const AddSubUser = (props) => {

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { addSubUser } = props;

  const [formData, setFormData] = useState({
    name : "",
    password : "",
    role : "",
    email: "",
    company_name : "",
    defaultCreative : null,
    timezone : null
  });

  // console.log("Data :", formData);

  const handleOpen = () => {
    setOpen(true);

  }

  const handleClose = () => {
    setOpen(false);

  };


  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        startIcon={<Edit />}
        className={classes.margin}
      >
        Add Client
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Add User</h2>
            <div>

              
            </div>
            <Paper style={{width: "50vw", padding: "1rem" }}>
              <Formik
                enableReinitialize={true}
                initialValues={formData}

                validationSchema={Yup.object().shape({
                  name : Yup.string().required("Name is Required"),
                  company_name : Yup.string().required("Company name is required"),
                  email : Yup.string().email().required("E-Mail is required"),
                  role : Yup.string().required("Role is Required"),
                  password : Yup.string().required('Password is required'),

                })}

                onSubmit={async (data) => {
                  addSubUser(data, () => {
                    handleClose();
                  });
                }}

              >
                {({ values, handleSubmit, errors, touched, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container spacing={4}>

                      <Grid item md={6}>
                        <Field
                          type="text"
                          name="name"
                          label="Name"
                          as={TextField}
                          fullWidth
                          error={touched.name && !!errors.name}
                          helperText={touched.name && errors.name}
                        />
                      </Grid>

                      <Grid item md={6}>
                        <Field
                          type="text"
                          name="company_name"
                          label="Company Name"
                          as={TextField}
                          fullWidth
                          error={touched.company_name && !!errors.company_name}
                          helperText={touched.company_name && errors.company_name}
                        />
                      </Grid>


                      <Grid item md={6}>
                        <Field
                          type="text"
                          name="email"
                          label="Email"
                          as={TextField}
                          fullWidth
                          error={touched.email && !!errors.email}
                          helperText={touched.email && errors.email}
                        />
                      </Grid>

                      <Grid item md={6}>
                        <Field
                          type="password"
                          name="password"
                          label="Password"
                          as={TextField}
                          fullWidth
                          error={touched.password && !!errors.password}
                          helperText={touched.password && errors.password}
                        />
                      </Grid>

                      {/* <Grid item md={6}>
                        <Field
                          type="text"
                          name="role"
                          label="Role"
                          as={TextField}
                          fullWidth
                          disabled
                        />
                      </Grid> */}

                      <Grid item md={6}>
                      
                        <Autocomplete
                          id="role"
                          value={values.role ? values.role : null}
                          options={roles}
                          size="small"
                          onChange={(e, value) => {
                            if(value === null) {
                              setFieldValue("role", "");
                            } else {
                              setFieldValue("role", value);
                            }
                          }}
                          getOptionLabel={option => option }
                          // style={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField 
                              {...params} 
                              name="role" 
                              label="Access Role"

                              error={touched.role && !!errors.role}
                              helperText={touched.role && errors.role}
                              
                            />
                          )}
                          loading={true}
                        />
                      </Grid>

                      <Grid item md={12}>

                        <Autocomplete
                          id="timezone"
                          value={values.timezone}
                          options={offsetTmz}
                          size="small"
                          onChange={(e, value) => {
                            // console.log(value);
                            setFieldValue('timezone', value);
                          }}
                          fullWidth
                          getOptionLabel={(option) => option}
                          // getOptionSelected={(option, value) => option.original_name == value.original_name}
                          // style={{ width: 300 }}
                          renderInput={(params) => <TextField  {...params} name="timezone" label="Select Timezone" />}
                          loading={true}
                        />
                      </Grid>

                      <Grid item md={6}>
                        <Button type="submit" variant="contained" color="primary"> Submit </Button>
                      </Grid>

                    </Grid>

                  </Form>
                )}
              </Formik>

            </Paper>
          </div>
        </Fade>
      </Modal>

    </div>
  )
}

export default connect(null, {addSubUser})(AddSubUser);
