import React, { useEffect, useState } from 'react';
import { Button, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';
import { PlayCircleOutline, Cancel } from '@material-ui/icons';
import { v4 as uuidv4 } from "uuid";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none",
    width: "100vw", 
    height : "100vh",
    padding: "1rem"
  },
  margin: {
    margin: theme.spacing(1),
  },
  horizontal : {
    // width : "80vw",
    height : "80vh"
  },
  vertical : {
    margin: theme.spacing(1),
    height : "80vh"
  }
}));



function ContentPreview({data}) {

  const { creatives } = data;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [ alignment, setAlignment ] = useState("Horizontal");

  let counter = 0;
  let timeOut = null;

  const [content, setContent] = useState(null);

  function playContent(){

    // if(timeOut !== null){
    //   clearTimeout(timeOut);
    // }

    setContent(creatives[counter % creatives.length]);

    getContentDimension(creatives[counter % creatives.length]);

    timeOut = setTimeout(() => {
      counter++;
      // console.log("Counter :", counter);
      playContent();
    }, creatives[counter % creatives.length].duration * 1000);

  }

  function getContentDimension(content){

    if(content.file_type.includes("video")){

      let video = document.createElement("vidoe");
      video.onloadedmetadata = () => {

        if(video.videoWidth > video.videoHeight){

          setAlignment("Horizontal");

        } else {

          setAlignment("Vertical");
        }
      }

      video.src = content.file_url;

    } else if(content.file_type.includes("images")) {

      let img = new Image();

      img.onload = function(){
        // console.log("Height",img.height);
        // console.log("Width",img.width);

        if(img.height > img.width){

          setAlignment("Vertical");

        } else if(img.height < img.width) {
          
          setAlignment("Horizontal");

        } else {

          setAlignment("Square");

        }

      }

      img.src = content.file_url;

    } else if(content.file_type.includes("html")) {

      setAlignment("Horizontal");
    }

  }

  useEffect(() => {

    if(open){
      playContent();
    }

  }, [data.creatives, open]);

  const handleOpen = () => {
    setOpen(true);

  }

  const handleClose = () => {
    setOpen(false);
    // clearTimeout(timeOut);

  };

  function returnContent(content){

    const {file_type : type, file_url : url} = content;

    if(type.includes("image")){

      return(
        <div key={uuidv4()}>
          <img src={url} className={alignment === "Horizontal" ? classes.horizontal : classes.vertical}/>
        </div>
      );

    } else if(type.includes("video")){

      return(
        <div key={uuidv4()} >
          <video src={url} autoPlay className={alignment === "Horizontal" ? classes.horizontal : classes.vertical}> </video>
        </div>
      );

    } else if(type.includes("html")){

      return (
        <div key={uuidv4()} >
          <iframe src={url} className={classes.horizontal} />
        </div>
      );
    }
  }


  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        className={classes.margin}
      >
        <PlayCircleOutline />
      </Button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={open}>
        <Paper style={{ }}>

        {/* { content ? <h4 key={content._id}>{content.original_name}:{content.file_type}</h4> : "Loading..." } */}
        { content ? returnContent(content) : "Loading..." }
        <div style={{textAlign : "center"}}>
          <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
          className={classes.margin}
          startIcon={<Cancel/>}
          >
            Close
          </Button>
        </div>
        </Paper>
        </Fade>
      </Modal>
      
    </div>
  )
}

export default ContentPreview;
