import axios from "axios";
import { rootURL } from "../Utils/config";
import {
    FETCH_ALL_SCREENS,
    ADD_SCREEN_TO_INVENTORY,
    REMOVE_SCREEN_FROM_INVENTORY,
    ADD_SCREEN, UPDATE_SCREEN, REMOVE_SCREEN, UNPAIR_SCREEN, GET_ALL_SCREENSHOTS
} from "./types";


export const fetchScreens = () => async dispatch => {

    // console.log("I was called");

    const result = await axios.get(`${rootURL}/api/getAllScreens`);
    const { screens } = result.data;
    // console.log(screens);

    
    dispatch({
        type: FETCH_ALL_SCREENS,
        payload : screens
    })
}

export const addScreenToInventory = (screen) => async dispatch => {
    
    try {
        const result = await axios.post(`${rootURL}/api/addScreenToInventory`,{screen_name : screen});
        // console.log(result.data);
        dispatch({
            type : ADD_SCREEN_TO_INVENTORY,
            payload : screen
        })
    } catch(err){
        console.log(err);
    }
}

export const removeScreenFromInventory = (screen_name) => async dispatch => {
    
    try {
        const result = await axios.post(`${rootURL}/api/removeScreenFromInventory`,{screen_name});
        // console.log(result);
        dispatch({
            type : REMOVE_SCREEN_FROM_INVENTORY,
            payload : screen_name
        })
    } catch(err){
        console.log(err);
    }
}

export const addScreen = (data, cb) => async dispatch => {

    try {

        const result = await axios.post(`${rootURL}/api/addScreen`, {...data});

        dispatch({
            type : ADD_SCREEN,
            payload : result.data.screen
        });

        cb();

    } catch(err) {
        console.log(err);
    }
}

export const updateScreen = (data, cb) => async dispatch => {

    try {
        const result = await axios.post(`${rootURL}/api/updateScreen`, {...data});

        // console.log("result", result)

        dispatch({
            type : UPDATE_SCREEN,
            payload: result.data.result
        });

        cb();
    } catch(err){
        console.log(err);
    }
} 
export const removeScreen = (screen_name) => async dispatch => {

    try {
        const result = await axios.post(`${rootURL}/api/removeScreen`,{screen_name});

        dispatch({
            type : REMOVE_SCREEN,
            payload : screen_name
        });


    }catch(e){

    }
}

export const addScreensWithExcel = (formData, cb) => async dispatch => {
    
    try {
        
        await axios.post(`${rootURL}/api/addScreensFromExcel`, formData, {
            headers : {
                'Content-Type' : 'multipart/form-data'
            }
        });

        cb();

    } catch(err) {
        console.log(err);
        cb();
    }
}

export const unpairScreen = (screen_name, cb) => async dispatch => {

    try {

        const screen = await axios.post(`${rootURL}/api/unpairscreen`, {screen_name});

        // console.log(screen.data.data);
        dispatch({
            type : UNPAIR_SCREEN,
            payload : screen.data.data
        });

        cb();

    } catch(err){
        console.log(err);
    }
}

export const getAllScreenshots = (screen_name, user_id, cb) => async dispatch => {

    try {
        const response = await axios.post(`${rootURL}/api/getAllScreenshots`, {screen_name, user_id});
        // console.log("Screenshots :", response.data);
        dispatch({
            type: GET_ALL_SCREENSHOTS,
            payload : response.data
        });

        cb();

    } catch(err){
        console.log("Error :", err);
    }

}