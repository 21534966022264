import React, { useState, useEffect } from 'react';
import { 
  Button, Grid, TextField, FormControl, FormControlLabel, Radio, RadioGroup, FormHelperText
 } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ArrowBack, CloudDownload } from "@material-ui/icons";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Field, Form } from "formik";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { Link as LinkRouter } from "react-router-dom";
import { CSVLink } from "react-csv";
import { addSubUser, getSubUsers } from "../../../Actions/profileActions";
import { fetchScreensForMarkup  } from "../../../Actions/adminActions";
import Alert from "../../Common/Alert";
import { rolePToM } from "../../../Utils/config";

let timeZones = moment.tz.names();
let offsetTmz=[];

let roles = [
  "Publisher",
  "Advertiser"
];

let advertiserTypes = [
  "Publishers of this agency",
  "Specific Publishers"
];


for(let i in timeZones){
  offsetTmz.push(`(GMT${moment.tz(timeZones[i]).format('Z')}) ${timeZones[i]}`);
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none"
  },
  margin: {
    margin: theme.spacing(2),
  },
  alert : {
    margin: theme.spacing(2),
    width: "50vw",
    textAlign: "center"
  }
}));

const AddClientModal = (props) => {

  const classes = useStyles();
  
  const { addSubUser, getSubUsers, subUsers, fetchScreensForMarkup, screens, history } = props;

  // console.log("Props :", props);

  const [ templateExport, setTemplateExport ] = useState([]);
  const [ show, setShow ] = useState(false)

  const [formData, setFormData] = useState({
    name : "",
    password : "",
    file: "",
    filename: "",
    role : null,
    accessTo: null,
    email: "",
    company_name : "",
    publisherIds : [],
    defaultCreative : null,
    timezone : null,
    markupType : "",
    sameMarkup : ""
  });

  const onSubmit = (data, props) => {

    const formData = new FormData();

    formData.append("markupFile", data.file);
    formData.append("filename", data.filename);
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("role", rolePToM(data.role));
    formData.append("password", data.password);
    formData.append("accessTo", data.accessTo);
    formData.append("company_name", data.company_name);
    formData.append("publisherIds", JSON.stringify(data.publisherIds));
    formData.append("timezone", data.timezone);
    formData.append("markupType", data.markupType);
    formData.append("sameMarkup", data.sameMarkup);

    // console.log("Form :", formData);

    
    addSubUser(formData, () => {
      history.push("/clients");
    });
    
  }

  const returnPublishers = () => {
    let publishers = subUsers.filter( s => s.role === "Media Owner");
    return publishers;
  }

  useEffect(() => {
    getSubUsers();
  }, []);

  useEffect(() => {

    fetchScreensForMarkup();

  }, []);

 

  const showMarkupMenu = (values, errors, touched, setFieldValue) => {

    const { role, accessTo,  publisherIds} = values;

    if(role === "Advertiser" && accessTo !== null){

      return (
        <Grid item md={6} sm={12}>
          <h3>Choose markup type</h3>
          <FormControl component="fieldset" error={errors.markupType && touched.markupType}>
  
            <RadioGroup
              name="markupType"
              value={values.markupType}
              onChange={(e) => {
  
                if(e.target.value === "VARIABLE"){
                  setFieldValue("markupType", e.target.value);
  
                } else {
                  setFieldValue("markupType", e.target.value);
                  
                }
  
              }}
            >
  
              <FormControlLabel
                value="SAME_FOR_ALL" 
                control={<Radio />} 
                label="Same for all"
                
              />

              <FormControlLabel 
                value="VARIABLE" 
                control={<Radio />} 
                label="Variable" 
                
              />
  
            </RadioGroup>

            { errors.markupType && touched.markupType ? <FormHelperText>{touched.markupType && errors.markupType}</FormHelperText> : null }
            
          </FormControl>
  
        </Grid>
      )

    } else {
      return null;
    }
    
  }

  const setScreenForTemplate = (publishers) => {
    let templateScreens = [];
    let temp;
    // console.log("TempPPP :", publishers);

    publishers.forEach(p => {
      temp = screens.filter(s => s.user_id === p._id);
      
      temp = temp.map(t => {

        let obj = {...t}
        delete obj.user_id;
        
        return {
          ...obj,
          publisher : p.email,
          markup : ""
        }
      });

      templateScreens = [...templateScreens, ...temp];
    });

    // console.log("Temp :", templateScreens);

    setTemplateExport(templateScreens)
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        startIcon={<ArrowBack />}
        // className={classes.margin}
        component={LinkRouter}
        to={"/clients"}
      >
        Back
      </Button>

      <h2>Add Client</h2>

      <Formik
        enableReinitialize={true}
        initialValues={formData}

        validationSchema={Yup.object().shape({
          name : Yup.string().required("Name is Required"),
          company_name : Yup.string().required("Company name is required"),
          email : Yup.string().email().required("E-Mail is required"),
          role : Yup.string().required("Role is Required").nullable(),
          accessTo : Yup.string().when('role', {
            is : "Advertiser",
            then : Yup.string().required("Please select the option").nullable(),
            otherwise : Yup.string().nullable()
          }),
          publisherIds : Yup.array().when('accessTo', {
            is: "Specific Publishers",
            then : Yup.array().required("Please select publishers"),
            otherwise : Yup.array()
          }),
          password : Yup.string().required('Password is required'),
          markupType : Yup.string().when('role', {
            is : "Advertiser",
            then : Yup.string().required("Please choose markupType"),
            otherwise : Yup.string()
          }),
          filename : Yup.string().when("markupType", {
            is: "VARIABLE",
            then: Yup.string().required("Please select markup file"),
            otherwise : Yup.string()
          }),
          sameMarkup : Yup.number().when("markupType", {
            is : "SAME_FOR_ALL",
            then : Yup.number().required("Please enter markup value"),
            otherwise : Yup.number()
          })

        })}

        onSubmit={onSubmit}

      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <Grid container spacing={4}>

              <Grid item md={6}>
                <Field
                  type="text"
                  name="name"
                  label="Name"
                  as={TextField}
                  fullWidth
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  
                />
              </Grid>

              <Grid item md={6}>
                <Field
                  type="text"
                  name="company_name"
                  label="Company Name"
                  as={TextField}
                  fullWidth
                  error={touched.company_name && !!errors.company_name}
                  helperText={touched.company_name && errors.company_name}
                />
              </Grid>

              <Grid item md={6}>
                <Field
                  type="text"
                  name="email"
                  label="Email"
                  as={TextField}
                  fullWidth
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />
              </Grid>

              <Grid item md={6}>
                <Field
                  type="password"
                  name="password"
                  label="Password"
                  as={TextField}
                  fullWidth
                  
                  error={touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                />
              </Grid>

              <Grid item md={6}>
              
                <Autocomplete
                  id="role"
                  value={values.role ? values.role : null}
                  name="role"
                  options={roles}
                  size="small"
                  onChange={(e, value, reason) => {
                    
                    if(value === null) {
                      setFieldValue("role", "");
                    } else {
                      setFieldValue("role", value);
                    }
                  }}
                  
                  getOptionLabel={option => option }
                
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      name="role" 
                      label="Access Role"
                      // {...getFieldProps("role")}
                      error={touched.role && !!errors.role}
                      helperText={touched.role && errors.role}
                      
                    />
                  )}
                  loading={true}
                />
              </Grid>

              { values.role === "Advertiser" ? (

                <Grid item md={6}>
                  <Autocomplete 
                    id="accessTo"
                    value={values.accessTo ? values.accessTo : null}
                    options={advertiserTypes}
                    size="small"
                    onChange={(e, value) => {

                      if(values.markupType !== ""){
                        setFieldValue("markupType" , "")
                      }

                      if(value === null) {
                        setFieldValue("accessTo", "");
                        
                      } else {
                        setFieldValue("accessTo", value);

                        if(value === "Publishers of this agency"){
                          let pubs = returnPublishers();
                          setScreenForTemplate(pubs);
                        }
                      }
                      
                    }}

                    getOptionLabel={option => option }

                    renderInput={(params) => (
                      <TextField 
                        {...params} 
                        name="accessTo" 
                        label="Access To"

                        error={touched.accessTo && !!errors.accessTo}
                        helperText={touched.accessTo && errors.accessTo}
                        
                      />
                    )}
                  />

                </Grid>
                
              ) : null }

              { values.accessTo === "Specific Publishers" && values.role === "Advertiser"? (
                <React.Fragment>
                  <Grid item md={12}>
                    <Autocomplete 
                      id="publisherIds"
                      value={values.publisherIds ? values.publisherIds : null}
                      options={returnPublishers()}
                      size="small"
                      onChange={(e, value) => {

                        // console.log("Pubs :", value);
                        if(value === null) {
                          setFieldValue("publisherIds", []);
                        } else {
                          setFieldValue("publisherIds", value);
                          setScreenForTemplate(value);

                        }
                        
                      }}
                      multiple
                      getOptionLabel={option => `${option.name} <${option.email}>` }
                      getOptionSelected={(option, value) => option.email === value.email}
                      renderInput={(params) => (
                        <TextField 
                          // as={TextField} 
                          {...params} 
                          name="publisherIds" 
                          label="Select Publishers"

                          error={touched.publisherIds && !!errors.publisherIds}
                          helperText={touched.publisherIds && errors.publisherIds}
                          
                        />
                      )}
                    />

                  </Grid>
                  
                </React.Fragment>
              ) : null}

              { showMarkupMenu(values, errors, touched, setFieldValue) }

              
              {
                values.markupType && values.markupType === "SAME_FOR_ALL" ? (
                  <Grid item md={12}>
                    <Field
                      type="number"
                      name="sameMarkup"
                      label="Enter Markup value (%)"
                      as={TextField}
                      fullWidth
                      error={touched.sameMarkup && !!errors.sameMarkup}
                      helperText={touched.sameMarkup && errors.sameMarkup}
                      
                    />
                </Grid>
                ) : 
                
                values.markupType &&
                <Grid item md={12}>
                  <Grid container spacing={4}>

                    <Grid item md={6} sm={6}>
                      {
                        show ? (
                          <React.Fragment>
                          <Button
                            component={CSVLink}
                            filename={"fill_Markup.csv"}
                            data={templateExport}
                            color="primary"
                            variant="contained"
                            startIcon={<CloudDownload />}
                          >
                            Download template  
                          </Button>
                          <p>Please set the markup values in sheet and upload using file picker present on the right side</p>
                          </React.Fragment>

                        ) : (
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => setShow(!show)}
                          >
                            Get Template
                          </Button>
                        )
                      }

                    </Grid>

                    <Grid item md={6}>
                      <FormControl component="fieldset" error={errors.filename && touched.filename}>
                      <input
                        name="markupFile"
                        // value={invoiceData.filename}
                        type="file"
                        
                        onChange={(e, value) => {
                          
                          setFieldValue("file", e.currentTarget.files[0]);
                          setFieldValue("filename", e.currentTarget.files[0].name);
                        }}
                      />
                      { errors.filename && touched.filename ? <FormHelperText>{touched.filename && errors.filename}</FormHelperText> : null }
                      </FormControl>
                    </Grid>

                  </Grid>
                </Grid>
              }


            

              <Grid item md={12}>

                <Autocomplete
                  id="timezone"
                  value={values.timezone}
                  options={offsetTmz}
                  size="small"
                  onChange={(e, value) => {
                    // console.log(value);
                    setFieldValue('timezone', value);
                  }}
                  autoComplete
                  fullWidth
                  getOptionLabel={(option) => option}
                  getOptionSelected={(option, value) => option === value ? true : false}
                  renderInput={(params) => <TextField {...params} name="timezone" label="Select Timezone" />}
                  // loading={true}
                />
              </Grid>

              <Grid item md={6}>
                <Button type="submit" variant="contained" color="primary"> Submit </Button>
              </Grid>

            </Grid>

          </Form>
        )}
      </Formik>
      <div className={classes.margin}>
        <Alert />
      </div>
            

    </div>
  )
}

const mapStateToProps = (state) => {

  // console.log("Users :", state.profile);
  return {
    subUsers : state.profile.subUsers,
    screens : state.admin.screensForMarkup
  };
}

export default connect(mapStateToProps, {
  addSubUser, getSubUsers, fetchScreensForMarkup
})(AddClientModal);
