import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Button, TextField, LinearProgress, FormControl, Grid, FormHelperText } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { Formik, Form, Field } from "formik";
import { makeStyles, withStyles, lighten } from '@material-ui/core/styles';
import { Backup } from "@material-ui/icons";
import * as Yup from "yup";
import Alert from "../../Common/Alert";

import { creativeUpload } from "../../../Actions/creativeActions";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  margin: {
    margin: theme.spacing(1),
  }
}));


const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: lighten('#ff6c5c', 0.5),
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#ff6c5c',
  },
})(LinearProgress);

function ImageUpload(props) {

  const { progress, creativeUpload, close } = props;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const [formData, setFormData] = useState({
    file: '',
    fileName: '',
    type: '',
    duration: 10,
    size: ''
  });


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const closeModal = () => {
    setFormData({
      file: '',
      fileName: '',
      type: '',
      duration: 5,
      size: ''
    });
    close();
  }

  return (
    <div>
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          fileName : Yup.string().required("Please select the creative"),
          duration : Yup.number().positive("Duration cannot be negative").required("Creative duration required"),
          type : Yup.string().required("Creative Type is required"),
          size : Yup.number().required()
        })}

        onSubmit={(data, formikProps) => {
          creativeUpload(data, ()=>{
            handleClose();
            formikProps.resetForm();
          });
        }}
      >
        {({values, errors, setFieldValue, touched}) => {
          return (
            <Form>
              <Grid container>

                <Grid item md={12}>
                  <FormControl component="fieldset" error={errors.fileName && touched.fileName}>
                    <input 
                      type="file"
                      // name="file"
                      
                      onChange={(e) => {

                        if(e.target.files[0]){

                          setFieldValue("file", e.target.files[0]);
                          setFieldValue("fileName", e.target.files[0].name);
                          setFieldValue("type", e.target.files[0].type);
                          setFieldValue("size", e.target.files[0].size);
                        }


                      }}
                    
                    />
                    { errors.fileName && touched.fileName ? <FormHelperText>{touched.fileName && errors.fileName}</FormHelperText> : null }
                  </FormControl>
                </Grid>

                <Grid item md={12}>
                  <Field 
                    value={values.duration}
                    as={TextField}
                    name="duration"
                    label="Duration"
                    type="number"
                    error={errors.duration && touched.duration}
                    helperText={touched.duration && errors.duration}
                  />

                </Grid>

                <Grid item md={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<Backup />}
                  > 
                    Upload
                  </Button>

                    <Button
                      onClick={closeModal}
                      variant="contained"
                      className={classes.margin}
                      color="secondary"
                      startIcon={<Backup />}
                    > 
                      Close
                    </Button>

                </Grid>

              </Grid>
            </Form>
          );
        }}
      </Formik>
      {progress > 0 &&
        <BorderLinearProgress
          className={classes.margin}
          variant="determinate"
          color="secondary"
          value={progress}
        />
      }
      <div>
        <Alert />
      </div>
      {/* {JSON.stringify(formData, 2, null)} */}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    progress: state.uploadProgress,
    creativeError : state.creativeError.imageError
  }
}

export default connect(mapStateToProps, { creativeUpload})(ImageUpload);