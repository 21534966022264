import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import {
  InputLabel, MenuItem, FormControl, Select, Grid
} from "@material-ui/core";

import { fetchCampaignLocationList } from "../../../Actions/campaignActions";
import SelectField from "./FormFields/SelectField";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '90vw',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));



function CampaignLocationList(props) {

  const { fetchCampaignLocationList, locations} = props;

  useEffect(() => {
    fetchCampaignLocationList();
  }, []);



  return (
    <Grid container spacing={2}>

      <Grid item md={12} sm={12} >
        <SelectField
          name="screens"
          label="Select location group"
          title="name"
          data={locations}
          fullWidth
          // multiple
        />
      </Grid>

    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    locations : state.campaign.locationList
  }
}

export default connect(mapStateToProps, { fetchCampaignLocationList })(CampaignLocationList);
