import React, { useEffect, useState} from 'react';
import { connect } from "react-redux";
import { Grid, Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';
import Card from "./Card";
import { useHistory } from "react-router-dom";
import { ArrowBack, Add, Remove } from "@material-ui/icons";
import { sub, format } from "date-fns";
import { perCampaignStats  } from "../../Actions/campaignActions";
import MaterialTable, { MTableToolbar } from "material-table";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getScreenCreativeListForCampaign, getImpressionCountForScreen } from "../../Actions/reportActions";
import { secondsToTotalTime } from "../../Utils/config";

const timeDurations = [
  { value : "today" , label : "Today", type : "today" },
  { value : 15 , label : "Last 15 Min", type : "minute" },
  { value : 30 , label : "Last 30 Min", type : "minute" },
  { value : 1 , label : "Last 1 Hour", type : "hour" },
  { value : 2 , label : "Last 2 Hour", type : "hour" },
  { value : 6 , label : "Last 6 Hour", type : "hour" },
  { value : 12 , label : "Last 12 Hour", type : "hour" },
  { value : 24 , label : "Last 24 Hour", type : "hour" },
  { value : 7 , label : "Last 1 Week", type : "days" },
  { value : 14 , label : "Last 2 Weeks", type : "days" },
  { value : 21 , label : "Last 3 Weeks", type : "days" },
  { value : 30 , label : "Last 1 Month", type : "days" },
];


const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },

  marginFormItems : {
    margin : theme.spacing(2)
  }
}));

function CampaignReportingCounts(props) {

  const { stats, perCampaignStats, screenCreativeList, getScreenCreativeListForCampaign, getImpressionCountForScreen
  } = props;
  const { impressions_delivered, budget, budget_spend, ots } = stats;

  const { state : campaign } = props.location;

  let history = useHistory();
  const goToPreviousPath = () => {
      history.goBack()
  }

  const classes = useStyles();

  const [ selectValue , setSelectValue ] = useState(null);
  const [ timeRange, setTimeRange  ] = useState({
    duration : null,
    start_date : "",
    end_date : ""
  })

  const numFormater = (value) => {

    if(!Number.isInteger(value)){
      return value;
    }
   
   
    // if(value > 999 && value < 1000000){

    //   return `${(value/1000).toFixed(2)}K+`;
    // } else if(value > 1000000 && value < 1000000000){

    //   return `${(value/1000000).toFixed(2)}M+`;
    // } else if(value > 1000000000){

    //   return `${(value/1000000000).toFixed(2)}B+`;
    // } else if(value < 1000) {
      
    //   return value;
    // }

    return value;
  }

  // console.log("Campaign :", campaign);

  useEffect(() => {
    perCampaignStats(campaign._id);
  }, [campaign]);

  useEffect(() => {
    getScreenCreativeListForCampaign(campaign._id);
  }, [campaign])

  const handleDurationChange = (data) => {

    // console.log("Data", data);

    const { value, type } = data.duration;

    let start_date = "";
    let end_date = "";

    if(type === "today"){

      start_date = new Date(new Date().setHours(0,0,0,0)).toISOString();
      end_date = new Date().toISOString();

    } else if (type === "minute") {

      end_date = new Date().toISOString();
      start_date = sub(new Date(), { minutes : value }).toISOString();

    } else if(type === "hour") {

      end_date = new Date().toISOString();
      start_date = sub(new Date(), { hours : value }).toISOString();

    } else if(type === "days") {

      end_date = new Date().toISOString();
      start_date = sub(new Date(), { days : value }).toISOString();

    }

    // setTimeRange({
    //   ...timeRange,
    //   start_date,
    //   end_date
    // });


    const items = {
      start_date,
      end_date,
      screenCreativeList,
      campaign_name : campaign.campaign_name
    }

    getImpressionCountForScreen(items);
  }

  const showPagingLogic = () => {

    if(screenCreativeList.length <= 5 ){
      return false;
    } else {
      return true;
    }
  }

  return (
    <div className={classes.margin}>
      <Button
        color="primary"
        variant="contained"
        onClick={goToPreviousPath}
        className={classes.margin}
        startIcon={<ArrowBack/>}
      >
        Back
      </Button>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <Card text="Impressions delivered" value={numFormater(impressions_delivered)} item="impression count" />
        </Grid>
        <Grid item md={3}>
          <Card text="Budget" value={numFormater(campaign.campaign_budget)} item="impression count"/>
        </Grid>
        <Grid item md={3}>
          <Card text="Total budget spend" value={numFormater(budget_spend)} item="budget spend"/>
        </Grid>
        <Grid item md={3}>
          <Card text="General OTS" value={ots} item="screen"/>
        </Grid>
      </Grid>

      <div className={classes.margin}>

        <MaterialTable
          key={`mainTable`}
          title={`${campaign.campaign_name}`}
          columns={[
            { title: "Name", headerStyle: { fontWeight: "bold" }, field: 'campaign_name' },
            { title: "Status", headerStyle: { fontWeight: "bold" }, field: 'campaign_status' },
            { title : "Screen Count", headerStyle: { fontWeight: "bold" }, render : rowData => {
              let count = 0
              rowData.screens.forEach(screen => {
                count += screen.screen.screen_count;
              });

              return count;
            }},

            // {title : "Weather", headerStyle: { fontWeight: "bold" }, render : weatherRender},
            // { title: "CPM", headerStyle: { fontWeight: "bold" }, field: 'floor_cpm', type : "numeric" },
            { title: "Budget", headerStyle: { fontWeight: "bold" }, field: 'campaign_budget', type : "numeric" },
            // { title: "Impressions", headerStyle: { fontWeight: "bold" }, field: 'impressions', type : "numeric" },
            {
              title: "Start Date", headerStyle: { fontWeight: "bold" }, render: rowData => {
                return `${new Date(rowData.start_date).toDateString()}`
              }
            },
            {
              title: "End Date", headerStyle: { fontWeight: "bold" }, render: rowData => {
                return `${new Date(rowData.end_date).toDateString()}`
              }
            },
            {
              title: "Created on", headerStyle: { fontWeight: "bold" }, render: rowData => {
                return `${new Date(rowData.createdAt).toDateString()}`
              }
            }
          ]}

          options={{
            // paging: showPagingLogic()
            search : true
          }}

          data={[campaign]}
          detailPanel={[
            rowData => {
              rowData["screenCreativeList"] = props.screenCreativeList;
              
              return {
                icon : Add,
                openIcon : Remove,
                tooltip : "Click to view Campaign screens",
                render : rowData => {
                  return (
                    <Grid container spacing={3} margin={classes.marginFormItems}>
  
                      <Grid item md={12}>
                        
                        <Formik
                          initialValues={timeRange}
                          onSubmit={handleDurationChange}
                          validationSchema={Yup.object().shape({
                            duration : Yup.mixed().required("Please select duration").nullable()
                          })}
                        >
                          {
                            ({ values, errors ,touched, setFieldValue }) => (
                              <Form>
                                <Grid container>
                                  <Grid item md={8}>
                                    <Autocomplete
                                      id="duration"
                                      value={values.duration}
                                      options={timeDurations}
                                      // multiple
                                      size="small"
                                      className={classes.marginFormItems}
                                      onChange={(e, value) => {
                                        // console.log("value", value);
                                        setFieldValue("duration", value)
                                      }}
                                      getOptionLabel={(option) => option.label}

                                      getOptionSelected={(option, value) => option.value === value.value}
                                      renderInput={(params) => (
                                        <TextField 
                                          {...params} 
                                          label="Select duration" 
                                          variant="outlined" 
                                          error={errors.duration && touched.duration}
                                          helperText={touched.duration && errors.duration}
                                        />
                                      )}
                                      
                                    />
                                  </Grid>

                                  <Grid item md={4}>
                                    <Button type="submit" color="primary" variant="contained" className={classes.marginFormItems}>
                                      Submit
                                    </Button>
                                    <Button type="reset" color="secondary" variant="contained" className={classes.marginFormItems}>
                                      Clear
                                    </Button>
                                  </Grid>


                                </Grid>
                              </Form>
                            )
                          }

                        </Formik>
  
                      
                      </Grid>
  
                      <Grid item md={12} style={{padding : "2rem"}}>
  
                        {/* { timeRange.start_date  ?  ( */}
  
                        <MaterialTable 
                          key={`ScreenCountTable`}
                          title={`Campaign Screens (${rowData.campaign_name})`}
                          data={rowData.screenCreativeList}
                          columns={[
                            { title: "Screen Name", headerStyle: { fontWeight: "bold" }, field: 'screen_name' },
                            { title: "Footfall", headerStyle: { fontWeight: "bold" }, field: 'footfall' },
                            { title: "Impression count", headerStyle: { fontWeight: "bold" }, field: 'impression_count', render : rowData => {
                              const { impression_count } = rowData;
                              if(impression_count === ""){
                                return "-";
                              } else {
                                return impression_count;
                              }
                            }},
                            { title: "Total playtime", headerStyle: { fontWeight: "bold" }, field: 'total_playtime', render :  rowData => {
                              const { total_playtime } = rowData;
                              if(total_playtime === ""){
                                return "-";
                              } else {
                                return secondsToTotalTime(total_playtime);
                              }
                            }},
                            { title: "Screen paired", headerStyle: { fontWeight: "bold" }, field: 'paired'}
                          ]}
                          className={classes.margin}
                          options={{
                            search: false,
                            paging : false
                            // paging: showPagingLogic()
                          }}

                          onRowClick={(event, rowData, togglePanel) => togglePanel()}
  
                          detailPanel={[
                            {
                              icon : Add,
                              openIcon : Remove,
                              tooltip: "Creative counts per screen",
                              render : rowData => {
                                return (
                                    <div style={{padding : "1rem"}}>
                                      <MaterialTable 
                                        key={`CreativeCountTable`}
                                        // title={`${rowData.screen_name}`}
                                        title={`Campaign Creatives (${rowData.screen_name})`}
                                        data={rowData.creatives}
                                        options={{
                                          search: false,
                                          paging : false
                                        }}
                                        columns={[
                                          { title: "Creative Name", headerStyle: { fontWeight: "bold" }, field: 'original_name' },
                                          // { title: "Footfall", headerStyle: { fontWeight: "bold" }, field: 'footfall' },
                                          { title: "Impression count", headerStyle: { fontWeight: "bold" }, field: 'impression_count' },
                                          { title: "Total playtime", headerStyle: { fontWeight: "bold" }, field: 'total_playtime', render :  rowData => {
                                            const { total_playtime } = rowData;
                                            if(total_playtime === ""){
                                              return "-";
                                            } else {
                                              return secondsToTotalTime(total_playtime);
                                            }
                                          }}
                                        ]}
                                      
                                      />
                                  </div>
                                )
                              }
                            }
                          ]}
                        
                        />
  
                        {/* : <div>No duration selected</div>} */}
                      </Grid>
  
                    </Grid>
                  )
                }
              }
            }
          ]}

          onRowClick={(event, rowData, togglePanel) => togglePanel()}
        />
      </div>

      
    </div>
  )
}

const mapStateToProps = (state) => {

  return {
    stats : state.campaign.perCampaignStats,
    screenCreativeList : state.reports.screenCreativeList
  }
}

export default connect(mapStateToProps, {
  perCampaignStats, getScreenCreativeListForCampaign, getImpressionCountForScreen
})(CampaignReportingCounts);
