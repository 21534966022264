import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Loading from "./CountLoading";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    textAlign: "center",
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
    
  },
});

function DashboardCard(props) {
  const classes = useStyles();
  // const bull = <span className={classes.bullet}>•</span>;
  const { text, value, item } = props;

  const formatValue = (value) => {

    if(value === 0){
      // return `No ${item} data`;
      return 0;
    } else {
      return value;
    }
  }

  return (
    <Card className={classes.root}>
      <CardContent>

        <Typography variant="h5" component="h2">
          
          { value === null ? <Loading/> : formatValue(value) }
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          
          {text}
        </Typography>
       
      </CardContent>
      
    </Card>
  );
}

export default DashboardCard;