import React from 'react';
import {connect} from "react-redux";
import {Route , Redirect} from "react-router-dom";
import Navbar from "../Layout/Navbar";

const PrivateRoute = ({ component : Component, auth, ...rest}) => (
  <Route 
    {...rest}
    render={
      props => auth.isAuthenticated === true ? (
        <Navbar>
            <Component {...props} />
        </Navbar>
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

const mapStateToProps = (state) => {
  return {
    auth : state.auth
  }
}
export default connect(mapStateToProps)(PrivateRoute);