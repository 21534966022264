import { UPLOAD_PROGRESS_STATUS } from "../Actions/types"; 

const initialState = 0;

export default (state=initialState, action) => {

    const { type, payload }  = action;

    switch(type){
        case UPLOAD_PROGRESS_STATUS:
            return payload;
        default:
            return state;
    }
}