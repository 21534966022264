import React, { useEffect } from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from "@material-ui/core";
import { Add, Visibility, ArrowForward ,CloudDownload, Image } from "@material-ui/icons";
import { Link as LinkRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import {
  getDOOHCampaigns, fetchScreensToEditCampaignScreens, fetchApprovedCreatives
} from "../../Actions/campaignActions";
import RemoveDOOHCampaign from "./DOOHCampaignRemove";
import UpdateDOOHCampaign from "./UpdateDOOHCampaign";
import SharedCampaignModel from "./ShareCampaignsModal";
import ShowCampaignSchedule from "./ShowCampaignSchedule";
import CampaignReportingCounts from "./CampaignNew/CampaignReportingCounts";
import WeatherData from "./WeatherData";
import ContentPreview from './ContentPreview';
import StatusChip from "../Common/StatusChip";

// import Virtual from "./Virtual";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));


function DOOHSavedListTable(props) {
  
  const { getDOOHCampaigns, campaigns, fetchScreensToEditCampaignScreens, screensForEdit, approvedCreatives, fetchApprovedCreatives }= props;
  const classes = useStyles();
  // const { fetchCampaignLocationList, locationList } = props;

  useEffect(() => {
    getDOOHCampaigns();
    fetchScreensToEditCampaignScreens();
    fetchApprovedCreatives();

  }, []);

  const InvoiceDownload = (row) => {

    const { invoiceURL } = row;

    if(invoiceURL !== ""){

      return (
        <Button
          key={row._id}
          variant="contained"
          color="primary"
          startIcon={<CloudDownload/>}
          href={`${row.invoiceURL}`}
        >
          Invoice
        </Button>
      );

    } else {

      return (
        <Button
          key={row._id}
          variant="contained"
          color="primary"
          startIcon={<CloudDownload/>}
          href={`${row.invoiceURL}`}
          disabled={true}
        >
          Invoice
        </Button>
      );

    }
  }

  const weatherRender = (rowData) => {

    
    let cities = rowData.screens.map(({screen}) => screen.city);
    
    cities = _.uniq(cities);
    // console.log("RowData", cities);

    return <WeatherData cities={cities}/>
  }

  const ReturnCampaignStatus = (rowData) => {
    const { campaign_status } = rowData;

    if(campaign_status === "Active"){

      return <StatusChip label={campaign_status} bColor="green"/>

    } else if(campaign_status === "Running") {

      return <StatusChip label={campaign_status} bColor="green"/>

    } else if(campaign_status === "Stop") {

      return <StatusChip label={campaign_status} bColor="red"/>

    }
  }

  return (
    <div>
      <CampaignReportingCounts />
      <MaterialTable
        title="DOOH Campaigns"

        columns={[
          { title: "Name", headerStyle: { fontWeight: "bold" }, field: 'campaign_name' },
          // { title: "Status", headerStyle: { fontWeight: "bold" }, field: 'campaign_status' },
          { title: "Status", headerStyle: { fontWeight: "bold" }, render : ReturnCampaignStatus },
          { title : "Screen Count", headerStyle: { fontWeight: "bold" }, render : rowData => {
            let count = 0
            rowData.screens.forEach(screen => {
              count += screen.screen.screen_count;
            });

            return count;
          }},
          {title : "Screens", headerStyle: { fontWeight: "bold" }, render : rowData => {

            return (<Button
              key={rowData._id} 
              variant="contained" 
              component={LinkRouter}
              startIcon={<Visibility />} 
              to={{
                  pathname: `/campaign/${rowData._id}/screens`,
                  state : rowData
              }}
              color="primary">view</Button>)
          }},

          {title : "Creatives", headerStyle: { fontWeight: "bold" }, render : rowData => {

            // console.log("Row Data :", rowData.creatives);

            let loop = rowData.creatives.reduce((total, item) => {
              return total + parseFloat(item.duration);
            }, 0);

            let creativeCount = rowData.creatives.length;

            // console.log("Loop :", loop);

            return (<Button
              key={rowData._id} 
              variant="contained" 
              component={LinkRouter}
              // startIcon={<Visibility />} 
              to={{
                  pathname: `/campaign/${rowData._id}/creatives`,
                  state : rowData
              }}
              // startIcon={<Image />}
              color="primary">
                {`${creativeCount}/${Math.ceil(loop)}`}
              </Button>)
          }},
          
          {title : "Campaign Preview", headerStyle: { fontWeight: "bold" }, render : (rowData) => <ContentPreview data={rowData}/>},
          { title : "Update", headerStyle: { fontWeight: "bold" }, render : rowData => {
            return <UpdateDOOHCampaign campaign={rowData} allScreens={screensForEdit} approvedCreatives={approvedCreatives} />
          }},
          {title : "Weather", headerStyle: { fontWeight: "bold" }, render : weatherRender},
          // { title: "CPM", headerStyle: { fontWeight: "bold" }, field: 'floor_cpm', type : "numeric" },
          { title: "Budget", headerStyle: { fontWeight: "bold" }, field: 'campaign_budget', type : "numeric" },
          // { title: "Impressions", headerStyle: { fontWeight: "bold" }, field: 'impressions', type : "numeric" },
          {
            title: "Start Date", headerStyle: { fontWeight: "bold" }, render: rowData => {
              return `${new Date(rowData.start_date).toDateString()}`
            }
          },
          {
            title: "End Date", headerStyle: { fontWeight: "bold" }, render: rowData => {
              return `${new Date(rowData.end_date).toDateString()}`
            }
          },
          {
            title: "Schedule", headerStyle: { fontWeight: "bold" }, render: rowData => {

              // console.log(rowData.schedule);

              if(rowData.schedule.length > 0){

                let interval = rowData.schedule.map( d => {
                  
                  return {
                    start : moment(new Date(new Date(d.start).toISOString())),
                    end : moment(new Date(new Date(d.end).toISOString())),
                    value : ""
                  }
                });
  
                // console.log(interval);
                return <ShowCampaignSchedule data={interval} />
              } else {
                return <StatusChip label={"24x7"} bColor="primary"/>
              }
            }
          },
          // {
          //   title: "Invoice", headerStyle: { fontWeight: "bold" }, render: InvoiceDownload
          // },
          {title : "Reporting", headerStyle: { fontWeight: "bold" }, render : rowData => {

            return (<Button
              key={rowData._id} 
              variant="contained" 
              component={LinkRouter}
              // startIcon={<Visibility />} 
              to={{
                  pathname: `/campaign/${rowData._id}/campaignCounts`,
                  state : rowData
              }}
              color="primary"><ArrowForward /></Button>)
          }},
          {
            title: "Created on", headerStyle: { fontWeight: "bold" }, render: rowData => {
              return `${new Date(rowData.createdAt).toDateString()}`
            }
          },
          
          { title : 'Remove', headerStyle: { fontWeight: "bold" }, render : rowData => {
              return <RemoveDOOHCampaign data={rowData} />
          }}
        ]}

        data={campaigns}
        options={{
          pageSize : 10,
          pageSizeOptions : [10, 30, 50, 100]
        }}

        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar  {...props} />

              <div>
                
                <Grid container>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      component={LinkRouter}
                      to={`/campaign/createCampaign`}
                      className={classes.margin}
                      startIcon={<Add />}
                    >
                      Add
                    </Button>
                    
                  </Grid>

                  <Grid item>
                    <SharedCampaignModel/>
                  </Grid>
                </Grid>
              </div>
            </div>
          )
        }}
      />
      {/* <Virtual screens={screensForEdit}/> */}
    </div>
  )
}

const mapStateToProps = (state) => {
  
  return {
    campaigns : state.campaign.doohCampaigns,
    screensForEdit : state.campaign.screensForDOOHScreenEdit,
    approvedCreatives: state.campaign.approvedCreatives
  }
}

export default connect(mapStateToProps, {
  getDOOHCampaigns, 
  fetchScreensToEditCampaignScreens, 
  fetchApprovedCreatives
})(DOOHSavedListTable);