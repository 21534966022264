import AWS from "aws-sdk/global";
import AWSMqttClient from "aws-mqtt";

AWS.config.credentials = new AWS.CognitoIdentityCredentials({
	IdentityPoolId: "ap-south-1:3e4d8b4d-270f-4b3e-810d-13e4c2eed2d0"
});

AWS.config.region = 'ap-south-1';

const mqttClient = new AWSMqttClient({
	region: AWS.config.region,
	credentials: AWS.config.credentials,
	endpoint: "a1ea3uxyhb29cy.iot.ap-south-1.amazonaws.com",
	expires: 600,
	clientId: 'mqtt-client-' + (Math.floor((Math.random() * 100000) + 1)),
	will: {
		topic: 'WillMsg',
		payload: 'Connection Closed abnormally..!',
		qos: 0,
		retain: false
	}
})

mqttClient.on('connect', () => {
	console.log("Mqtt Connect")
});

mqttClient.on('close', () => {
	console.log("Mqtt Closed")
});

mqttClient.on('offline', () => {
	console.log("Mqtt Offline")
});


export default mqttClient;