import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { Edit, ThumbDown, ThumbUp } from "@material-ui/icons";
// import { createMuiTheme, withStyles, ThemeProvider } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import { approveCreativeAdmin, disapproveCreativeAdmin } from "../../../Actions/AdminCreativeActions";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

function EditCreativeApproval(props) {

  // console.log("props :", props);
  const {data, approveCreativeAdmin, disapproveCreativeAdmin  } = props;

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ApproveCreative = (data) => {
    approveCreativeAdmin(data);
    // console.log("Confirm Clicked!", data);
    handleClose();
  }

  const DisapproveCreative = (data) => {
    
    disapproveCreativeAdmin(data);
    // console.log("Confirm Clicked!",data);
    handleClose();
  }

  const showButtons = (data) => {

    if(data.approval_status === "Approved"){

      return <div>
        <Button
              variant="contained"
              className={classes.margin}
              color="secondary"
              onClick={() => DisapproveCreative(data)}
              startIcon={<ThumbDown />}
        >
          Disapprove
        </Button>
      </div>

    } else if(data.approval_status === "Disapproved"){

      return <div>
        <Button
              variant="contained"
              color="primary"
              className={classes.margin}
              onClick={() => ApproveCreative(data)}
              startIcon={<ThumbUp />}
        >
          Approve
        </Button>
      </div>

    } else {
      return (
        <div>
          <Button
              variant="contained"
              color="primary"
              className={classes.margin}
              onClick={() => ApproveCreative(data)}
              startIcon={<ThumbUp />}
          >
              Approve
          </Button>

          <Button
              variant="contained"
              className={classes.margin}
              color="secondary"
              onClick={() => DisapproveCreative(data)}
              startIcon={<ThumbDown />}
          >
              Disapprove
          </Button>
        </div>
      )
    }

  }

  return (
    <div>
      <Button type="button" onClick={handleOpen} variant="contained" color="primary">
        <Edit/>
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Update approval status</h2>
            {showButtons(data)}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default connect(null,{approveCreativeAdmin, disapproveCreativeAdmin})(EditCreativeApproval);