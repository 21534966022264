import React, { useState, useEffect } from 'react';
import { Button, Modal, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Share } from "@material-ui/icons";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';
import { Formik, Field, Form } from "formik";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { getSubUsers } from "../../Actions/profileActions";
import { addSharedCampaigns } from "../../Actions/campaignActions";
// import { format } from "date-fns";

let timeZones = moment.tz.names();
let offsetTmz=[];


for(let i in timeZones){
  offsetTmz.push(`(GMT${moment.tz(timeZones[i]).format('Z')}) ${timeZones[i]}`);
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none"
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const SharedCampaignModal = (props) => {

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { getSubUsers, addSharedCampaigns, subUsers, campaigns } = props;

  const [formData, setFormData] = useState({
    campaigns : [],
    clients : []
  });

  // console.log("Data :", formData);

  const handleOpen = () => {
    setOpen(true);

  }

  const handleClose = () => {
    setOpen(false);

  };

  useEffect(()=>{

    getSubUsers();
  }, []);


  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        startIcon={<Share />}
        className={classes.margin}
      >
        Share Campaigns
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Share Campaigns</h2>
            <div>

              
            </div>
            <Paper style={{width: "50vw", padding: "1rem" }}>
              <Formik
                enableReinitialize={true}
                initialValues={formData}

                validationSchema={Yup.object().shape({
                  
                  campaigns : Yup.array().required("Please select campaign").nullable(),
                  clients : Yup.array().required("Please select clients").nullable(),

                })}

                onSubmit={async (data) => {
                  
                  addSharedCampaigns(data, () => {
                    handleClose();
                  })
                }}

              >
                {({ values, errors, touched, setFieldValue }) => (
                  <Form>
                    <Grid container spacing={4}>

                      <Grid item md={6}>
                      
                        <Autocomplete
                          id="campaign"
                          value={values.campaigns }
                          options={[...campaigns]}
                          size="small"
                          onChange={(e, value) => {
                            // console.log("Campaigns", value);
                            setFormData({
                              ...formData,
                              campaigns : value
                            })
                            setFieldValue('campaigns', value);
                          }}
                          multiple
                          getOptionLabel={(option) => option.campaign_name}
                          getOptionSelected={(option, value) => option.campaign_name === value.campaign_name}
                          // style={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField 
              
                              {...params} 
                              name="campaigns" 
                              label="Campaign Name"

                              error={errors.campaigns && touched.campaigns}
                              helperText={touched.campaigns && errors.campaigns}
                              
                            />
                          )}
                          
                        />
                      </Grid>

                      <Grid item md={6}>

                        <Autocomplete
                          id="clients"
                          value={values.clients}
                          options={[...subUsers]}
                          size="small"
                          onChange={(e, value) => {
                            setFormData({
                              ...formData,
                              clients : value
                            })
                            setFieldValue('clients', value);
                          }}
                          multiple
                          fullWidth
                          getOptionLabel={(option) => `${option.name} <${option.email}>`}
                          getOptionSelected={(option, value) => option.email === value.email}
                          // style={{ width: 300 }}
                          renderInput={(params) => <TextField 
                            {...params} 
                            name="clients" 
                            label="Client Name"

                            error={errors.clients && touched.clients}
                            helperText={touched.clients && errors.clients}
                            
                          />}
                          
                        />
                      </Grid>

                      <Grid item md={6}>
                        <Button type="submit" variant="contained" color="primary"> Submit </Button>
                      </Grid>

                    </Grid>

                  </Form>
                )}
              </Formik>

            </Paper>
          </div>
        </Fade>
      </Modal>

    </div>
  )
}

const mapStateToProps = (state) => {

  // console.log("Users :", state.profile);
  return {
    subUsers : state.profile.subUsers,
    campaigns : state.campaign.doohCampaigns,
  };
}

export default connect(mapStateToProps, {getSubUsers, addSharedCampaigns})(SharedCampaignModal);
