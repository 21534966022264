import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Button, TextField, Grid } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles, withStyles, lighten } from '@material-ui/core/styles';
import { Backup } from "@material-ui/icons";
import * as Yup from "yup";
import Alert from "../../Common/Alert";

import {creativeUpload} from "../../../Actions/creativeActions";


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      // width: '25ch',
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
}));


function WebUpload(props) {

  const { progress, creativeUpload, close } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [formData, setFormData] = useState({
    file: '',
    fileName: '',
    type: 'html',
    duration: 5,
    size: 'NA',
    url : ''
  });

  // useEffect(() => {
    
  //   if(creativeError !=="") {

  //     setOpen(true);
  //   }
  // }, [creativeError]);


  const closeModal = () => {
    setFormData({
      file: '',
      fileName: '',
      type: 'html',
      duration: 5,
      size: 'NA',
      url : ''
    });
    close();
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          fileName : Yup.string().required("Please enter the name"),
          duration : Yup.number().positive("Duration cannot be negative").required("Creative duration is required"),
          type : Yup.string().required("Creative Type is required"),
          size : Yup.string().required(),
          url : Yup.string().required("Please enter the web URL")
        })}

        onSubmit={(data, formikProps) => {
          creativeUpload(data, ()=>{
            handleClose();
            formikProps.resetForm();
          });
        }}
      >
        {({values, errors, setFieldValue, touched}) => {
          return (
            <Form>
              <Grid container>

                <Grid item md={12}>

                  <Field 
                    value={values.fileName}
                    as={TextField}
                    name="fileName"
                    type="text"
                    label="Name"
                    // placeholder="Enter the name"
                    error={errors.fileName && touched.fileName}
                    helperText={touched.fileName && errors.fileName}
                    fullWidth
                  />
                  
                </Grid>

                <Grid item md={12}>
                  <Field 
                    value={values.duration}
                    as={TextField}
                    name="duration"
                    type="number"
                    label="Duration"
                    // placeholder="Duration"
                    error={errors.duration && touched.duration}
                    helperText={touched.duration && errors.duration}
                    fullWidth
                  />

                </Grid>

                <Grid item md={12}>

                  <Field 
                    value={values.url}
                    as={TextField}
                    name="url"
                    type="text"
                    label="Enter the web URL"
                    error={errors.url && touched.url}
                    helperText={touched.url && errors.url}
                    // placeholder="Enter the web URL"
                    fullWidth
                  />
                  
                </Grid>

                <Grid item md={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<Backup />}
                  > 
                    Upload
                  </Button>

                    <Button
                      onClick={closeModal}
                      variant="contained"
                      className={classes.margin}
                      color="secondary"
                      startIcon={<Backup />}
                    > 
                      Close
                    </Button>

                </Grid>

              </Grid>
            </Form>
          );
        }}
      </Formik>
      <div>
        <Alert />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    creativeError : state.creativeError.webError
  }
}


export default connect(mapStateToProps, {creativeUpload})(WebUpload);
