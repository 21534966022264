import {
  FETCH_ALL_LOCATION_LIST, ADD_LOCATION_LIST, REMOVE_LOCATION_LIST, FETCH_APPROVED_CREATIVES, FETCH_DOOH_CAMPAIGNS,
  REMOVE_DOOH_CAMPAIGN, UPDATE_DOOH_CAMPAIGN, FETCH_SCREENS_TO_EDIT_CAMPAIGN_SCREENS, ADD_SHARED_CAMPAIGNS,
  REMOVE_SHARED_CAMPAIGNS, FETCH_SHARED_CAMPAIGNS, FETCH_CAMPAIGN_FOR_APPROVAL, CHANGE_CAMPAIGN_APPROVAL, SET_PER_CAMPAIGN_STATS,
  SET_COMBINE_CAMPAIGN_STATS
} from "./types";
import axios from "axios";
import { rootURL } from "../Utils/config";


export const fetchCampaignLocationList = () => async dispatch => {

  // console.log("I Was Called");
  
  try {

    const response = await axios.get(`${rootURL}/api/getAllLocationListForCampaign`);

    dispatch({
      type : FETCH_ALL_LOCATION_LIST,
      payload : response.data.result
    });

  } catch(err){
    console.log(err);
  }

}

export const addCampaignLocationList = (name, data, cb) => async dispatch => {

  const locations = data.map(d => d._id);

  try {

    // console.log("name", name);
    // console.log("locations", locations);

    const response = await axios.post(`${rootURL}/api/addLocationToList`, {name, locations});

    // console.log(response.data);

    // dispatch({

    //   type: ADD_LOCATION_LIST,
    //   payload : response.data.result

    // });

    cb();

  } catch(err){
    console.log(err);
  }

}

export const removeCampaignLocationList = (id) => async dispatch => {

  try {

    const result = await axios.post(`${rootURL}/api/removeLocationFromList`, {id});

    dispatch({
      type : REMOVE_LOCATION_LIST,
      payload : id
    });

  } catch(err) {
    console.log(err);
  }

}

export const fetchApprovedCreatives = () => async dispatch => {

  try {

    const creatives = await axios.get(`${rootURL}/api/getApprovedContent`);


    let payload = creatives.data.result.map(creative => {

      const temp = {...creative};
      temp.title = creative.original_name;

      return temp;
    });

    dispatch({
      type : FETCH_APPROVED_CREATIVES,
      payload
    });

  } catch(err){

  }
}

export const createDOOHCampaign = (data, cb) => async dispatch => {


  try {

    const result = await axios.post(`${rootURL}/api/createDOOHCampaign`, {data});
    // console.log(result.data);
    cb();

  } catch(err) {
    console.log(err);
  }
}

export const getDOOHCampaigns = () => async dispatch => {

  
  try {
    const result = await axios.get(`${rootURL}/api/getDOOHCampaigns`);
    // console.log(result);
    
    dispatch({
      type : FETCH_DOOH_CAMPAIGNS,
      payload : result.data.campaigns
    });
    
  } catch (err) {
    console.log(err);
  }
}

export const removeDOOHCampaign = (id) => async dispatch => {

  try {
    await axios.post(`${rootURL}/api/removeDOOHCampaign`, {id});

    dispatch({
      type : REMOVE_DOOH_CAMPAIGN,
      payload : id
    });

  } catch(err){
    console.log(err);
  }
}

export const updateDOOHCampaign = (state, id, cb) => async dispatch => {

  try {

    // let data = {
    //   ...state,
    //   screens : [...state.screens.map(screen => screen._id)]
    // };

    const result = await axios.post(`${rootURL}/api/updateDOOHCampaign`, {data : state, id});

    // console.log("Updated :", result.data.updated);

    // dispatch({
    //   type : UPDATE_DOOH_CAMPAIGN,
    //   payload : result.data.updated
    // });

    dispatch(getDOOHCampaigns());

    
    cb();

  } catch(err) {
    console.log(err);
  }
}

export const fetchScreensToEditCampaignScreens = () => async dispatch => {

  try{
    const response = await axios.post(`${rootURL}/api/getAllInventoryScreensForUpdate`);

    const {screens} = response.data;

    // console.log("response.data :", response.data);

    // const screens = response.data.data.map(item => {
    //   const screen = {...item};
    //   screen.title = item.screen_name;
    //   return screen;
    // });

    dispatch({
      type : FETCH_SCREENS_TO_EDIT_CAMPAIGN_SCREENS,
      payload : screens
    });

  } catch(err){
    console.log(err);
  }

}

export const addSharedCampaigns = (data, cb) => async dispatch => {

  // console.log("Data", data);

  try{

    const campaign = await axios.post(`${rootURL}/api/addSharedCampaigns`, {...data});

    cb();

  } catch(err){

    console.log("Error :", err.response.data);
  }


  

}

export const removeSharedCampaigns = (data, cb) => async dispatch => {

}

export const fetchSharedCampaigns = (data, cb) => async dispatch => {

  try {

    const response = await axios.get(`${rootURL}/api/fetchSharedCampaigns`);

    console.log("Reponse", response.data);

    dispatch({
      type : FETCH_SHARED_CAMPAIGNS,
      payload : response.data.campaigns.sharedCampaigns
    });

  } catch(err){
    console.log("Err :", err);
  }
}

export const fetchDOOHCampaignsForApproval = () => async dispatch => {

  // console.log("I Ran");
  
  try {
    const result = await axios.get(`${rootURL}/api/findCampaignsForApproval`);
    // console.log(result);
    
    dispatch({
      type : FETCH_CAMPAIGN_FOR_APPROVAL,
      payload : result.data.campaigns
    });
    
  } catch (err) {
    console.log(err);
  }
}

export const approveScreenForCampaign = (data) => async dispatch => {

  try {

    const response = await axios.post(`${rootURL}/api/approveScreenForCampaign`, {data});

    // console.log("response", response.data.result);

    dispatch(fetchDOOHCampaignsForApproval())

  } catch(err){
    console.log(err);
  }
}

export const approveCPMForCampaign = (data) => async dispatch => {

  try {

    const response = await axios.post(`${rootURL}/api/approveCPMForCampaign`, {data});

    // console.log("response", response.data.result);

    dispatch(fetchDOOHCampaignsForApproval())

  } catch(err){
    console.log(err);
  }
}

export const disapproveScreenForCampaign =  (data) => async dispatch => {

  try {

    const response = await axios.post(`${rootURL}/api/disapproveScreenForCampaign`, {data});
    // console.log("response", response.data);

    dispatch(fetchDOOHCampaignsForApproval())

  } catch(err){

    console.log(err);
  }

}

export const disapproveCPMForCampaign =  (data) => async dispatch => {

  try {

    const response = await axios.post(`${rootURL}/api/disapproveCPMForCampaign`, {data});
    // console.log("response", response.data);

    dispatch(fetchDOOHCampaignsForApproval())

  } catch(err){

    console.log(err);
  }

}

export const combineCampaignStats = () => async dispatch => {

  try {

    const result = await axios.post(`${rootURL}/api/combineCampaignStats`);
    // console.log(result.data);

    dispatch({
      type : SET_COMBINE_CAMPAIGN_STATS,
      payload : result.data
    });

  } catch(err){
    console.log(err);
  }

}

export const perCampaignStats = (id) => async dispatch => {

  try {

    const result = await axios.post(`${rootURL}/api/perCampaignStats`, {id});

    dispatch({
      type : SET_PER_CAMPAIGN_STATS,
      payload : result.data
    });

  } catch(err){
    console.log(err);
  }

}

